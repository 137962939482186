import { useAuth0 } from '@auth0/auth0-react';
import downloadOutline from '@iconify/icons-eva/download-outline';
import { Icon } from '@iconify/react';
import {
  Alert,
  AlertColor,
  IconButton,
  LinearProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation } from 'react-query';
import { TransactionsPartialFragment } from '../../../@generated/types';
import { getDateTime } from '../../../utils';
import { getHostedInvoiceUrl } from '../utils';

interface ITransactionsTable {
  transactions: TransactionsPartialFragment[];
  page: number;
  limit: number;
  total?: number;
  loading?: boolean;
  onChangeLimit(limit: number): void;
  onChangePage(page: number): void;
}

export default function CandidatesTable({
  transactions,
  page,
  limit,
  total,
  loading = false,
  onChangeLimit,
  onChangePage,
}: ITransactionsTable) {
  const { getAccessTokenSilently } = useAuth0();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(newPage);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleError = (error: string) => {
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(error);
  };

  const { mutate } = useMutation(
    async ({ invoiceId }: { invoiceId: string }) => {
      const token = await getAccessTokenSilently();
      return await getHostedInvoiceUrl(token, { invoiceId: invoiceId });
    },
    {
      onSuccess(data) {
        window.open(data.hosted_invoice_url, '_blank');
      },
      onError() {
        const errorMessage =
          'Unable to retrieve invoice receipt at this time. Please contact us us via chat or at info@brokee.io';
        handleError(errorMessage);
      },
    }
  );

  function retrieveHostedInvoiceUrl(invoiceId: string) {
    mutate({
      invoiceId: invoiceId || '',
    });
  }

  return (
    <>
      <PerfectScrollbar>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Receipt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => {
              const {
                id,
                amount,
                created_at,
                description,
                status,
                stripe_charge_id,
              } = transaction;
              return (
                <TableRow hover key={`transaction${id}`}>
                  <TableCell>{description}</TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(amount / 100)}
                  </TableCell>
                  <TableCell>{getDateTime(created_at)}</TableCell>
                  <TableCell>
                    {stripe_charge_id ? (
                      <IconButton
                        id="billing-details-retrieve-invoice"
                        sx={{
                          p: '8px',
                          padding: '8px',
                          borderRadius: '50%',
                          minWidth: 'auto',
                        }}
                        onClick={() =>
                          retrieveHostedInvoiceUrl(stripe_charge_id)
                        }
                      >
                        <Icon icon={downloadOutline} width="20" height="20" />
                      </IconButton>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      {loading && <LinearProgress sx={{ width: '100%' }} />}
      {!loading && (
        <TablePagination
          component="div"
          count={total ?? transactions.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
      {showSnackbar && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            variant="filled"
            onClose={handleSnackbarClose}
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
