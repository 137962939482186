import { useAuth0 } from '@auth0/auth0-react';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import { useEffect, useState } from 'react';
import { useGetUserAssessmentDataQuery } from '../../../@generated/facadeClient';
import { GetUserAssessmentDataQuery } from '../../../@generated/types';
import { useBilling } from '../../../hooks/useBilling';
import { verifyAccessForAssessment } from '../../../hooks/useBilling/utils';
import { getUserHash, getUserMetaData, isAdmin } from '../../../utils/auth';
import { createTrialForUser } from '../Services/ChallengeService';

export default function useAssessmentsData(id: string) {
  const { user, getAccessTokenSilently } = useAuth0();
  const [assessment, setAssessment] = useState<
    GetUserAssessmentDataQuery['tests'][0] | null
  >(null);
  const { canAccessProFeatures, canAccessPersonalAccountFeatures } =
    useBilling();

  const [loading, setLoading] = useState<boolean>(true);
  const [hasAssessmentAccess, setAssessmentAccess] = useState<boolean>(true);
  const [problemSelection, setProblemSelection] = useState<boolean>(false);
  const isAdminUser = isAdmin(user);

  const { data, refetch, error } = useGetUserAssessmentDataQuery({
    variables: {
      brokee_id: Number(id),
      user_id: getUserMetaData(user).local_user_id,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        const fetchedAssessment = data.tests[0];
        setAssessment(fetchedAssessment);

        if (fetchedAssessment) {
          await handleAssessment(fetchedAssessment);
        } else {
          setLoading(false);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [data, isAdminUser, refetch, user, id]);

  const handleAssessment = async (
    fetchedAssessment: GetUserAssessmentDataQuery['tests'][0]
  ) => {
    const { test_trials } = fetchedAssessment;
    // need handle trial selection because now we select newest

    if (test_trials && test_trials.length > 0) {
      await handleExistingTrials(test_trials);
    } else if (isAdminUser) {
      await handleAdminAssessment(fetchedAssessment);
    } else {
      setAssessmentAccess(false);
      setLoading(false);
    }
  };

  const handleAdminAssessment = async (
    fetchedAssessment: GetUserAssessmentDataQuery['tests'][0]
  ) => {
    if (fetchedAssessment.dynamic_issues) {
      const featuresAllowed =
        canAccessProFeatures() || canAccessPersonalAccountFeatures();
      setProblemSelection(featuresAllowed);

      // handle creation here to fetch features  only for dynamic selection
      featuresAllowed
        ? setProblemSelection(true)
        : await handleTrialCreation(fetchedAssessment);
    } else {
      await handleTrialCreation(fetchedAssessment);
    }
    setLoading(false);
  };
  const handleExistingTrials = async (
    test_trials: GetUserAssessmentDataQuery['tests'][0]['test_trials']
  ) => {
    const trial = test_trials[0]; // Default to the first trial
    const { id: trial_id, company_id, started_at, submitted } = trial;

    try {
      const token = await getAccessTokenSilently();
      const accessData = await verifyAccessForAssessment(
        token,
        company_id || 0,
        Number(id)
      );
      setAssessmentAccess(
        accessData.access_granted || (started_at && !submitted)
      );
      sessionStorage.setItem('trial_id', String(trial_id));
      sessionStorage.setItem('company_id', String(company_id));
    } catch (error) {
      setAssessmentAccess(false);
      Bugsnag.notify(error as NotifiableError);
    } finally {
      setLoading(false);
    }
  };

  const handleTrialCreation = async (
    fetchedAssessment: GetUserAssessmentDataQuery['tests'][0],
    selectedIssues?: number
  ) => {
    try {
      const userMetaData = getUserMetaData(user);
      const token = await getAccessTokenSilently();
      const newTrial = await createTrialForUser(token, {
        userhash: getUserHash(user),
        test_id: id,
        company_id: userMetaData?.company_id,
        requester_id: userMetaData?.local_user_id,
        re_invitation_allowed: true,
        available_issues: fetchedAssessment.available_issues || 0,
        issues_per_test:
          selectedIssues !== undefined
            ? selectedIssues
            : fetchedAssessment.issues_per_test || 0,
      });

      const newTrialId = newTrial.id;
      sessionStorage.setItem('trial_id', String(newTrialId));
      sessionStorage.setItem('company_id', String(userMetaData?.company_id));
      await refetch();
    } catch (error) {
      setAssessmentAccess(false);
      Bugsnag.notify(error as NotifiableError);
    } finally {
      setLoading(false);
      setProblemSelection(false);
    }
  };

  return {
    assessment,
    loading,
    error,
    handleTrialCreation,
    hasAssessmentAccess,
    problemSelection,
  };
}
