import { Box } from '@mui/material';
interface ISingleFrameProps {
  serviceUrl: string;
}
export default function SingleFrame({ serviceUrl }: ISingleFrameProps) {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <iframe
        src={serviceUrl}
        title={'Single Frame'}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </Box>
  );
}
