import { Typography } from '@mui/material';

export default function PrivateFooter() {
  return (
    <Typography
      variant="caption"
      display="block"
      textAlign="center"
      sx={{ py: '1rem' }}
    >
      Copyright ©{new Date().getFullYear()} Brokee.
      <br />
      All rights reserved.
    </Typography>
  );
}
