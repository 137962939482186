import { useAuth0 } from '@auth0/auth0-react';
import infoIcon from '@iconify/icons-eva/info-outline';
import icon1stPlaceMedal from '@iconify/icons-twemoji/1st-place-medal';
import incomingEnvelope from '@iconify/icons-twemoji/incoming-envelope';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Paper,
  Popover,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useGetCandidatesStatisticsQuery } from '../../@generated/facadeClient';
import {
  AVERAGE_ADDITIONAL_INTERVIEWER_COST_PER_HOUR,
  AVERAGE_ADDITIONAL_INTERVIEW_TIME,
  AVERAGE_TECHNICAL_INTERVIEWER_COST_PER_HOUR,
  AVERAGE_TECHNICAL_INTERVIEW_TIME,
} from '../../constants/averageInterviewCost';
import { getUserMetaData } from '../../utils/auth';
import {
  calculateTotalSavedMoney,
  calculateTotalSavedTime,
} from '../../utils/savingsFormula';
import {
  convertNumOfHoursToReadableFormat,
  getFormatCaption,
} from '../../utils/convertNumOfHoursToReadableFormat';

function SavingsOverview() {
  const { user } = useAuth0();
  const userMetaData = getUserMetaData(user);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isInfoPopperOpen = Boolean(anchorEl);
  const popperId = isInfoPopperOpen ? 'calculator-info-popper' : undefined;

  const interviewCosts = JSON.parse(
    localStorage.getItem('interviewCosts') ||
      JSON.stringify({
        technicalInterviewerCostPerHour:
          AVERAGE_TECHNICAL_INTERVIEWER_COST_PER_HOUR,
        technicalInterviewTime: AVERAGE_TECHNICAL_INTERVIEW_TIME,
        additionalInterviewerCostPerHour:
          AVERAGE_ADDITIONAL_INTERVIEWER_COST_PER_HOUR,
        additionalInterviewTime: AVERAGE_ADDITIONAL_INTERVIEW_TIME,
      })
  );

  const { data: candidatesStatistics, loading } =
    useGetCandidatesStatisticsQuery({
      variables: {
        company_id: userMetaData.company_id,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    });

  const totalCandidates =
    candidatesStatistics?.total_invented.aggregate?.count || 0;
  const totalPassedCandidates =
    candidatesStatistics?.total_passed.aggregate?.count || 0;

  const savedTime = calculateTotalSavedTime(
    totalCandidates,
    totalPassedCandidates,
    interviewCosts.technicalInterviewTime,
    interviewCosts.additionalInterviewTime
  );

  const savedMoney = calculateTotalSavedMoney(
    totalCandidates,
    totalPassedCandidates,
    interviewCosts.technicalInterviewerCostPerHour,
    interviewCosts.technicalInterviewTime,
    interviewCosts.additionalInterviewerCostPerHour,
    interviewCosts.additionalInterviewTime
  );

  const candidatesStatisticsCards = [
    {
      count: '$ ' + savedMoney,
      bgcolor: 'rgb(240, 253, 249)',
      titleColor: 'rgb(22, 179, 100)',
      label: 'Money Saved',
      icon: icon1stPlaceMedal,
      loading,
    },

    {
      count: convertNumOfHoursToReadableFormat(savedTime),
      bgcolor: 'rgb(255, 250, 235)',
      titleColor: 'rgb(247, 144, 9)',
      label: 'Work Time Saved',
      icon: incomingEnvelope,
      loading,
      caption: getFormatCaption(savedTime),
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!savedTime || !savedMoney) {
    return null;
  }

  return (
    <Card elevation={3}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '1.5rem',
              }}
            >
              <Typography variant="h6" display="block">
                Savings Calculator
              </Typography>

              <IconButton
                id="saving-calculator-info"
                edge="start"
                color="inherit"
                onClick={handleClick}
                aria-label="close"
                aria-describedby={popperId}
              >
                <Icon icon={infoIcon} />
              </IconButton>
              <Popover
                id={popperId}
                open={isInfoPopperOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Paper elevation={2}>
                  <Box sx={{ p: '1rem', maxWidth: '30rem' }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body2" display="block">
                          These numbers depend on the average times for
                          interviews and salaries of engineers conducting those
                          interviews. Please adjust these numbers in&nbsp;
                          {
                            <Link
                              component={RouterLink}
                              to="/company/settings/dashboard"
                              underline="hover"
                              variant="subtitle2"
                            >
                              settings
                            </Link>
                          }
                          &nbsp;according to your own pay rates and your hiring
                          process.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Popover>
            </Box>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            {candidatesStatisticsCards.map(
              ({ titleColor, label, count, caption, bgcolor }) => {
                return (
                  <Grid key={label} item xs={12} sm={6}>
                    <Box
                      sx={{
                        backgroundColor: bgcolor,
                        borderRadius: '20px',
                        p: '2rem 1.5rem',
                        position: 'relative',
                      }}
                    >
                      <Grid container spacing={2} justifyItems={'center'}>
                        <Grid item xs={12} sm={12}>
                          <Typography
                            variant="h6"
                            display="block"
                            sx={{ color: titleColor, lineHeight: 1.57 }}
                            textAlign={'center'}
                          >
                            {label}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Typography
                            variant="h4"
                            display="block"
                            sx={{ lineHeight: 1.2 }}
                            textAlign={'center'}
                          >
                            {count}
                          </Typography>
                        </Grid>
                      </Grid>
                      {caption && (
                        <Typography
                          variant="body2"
                          display="block"
                          sx={{
                            position: 'absolute',
                            bottom: '.5rem',
                            right: '.5rem',
                            color: titleColor,
                          }}
                          textAlign={'center'}
                        >
                          {caption}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default SavingsOverview;
