import { ExpandMore } from '@mui/icons-material';
import {
  Typography,
  Box,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from '@mui/material';
import { useState } from 'react';
import { ProblemSetPartialFragment } from '../../@generated/types';

export function ProblemList({
  testName,
  testIssues,
}: {
  testName: string;
  testIssues: ProblemSetPartialFragment['test_issues'];
}) {
  const [selectedIssue, setSelectedIssue] = useState<string>('');
  const handleIssueChange = (issueTitle: string) => {
    if (selectedIssue === issueTitle) {
      setSelectedIssue('');
    } else {
      setSelectedIssue(issueTitle);
    }
  };
  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          p: 2,
          width: '100%',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          bgcolor: 'grey.200',
          fontWeight: 'bold',
          borderRadius: '10px',
        }}
      >
        {testName} Problems
      </Typography>
      {!testIssues?.length ? (
        <Typography
          variant="subtitle1"
          sx={{
            p: 2,
            textAlign: 'center',
          }}
        >
          No Problems Found
        </Typography>
      ) : null}
      {testIssues?.map((issue, index) => (
        <Accordion
          key={index}
          expanded={selectedIssue === issue.title || selectedIssue === 'All'}
          onChange={() => handleIssueChange(issue.title ?? 'N/A')}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1">{issue.title ?? 'N/A'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              {' '}
              <b>Explanation:</b> {issue.explanation ?? 'N/A'}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
