import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, IconButton, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { MAX_TERMINAL_TABS } from '../../../../constants/assessment';
import DescriptionFrame from './DescriptionFrame';
interface ITabsEnvironmentFrameProps {
  descriptionWithHash: string;
  descriptionHTML: React.FC<{ description: string }>;
  serviceUrl: string;
}
interface ITab {
  id: string;
  label: string;
  iframeKey: number;
}
export default function MultiTabsFrame({
  descriptionWithHash,
  descriptionHTML,
  serviceUrl,
}: ITabsEnvironmentFrameProps) {
  const [tabs, setTabs] = useState<ITab[]>([
    { id: 'tab1', label: 'Tab 1', iframeKey: 1 },
  ]);
  const [value, setValue] = useState(tabs[0]?.id || '');
  const [availableIds, setAvailableIds] = useState<number[]>([]);
  const [showDescription, setShowDescription] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleAddTab = () => {
    if (tabs.length < MAX_TERMINAL_TABS) {
      const newTabNumber =
        availableIds.length > 0 ? availableIds[0] : tabs.length + 1;
      const newTabId = `tab${newTabNumber}`;
      const newTabLabel = `Tab ${newTabNumber}`;
      const newIframeKey = Date.now(); // Unique key for the iframe
      const newTabs = [
        ...tabs,
        { id: newTabId, label: newTabLabel, iframeKey: newIframeKey },
      ];
      setTabs(newTabs);
      setValue(newTabId);
      if (availableIds.length > 0) {
        setAvailableIds(availableIds.slice(1));
      }
    }
  };

  const handleCloseTab = (tabId: string) => {
    const newTabs = tabs.filter((tab) => tab.id !== tabId);
    setTabs(newTabs);
    if (value === tabId && newTabs.length > 0) {
      setValue(newTabs[0].id);
    } else if (newTabs.length === 0) {
      setValue('');
    }
    const closedTabNumber = parseInt(tabId.replace('tab', ''), 10);
    setAvailableIds([...availableIds, closedTabNumber].sort((a, b) => a - b));
  };

  const handleToggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            aria-label="secondary tabs example"
            sx={{
              backgroundColor: '#2d2d2d',
              borderColor: '#2d2d2d',
              flexGrow: 1,
            }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                value={tab.id}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {tab.label}
                    {tabs.length > 1 && (
                      <IconButton
                        size="small"
                        id="terminal-tab-close"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCloseTab(tab.id);
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                }
                sx={{
                  p: 1,
                  pl: 2,
                  gap: 2,
                  flexShrink: 1,
                  height: '50px',
                }}
              />
            ))}
            {tabs.length < MAX_TERMINAL_TABS && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton id="terminal-tab-add" onClick={handleAddTab}>
                  <AddIcon />
                </IconButton>
              </Box>
            )}
            <Box
              sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
            >
              <Button
                id="terminal-tab-toggle-description"
                onClick={handleToggleDescription}
                variant="text"
                color="primary"
                startIcon={
                  showDescription ? <VisibilityOffIcon /> : <VisibilityIcon />
                }
              >
                {showDescription ? 'Hide Description' : 'Show Description'}
              </Button>
            </Box>
          </Tabs>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100%',
          }}
        >
          {tabs.map((tab) => (
            <div
              key={tab.id}
              style={{
                display: tab.id === value ? 'block' : 'none',
                width: '100%',
                height: '100%',
              }}
            >
              <iframe
                title={`Embedded Screen - ${tab.id}`}
                src={serviceUrl}
                width="100%"
                height="100%"
                style={{ border: 'none', overflow: 'hidden' }}
              />
            </div>
          ))}
        </Box>
      </Box>
      {showDescription && (
        <Box
          sx={{
            width: '65%',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <DescriptionFrame
            descriptionWithHash={descriptionWithHash}
            descriptionHTML={descriptionHTML}
          />
        </Box>
      )}
    </Box>
  );
}
