import * as React from 'react';
import Tab from '@mui/material/Tab';

interface TabLinkProps {
  label?: string;
  href?: string;
  value?: string;
}

export default function TabLink(props: TabLinkProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}
