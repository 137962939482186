import {
  Alert,
  AlertProps,
  AlertTitle,
  Box,
  Snackbar,
  Typography,
} from '@mui/material';

interface ISolutionProgress {
  fully_completed: boolean;
  tasks_completed: number;
  total_tasks: number;
}

interface ICheckSolutionAlertProps {
  open: boolean;
  duration?: number;
  progress: ISolutionProgress | null;
  onClose: () => void;
}

const CheckSolutionAlert: React.FC<ICheckSolutionAlertProps & AlertProps> = ({
  open,
  progress,
  onClose,
  ...props
}) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={15000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ mt: '3rem' }}
    >
      <Alert
        onClose={handleClose}
        severity="success"
        variant="filled"
        sx={{
          width: '100%',
        }}
      >
        <AlertTitle>Current Progress</AlertTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="body1">
            {`Tasks Completed: ${progress?.tasks_completed} • Total Tasks: ${
              progress?.total_tasks
            } • Completion: ${
              progress?.fully_completed ? 'Completed ✅' : 'Incomplete ❌'
            }`}
          </Typography>
        </Box>
      </Alert>
    </Snackbar>
  );
};
export default CheckSolutionAlert;
