import { useAuth0 } from '@auth0/auth0-react';

import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { TestTrialPartialFragment } from '../../../@generated/types';
import { changePassword, inviteUser } from '../utils';

interface IUseCandidatesActions {
  onSuccess(message: string): void;
  onError(error: string): void;
}

export default function useCandidateActions({
  onSuccess,
  onError,
}: IUseCandidatesActions) {
  const { getAccessTokenSilently } = useAuth0();

  const invite = useMutation(
    async (email: string) => {
      const token = await getAccessTokenSilently();

      await inviteUser(token, email);
    },
    {
      onSuccess: (data, email) => {
        onSuccess(`Invitation to ${email} was successfully sent!`);
      },
      onError: () => {
        onError(`Something went wrong. Please try again.`);
      },
    }
  );

  const handleSuccessCopyInviteUrl = async (inviteUrl: string) => {
    await navigator.clipboard.writeText(inviteUrl ?? '');
    onSuccess('Invite URL has been copied successfully!');
  };

  const getInviteUrl = useMutation(
    async ({ email, testId }: { email: string; testId: string }) => {
      const token = await getAccessTokenSilently();

      return await changePassword(token, {
        email,
        testId,
      });
    },
    {
      onSuccess: handleSuccessCopyInviteUrl,
      onError: (error: AxiosError) => {
        onError(
          (error?.response?.data as { error: string })?.error ??
            `Something went wrong. Please try again.`
        );
      },
    }
  );

  const handleCopyInviteUrl = (testTrial: TestTrialPartialFragment) => {
    getInviteUrl.mutate({
      email: testTrial.candidate.email,
      testId: testTrial.test.brokee_id.toString(),
    });
  };

  function resendInvite(testTrial: TestTrialPartialFragment) {
    invite.mutate(testTrial.candidate.email);
  }

  return { resendInvite, handleCopyInviteUrl };
}
