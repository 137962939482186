import peopleFill from '@iconify/icons-eva/people-fill';
import atOutline from '@iconify/icons-eva/at-outline';
import { IconifyIcon } from '@iconify/types';

interface IDashboardTile {
  sectionName: string;
  sectionIcon: IconifyIcon;
  title: string;
  description: string;
  navigateTo?: string;
  buttonName?: string;
}
export const dashboardTiles: IDashboardTile[] = [
  {
    sectionName: 'Candidates',
    sectionIcon: peopleFill,
    title: 'Review your candidates and invite new ones',
    description:
      'Assess potential hires, review their assessment outcomes, and send invitations to new applicants.',
    navigateTo: '/company/candidates',
    buttonName: 'Invite new candidates',
  },
  {
    sectionName: 'Contact Us',
    sectionIcon: atOutline,
    title: 'Help us improve: Share your feedback',
    description:
      "Your feedback is important to us. Please don't hesitate to contact us at <b>info@brokee.io</b> with any comments, questions, or concerns you may have.",
  },
];
