import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import {
  EEnvironmentStatuses,
  EEnvironmentType,
} from '../../../constants/assessment';
import { getUserHash, getUserTerminalSecret } from '../../../utils/auth';
import { useInfrastructure } from '../InfrastructureContext';
import { IAssessmentProps } from '../data';
import {
  getWorkingEnvServiceUrl,
  setUserHashInAssessmentDescription,
} from '../utils';
import CloudFrame from './Frames/CloudFrame';
import MultiTabsFrame from './Frames/MultiTabsFrame';
import SingleFrame from './Frames/SingleFrame';

function AssessmentEnvironment({ assessment }: IAssessmentProps) {
  const { user } = useAuth0();
  const userHash = getUserHash(user);
  const terminalSecret = getUserTerminalSecret(user);

  const {
    isInfrastructureCreated,
    isInfrastructureCreating,
    infraLoading,
    infrastructure,
  } = useInfrastructure();

  const envType = assessment?.environment_type?.type;

  const serviceUrl = getWorkingEnvServiceUrl(
    assessment?.brokee_id || 0,
    envType || '',
    terminalSecret,
    userHash
  );

  const descriptionWithHash = setUserHashInAssessmentDescription(
    assessment?.brokee_id || 0,
    assessment?.description_long || '',
    userHash
  );

  const DescriptionHTML: FC<{ description: string }> = ({ description }) => {
    return <div dangerouslySetInnerHTML={{ __html: description }} />;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height:
          isInfrastructureCreated && envType !== EEnvironmentType.CONSOLE
            ? `100vh`
            : 1, // - 50px tab headers
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Card
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius:
            envType === EEnvironmentType.CONSOLE
              ? '1rem'
              : isInfrastructureCreated
              ? 0
              : '1rem',
          mt:
            envType === EEnvironmentType.CONSOLE
              ? 0
              : isInfrastructureCreated
              ? '4rem'
              : 0,
        }}
      >
        {isInfrastructureCreated && !isInfrastructureCreating ? (
          <>
            {envType === EEnvironmentType.TERMINAL ? (
              <MultiTabsFrame
                descriptionHTML={DescriptionHTML}
                descriptionWithHash={descriptionWithHash}
                serviceUrl={serviceUrl}
              />
            ) : envType === EEnvironmentType.CODING ? (
              <SingleFrame serviceUrl={serviceUrl} />
            ) : (
              <CloudFrame
                assessmentId={assessment?.brokee_id}
                categoryName={assessment?.test_category?.name || ''}
                challengeOutputs={infrastructure?.outputs}
              />
            )}
          </>
        ) : (
          <>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" component="div">
                  Working Environment
                </Typography>
              </Box>
            </CardContent>
            <Divider />
            {infraLoading && !isInfrastructureCreating ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  p: '1rem',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Typography
                variant="subtitle1"
                sx={{ textAlign: 'center', py: '2rem', width: '100%' }}
              >
                {isInfrastructureCreating
                  ? EEnvironmentStatuses.CREATING
                  : EEnvironmentStatuses.NONEXISTENT}
              </Typography>
            )}
          </>
        )}
      </Card>
    </Box>
  );
}
export default React.memo(AssessmentEnvironment);
