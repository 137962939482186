import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { formatISO } from 'date-fns';
import { useMutation } from 'react-query';
import { useUpdateUserByUserIdMutation } from '../../../@generated/facadeClient';
import { UsersPartialFragment } from '../../../@generated/types';
import { deleteUser } from '../utils';

interface IDeleteDialogProps {
  user: UsersPartialFragment;
  onClose(): void;
  onSuccess(message: string): void;
  onError(error: string): void;
}

export default function DeleteUserDialog({
  user,
  onClose,
  onSuccess,
  onError,
}: IDeleteDialogProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [updateUserByUserIdMutation] = useUpdateUserByUserIdMutation();
  const deleteCandidate = useMutation(
    async () => {
      const token = await getAccessTokenSilently();
      await deleteUser(token, user.auth0_id);
      updateUserByUserIdMutation({
        variables: {
          user_id: user.id,
          set: {
            deleted_at: formatISO(new Date()),
          },
        },
      });
    },
    {
      onSuccess: () => {
        onSuccess(`Admin ${user.name} successfully deleted!`);
      },
      onError: () => {
        onError(`Something went wrong. Please try again.`);
      },
    }
  );

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle>Delete {user.name}?</DialogTitle>
      <DialogContent>
        <DialogContentText variant="subtitle1">
          This admin will be deleted. This action cannot be undone. Do you want
          to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="admin-cancel-deletion"
          onClick={onClose}
          variant="text"
          color="error"
        >
          Cancel
        </Button>
        <Button
          id="admin-confirm-deletion"
          variant="contained"
          color="error"
          onClick={() => {
            deleteCandidate.mutate();
          }}
        >
          Yes, Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
