export enum ESettingsTabs {
  dashboard = 'dashboard',
  subscription = 'subscription',
  billing = 'billing',
  team = 'team',
}
export const tabs: string[] = [
  ESettingsTabs.subscription,
  ESettingsTabs.billing,
  ESettingsTabs.dashboard,
  ESettingsTabs.team,
];

export const defaultLimit = 10;
export const defaultPage = 0;

export enum EConfirmationActions {
  CANCEL_GROWTH = 'CANCEL_GROWTH',
  CANCEL_GROWTH_AND_SUB_TO_ON_DEMAND = 'CANCEL_GROWTH_AND_SUB_TO_ON_DEMAND',
  GO_FROM_ON_DEMAND_TO_GROWTH = 'GO_FROM_ON_DEMAND_TO_GROWTH',
  MOVE_FROM_FREE_TO_GROWTH = 'MOVE_FROM_FREE_TO_GROWTH',
  MOVE_FROM_FREE_TO_ON_DEMAND = 'MOVE_FROM_FREE_TO_ON_DEMAND',
  CANCEL_PERSONAL = 'CANCEL_PERSONAL',
  MOVE_FROM_FREE_TO_PERSONAL = 'MOVE_FROM_FREE_TO_PERSONAL',
  MOVE_FROM_FREE_TO_ON_DEMAND_PERSONAL = 'MOVE_FROM_FREE_TO_ON_DEMAND_PERSONAL',
  GO_FROM_PERSONAL_TO_ON_DEMAND_PERSONAL = 'GO_FROM_PERSONAL_TO_ON_DEMAND_PERSONAL',
}

export const confirmationMessagesMap = {
  [EConfirmationActions.CANCEL_GROWTH]: {
    title: 'You are about to cancel your plan, would you like to proceed?',
    message:
      "Upon cancellation, you'll retain access to any unused test credits. Your last payment, if due, will be taken at the end of the current billing cycle.",
  },
  [EConfirmationActions.CANCEL_GROWTH_AND_SUB_TO_ON_DEMAND]: {
    title:
      'This action will cancel your plan and allow you to buy tests on-demand, would you like to proceed?',
    message:
      'After switching to On-Demand plan, any unused test credits will remain on your balance. Your last payment for the Growth plan, if any, will be taken at the end of the current billing cycle.',
  },
  [EConfirmationActions.GO_FROM_ON_DEMAND_TO_GROWTH]: {
    title: 'Test credits transfer.',
    message: 'Existing test credits will be added to your new credits balance.',
  },
  [EConfirmationActions.MOVE_FROM_FREE_TO_GROWTH]: {
    title:
      'You are about to upgrade your plan to Growth, would you like to proceed?',
    message:
      "Make sure you've used all your free credits, as they will expire when you start a paid plan.",
  },
  [EConfirmationActions.MOVE_FROM_FREE_TO_ON_DEMAND]: {
    title:
      'You are about to upgrade your plan to On Demand, would you like to proceed?',
    message:
      "Make sure you've used all your free credits, as they will expire when you start a paid plan.",
  },
  [EConfirmationActions.CANCEL_PERSONAL]: {
    title:
      'You are about to cancel your subscription, would you like to proceed?',
    message:
      "Upon cancellation, you'll retain access to any unused test credits. Your last payment, if due, will be taken at the end of the current billing cycle.",
  },
  [EConfirmationActions.MOVE_FROM_FREE_TO_PERSONAL]: {
    title:
      'You are about to upgrade your plan to Personal Lite, would you like to proceed?',
    message:
      "Make sure you've used all your free credits, as they will expire when you start a paid plan.",
  },
  [EConfirmationActions.MOVE_FROM_FREE_TO_ON_DEMAND_PERSONAL]: {
    title:
      'You are about to upgrade your plan to On Demand Pack, would you like to proceed?',
    message:
      "Make sure you've used all your free credits, as they will expire when you start a paid plan.",
  },
  [EConfirmationActions.GO_FROM_PERSONAL_TO_ON_DEMAND_PERSONAL]: {
    title: 'Extra Credits to Your Plan',
    message:
      'On Demand Pack adds more access credits. Your current subscription stays active, would you like to proceed?',
  },
};
