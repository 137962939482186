import { useAuth0 } from '@auth0/auth0-react';

export function SignUp() {
  const { loginWithRedirect } = useAuth0();
  loginWithRedirect({
    mode: 'signUp',
    appState: { returnTo: '/company/candidates' },
  });
  return <div></div>;
}
