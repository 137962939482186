import AssessmentIcon from '@mui/icons-material/Assessment';
import ExploreIcon from '@mui/icons-material/Explore';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { EAccountTypes } from '../../constants/accounts';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

interface Step {
  description: string;
  title: string;
  icon: JSX.Element;
}

export interface IOnBoardingSteps {
  [EAccountTypes.personal]: Step[];
  [EAccountTypes.company]: Step[];
}

export const onboardingSteps: IOnBoardingSteps = {
  [EAccountTypes.personal]: [
    {
      title: 'Explore Our Tests',
      description:
        'Browse testing library based on technology you’d like to learn, job role you are applying for or difficulty level.',
      icon: <ExploreIcon />,
    },
    {
      title: 'Take The Test',
      description:
        'Start the assessment and see if you have enough skills to resolve all problems.',
      icon: <AssessmentIcon />,
    },
    {
      title: 'Improve Your Skills',
      description:
        'Check out learning resources if you didn’t manage to complete the test and try again. Many tests generate a random set of problems, so you can keep practicing.',
      icon: <TrendingUpIcon />,
    },
    {
      title: 'Share Your Achievements',
      description:
        'Complete the assessment successfully and share your achievement! Update your resume, showcase your badge on social media, or celebrate your success.',
      icon: <EmojiEventsIcon />,
    },
  ],
  [EAccountTypes.company]: [
    {
      title: 'Explore Our Assessment',
      description:
        'Discover a variety of assessment tools tailored for evaluating team skills and potential candidates.',
      icon: <ExploreIcon />,
    },
    {
      title: 'Invite Candidates',
      description:
        'Send invitations to candidates to take the assessments and evaluate their abilities.',
      icon: <PersonAddAlt1Icon />,
    },

    {
      title: 'Analyze Results',
      description:
        'Review detailed reports and analytics to track candidate progress and make informed hiring decisions.',
      icon: <TroubleshootIcon />,
    },
  ],
};

export enum EExploreNextSteps {
  Company = 'Explore the Test Catalog and try out the available assessments under your free plan, which includes 5 credits.  Start this process by taking a test yourself to experience the platform firsthand, or invite your coworkers as candidates using their personal emails to enhance your evaluation process together. Then invite real candidates to take the tests, compare their results, and decide who is the best fit for your needs!',

  Personal = 'Enjoy your free trial by exploring the Test Catalog, where you have 3 credits to launch assessments and start practicing. Enhance your skills in a live environment, troubleshoot, and fix real-life problems while accessing learning materials to deepen your understanding.',
}
