import {
  ApolloClient,
  ApolloLink,
  Operation,
  createHttpLink,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fragmentMatcher from '../../@generated/fragmentMatcher';
import { apolloTypePolicies } from './apolloTypePolicies';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

const graphqlUri =
  process.env.REACT_APP_GRAPHQL_URL || 'https://graph.brokee.io/v1/graphql';
const graphqlWsUri =
  process.env.REACT_APP_GRAPHQL_WS_URL || 'wss://graph.brokee.io/v1/graphql';

const httpLink = createHttpLink({
  uri: ({ operationName }) => {
    return `${graphqlUri}?q=${operationName}`;
  },
});

const createWsLink = (token: string | null, operation: Operation) => {
  const { operationName, query, variables } = operation;
  return new GraphQLWsLink(
    createClient({
      url: graphqlWsUri,
      connectionParams: {
        headers: token ? privateHeaders(token) : publicHeaders,
        query,
        variables,
        operationName,
      },
      retryAttempts: Infinity,
    })
  );
};

const errorLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (!error.extensions?.skipReporting) {
        // TODO when error reporting is in place
        console.log('REPORT EXCEPTION', error);
      }
    });
  }
});

const cache = new InMemoryCache({
  possibleTypes: fragmentMatcher.possibleTypes,
  typePolicies: apolloTypePolicies,
});

const publicHeaders = {
  'X-Hasura-Role': 'anonymous',
};

const privateHeaders = (token: string) => ({
  authorization: `Bearer ${token}`,
});

export const getApolloClient = (token: string | null) => {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...(token ? privateHeaders(token) : publicHeaders),
    },
  }));

  const dynamicLink = new ApolloLink((operation, forward) => {
    const wsLink = createWsLink(token, operation);
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );

    return splitLink.request(operation, forward);
  });

  return new ApolloClient({
    cache,
    connectToDevTools: true,
    link: ApolloLink.from([errorLink, authLink, dynamicLink].filter(Boolean)),
  });
};
