import copyOutline from '@iconify/icons-eva/copy-outline';
import { Icon } from '@iconify/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { FC, useState } from 'react';

interface IInvitationLinkProps {
  inviteURL: string;
  onClose: () => void;
}

export const InvitationLink: FC<IInvitationLinkProps> = ({
  inviteURL,
  onClose,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleCopyToClipboardURL = async () => {
    await navigator.clipboard.writeText(inviteURL);
    setOpen(true);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle>Invitation Link</DialogTitle>
      <DialogContent>
        <TextField
          size="small"
          fullWidth
          variant="filled"
          label="Link URL"
          value={inviteURL}
          style={{ padding: 2 }}
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  open={open}
                  onClose={handleTooltipClose}
                  followCursor
                  placement="top"
                  title="Copied!"
                >
                  <IconButton
                    id="test-catalog-copy-invitation-link"
                    onClick={handleCopyToClipboardURL}
                  >
                    <Icon icon={copyOutline} width={20} height={20} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="test-catalog-close-invitation-link"
          onClick={onClose}
          variant="text"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvitationLink;
