import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';

export default function ProtectedRoute({
  component,
  ...args
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
PropsWithChildren<any>) {
  const Component = withAuthenticationRequired(component, args);

  return <Component />;
}
