import { useBilling } from '../../hooks/useBilling';
import { SubscriptionAlert } from '.';
import { Box } from '@mui/material';

export default function PersonalSubscriptionAlert() {
  const { canAccessPersonalAccountFeatures } = useBilling();
  return (
    <>
      {!canAccessPersonalAccountFeatures() && (
        <Box sx={{ width: '100%' }}>
          <SubscriptionAlert text="Unlock personal account premium features." />
        </Box>
      )}
    </>
  );
}
