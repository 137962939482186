import { ReactNode, createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import useAssessmentsData from './hooks/fetchAssessmentData';
import { GetUserAssessmentDataQuery } from '../../@generated/types';

interface AssessmentsContextType {
  assessment: GetUserAssessmentDataQuery['tests'][0] | null;
  problemSelection: boolean;
  hasAssessmentAccess: boolean;
  handleTrialCreation: (
    assessment: GetUserAssessmentDataQuery['tests'][0],
    selectedIssues?: number
  ) => void;
  loading: boolean;
  error: any;
}

const AssessmentContext = createContext<AssessmentsContextType | null>(null);

interface AssessmentsProviderProps {
  children: ReactNode;
}

export const AssessmentsProvider = ({ children }: AssessmentsProviderProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    assessment,
    handleTrialCreation,
    problemSelection,
    loading,
    hasAssessmentAccess,
    error,
  } = useAssessmentsData(id || '');

  return (
    <AssessmentContext.Provider
      value={{
        assessment,
        handleTrialCreation,
        problemSelection,
        loading,
        hasAssessmentAccess,
        error,
      }}
    >
      {children}
    </AssessmentContext.Provider>
  );
};

export const useAssessment = () => {
  const context = useContext(AssessmentContext);
  if (!context) {
    throw new Error('useAssessment must be used within a ChallengeProvider');
  }
  return context;
};
