import menuFill from '@iconify/icons-eva/menu-fill';
import { Icon } from '@iconify/react';
import {
  AppBar,
  Box,
  IconButton,
  SvgIcon,
  Theme,
  Toolbar,
} from '@mui/material';
import { ReactNode } from 'react';
import { AccountPopover } from '../AccountPopover';

export default function Navbar({
  extraInfo,
  onSidebarOpen,
}: {
  extraInfo?: ReactNode;
  onSidebarOpen(): void;
}) {
  return (
    <>
      <AppBar
        sx={(theme: Theme) => ({
          left: {
            lg: 280,
          },
          width: {
            lg: 'calc(100% - 280px)',
          },
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[3],
        })}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            id="navbar-page-open-sidebar"
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none',
              },
            }}
          >
            <SvgIcon>
              <Icon icon={menuFill} />
            </SvgIcon>
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          {extraInfo && (
            <Box sx={{ mr: ['0.5rem', '1.5rem'] }}>{extraInfo}</Box>
          )}
          <AccountPopover />
        </Toolbar>
      </AppBar>
    </>
  );
}
