import { GetUserAssessmentDataQuery } from '../../@generated/types';

export interface IAssessmentProps {
  assessment: GetUserAssessmentDataQuery['tests'][0] | null;
}

export const iconButtonStyle = {
  color: 'neutral.700',
  '&:hover': {
    backgroundColor: 'neutral.300',
  },
};
