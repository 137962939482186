export enum EUserRoles {
  company = 'Company',
  candidate = 'Candidate',
  personal = 'personal',
}

export enum ECompanyRoles {
  admin = 'admin',
}

export enum ECompanyRolesIds {
  admin = 1,
  viewer = 2,
  candidate = 3,
}
