import { format, parseISO } from 'date-fns';

export const getDateTime = (isoStr?: string): string => {
  if (isoStr) {
    // we should reconsider updating this format if localization is required
    return format(parseISO(isoStr), 'PP, KK:mm:ss a');
  }
  return 'N/A';
};

export function isDefined(variable: any) {
  return !(typeof variable === 'undefined' || variable === null);
}
