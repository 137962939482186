import StarIcon from '@mui/icons-material/Star';
import { Box, Button, Rating, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useInsertTestFeedbackMutation } from '../../@generated/facadeClient';
import { AssessmentAlert } from '../AssessmentAlert';
import {
  assessmentFeedbackLabels,
  feedbackCharLimit,
  getLabelText,
  IAssessmentFeedbackFormProps,
} from './data';

export default function AssessmentFeedbackForm({
  trialId,
}: IAssessmentFeedbackFormProps) {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState<number | null>(3);
  const [hover, setHover] = useState(-1);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const [insertTestFeedback] = useInsertTestFeedbackMutation();

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= feedbackCharLimit) {
      setFeedback(event.target.value);
    }
  };

  const insertFeedback = useMutation(
    async () => {
      insertTestFeedback({
        variables: {
          object: {
            trial_id: trialId,
            comments: feedback.length ? feedback : null,
            rating: rating,
          },
        },
      });
    },
    {
      onSuccess: () => {
        setAlertMessage('Thanks for taking the time to provide your review!');
        setAlertSeverity('success');
        setSnackbarOpen(true);
      },
      onError: () => {
        setAlertMessage('Oh no! Something went wrong.');
        setAlertSeverity('error');
        setSnackbarOpen(true);
      },
    }
  );
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          mb: 2,
          width: '100%',
        }}
      >
        <Typography
          variant="subtitle1"
          marginTop="1"
          gutterBottom
          align="center"
          fontWeight={550}
        >
          How would you rate your experience?
        </Typography>
        <Rating
          name="hover-feedback"
          value={rating}
          precision={1}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />}
          sx={{
            '& .MuiRating-iconFilled': {
              transition: 'none',
            },
            '& .MuiRating-iconHover': {
              transition: 'none',
            },
            '& .MuiRating-icon': {
              fontSize: '2rem',
            },
          }}
        />
        {rating !== null && (
          <Box sx={{ m: 1, textAlign: 'center' }}>
            {assessmentFeedbackLabels[hover !== -1 ? hover : rating]}
          </Box>
        )}

        <TextField
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          placeholder="Share your thoughts about this experience..."
          value={feedback}
          onChange={handleFeedbackChange}
          helperText={`${feedback.length}/${feedbackCharLimit} characters`}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            mt: 1,
          }}
        >
          <Button
            id="assessment-submit-review"
            variant="outlined"
            color="inherit"
            disabled={insertFeedback.isLoading}
            onClick={() => {
              insertFeedback.mutate();
            }}
          >
            Submit Review
          </Button>
        </Box>
      </Box>
      <AssessmentAlert
        text={alertMessage}
        open={snackbarOpen}
        severity={alertSeverity}
        variant={'standard'}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}
