export enum EPricingPlans {
  Growth = 'Growth',
  OnDemand = 'On Demand',
  Free = 'Free',
  PersonalLite = 'Personal Lite',
  OnDemandPersonal = 'On Demand Pack',
}

export enum EPurchaseType {
  free_trial = 'free_trial',
  subscription = 'subscription',
  payment = 'payment',
}

export enum ESubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
}

export enum ESubscriptionType {
  metered = 'metered',
}
export enum ESubscriptionLabels {
  LimitedAccess = 'Limited Access',
  Trial = 'TRIAL',
  Personal = 'PERSONAL',
  Pro = 'PRO',
}
