import { useBilling } from '../../../hooks/useBilling';
import { useEffect, useState } from 'react';
import { useGetPaymentRequireQuery } from '../../../@generated/facadeClient';
const useGetIsAuthorizedToAccessTest = (testId?: number): [null | boolean] => {
  const { canAccessProFeatures } = useBilling();
  const isPro = canAccessProFeatures();
  const [isAuthorized, setIsAuthorized] = useState<null | boolean>(null);
  const { data, loading, error } = useGetPaymentRequireQuery({
    variables: {
      brokee_id: testId,
    },
    nextFetchPolicy: 'network-only',
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const checkAuthorization = async () => {
      if (!loading && !error && data) {
        const authorized = data?.tests[0]?.requires_payment;
        setIsAuthorized(!authorized || isPro);
      }
    };

    checkAuthorization();
  }, [loading, error, data, isPro]);

  return [isAuthorized];
};

export default useGetIsAuthorizedToAccessTest;
