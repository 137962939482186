import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useGetCompanyDetailsByCompanyIdQuery } from '../../@generated/facadeClient';
import { EPurchaseType, EPricingPlans } from '../../constants/billing';
import { getUserMetaData, isCandidate } from '../../utils/auth';
import { IExpandedAccess, verifyAccess } from './utils';

export default function useBilling() {
  const { user, getAccessTokenSilently } = useAuth0();

  const shouldFetchData = !isCandidate(user);

  const { data: companyDetails } = useGetCompanyDetailsByCompanyIdQuery({
    variables: {
      company_id: getUserMetaData(user)?.company_id,
    },
    skip: !shouldFetchData,
  });

  const {
    data: companyAccessDetails,
    isLoading: companyAccessDetailsLoading,
    refetch: refetchCompanyAccessDetails,
  } = useQuery<IExpandedAccess>({
    queryKey: 'verifyAccess',
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      return await verifyAccess(token, getUserMetaData(user)?.company_id);
    },
    enabled: !!companyDetails?.companies?.[0]?.['stripe_customer_id'],
  });

  const isFreeTrialRegistration = useCallback(() => {
    return (
      !companyAccessDetails &&
      companyDetails &&
      !companyDetails.companies[0]?.stripe_customer_id
    );
  }, [companyAccessDetails, companyDetails]);

  const getAvailableCredits = useCallback(() => {
    return companyAccessDetails?.available_credits ?? 0;
  }, [companyAccessDetails]);

  const hasActiveFreeTrial = useCallback(() => {
    return (
      !!companyAccessDetails &&
      companyAccessDetails.purchase_type === EPurchaseType.free_trial &&
      companyAccessDetails.available_credits > 0
    );
  }, [companyAccessDetails]);

  const canAccessProFeatures = useCallback(() => {
    return (
      !!companyAccessDetails &&
      (companyAccessDetails.available_credits > 0 ||
        companyAccessDetails.is_active_subscription) &&
      companyAccessDetails.purchase_type !== EPurchaseType.free_trial
    );
  }, [companyAccessDetails]);

  const canAccessPersonalAccountFeatures = useCallback(() => {
    if (!shouldFetchData) return true;
    return (
      !!companyAccessDetails &&
      (companyAccessDetails.available_credits > 0 ||
        companyAccessDetails.is_active_subscription) &&
      companyAccessDetails.purchase_type !== EPurchaseType.free_trial
    );
  }, [companyAccessDetails, shouldFetchData]);

  const canAccessFreeFeatures = useCallback(() => {
    return hasActiveFreeTrial() || canAccessProFeatures();
  }, [canAccessProFeatures, hasActiveFreeTrial]);

  const hasNoAccess = useCallback(() => {
    return !canAccessFreeFeatures() && !canAccessProFeatures();
  }, [canAccessFreeFeatures, canAccessProFeatures]);

  const hasCanceledGrowthPlan = useCallback(() => {
    return (
      !companyAccessDetailsLoading &&
      !companyAccessDetails?.is_active_subscription &&
      !companyAccessDetails?.stripe_subscription_id
    );
  }, [companyAccessDetails, companyAccessDetailsLoading]);

  const hasCanceledPersonalPlan = useCallback(() => {
    return (
      !companyAccessDetailsLoading &&
      !companyAccessDetails?.is_active_subscription &&
      companyAccessDetails?.stripe_subscription_id &&
      companyAccessDetails?.plan_name === EPricingPlans.PersonalLite
    );
  }, [companyAccessDetails, companyAccessDetailsLoading]);

  const hasLimitedAccess = useCallback(() => {
    return !!companyAccessDetails && !companyAccessDetails?.access_granted;
  }, [companyAccessDetails]);

  return {
    companyAccessDetails,
    companyAccessDetailsLoading,
    isFreeTrialRegistration,
    canAccessFreeFeatures,
    canAccessProFeatures,
    canAccessPersonalAccountFeatures,
    hasActiveFreeTrial,
    hasNoAccess,
    hasLimitedAccess,
    hasCanceledGrowthPlan,
    hasCanceledPersonalPlan,
    getAvailableCredits,
    refetchCompanyAccessDetails,
  };
}
