import { Box } from '@mui/material';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAssessment } from './AssessmentContext';
import AssessmentDescription from './AssessmentDescription';
import AssessmentDiagram from './AssessmentDiagram';
import AssessmentEnvironment from './WorkingEnvironment/Environment';
import EnvironmentSummary from './WorkingEnvironment/Summary';

// TODO:
// * full screen for code server

export default function AssessmentPage() {
  const location = useLocation();
  const path = location.pathname.split('/').pop();
  const { assessment } = useAssessment();

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ display: path === 'description' ? 'block' : 'none' }}>
        <AssessmentDescription assessment={assessment} />
      </Box>
      <Box sx={{ display: path === 'diagram' ? 'block' : 'none' }}>
        <AssessmentDiagram imageUrl={assessment?.arch_diagram_image || ''} />
      </Box>
      <Box sx={{ display: path === 'environment' ? 'block' : 'none' }}>
        <AssessmentEnvironment assessment={assessment} />
      </Box>
      <Box sx={{ display: path === 'summary' ? 'block' : 'none' }}>
        <EnvironmentSummary assessment={assessment} />
      </Box>

      <Routes>
        <Route path="description" element={<></>} />
        <Route path="diagram" element={<></>} />
        <Route path="environment" element={<></>} />
        <Route path="environment/summary" element={<></>} />
        <Route path="*" element={<Navigate to="description" />} />
      </Routes>
    </Box>
  );
}
