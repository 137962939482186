import { TypePolicies } from '@apollo/client';

// const disabledTypePolicy = {
//   keyFields: false as never,
// };
const baseTypePolicy = {
  keyFields: ['id'],
};

const brokeeBaseTypePolicy = {
  keyFields: ['brokee_id'],
};

// function mergeUsingIncoming<T>() {
//   return (_cached: T, incoming: T) => incoming;
// }

export const apolloTypePolicies: TypePolicies = {
  tests: brokeeBaseTypePolicy,
  test_trials: baseTypePolicy,
  users: baseTypePolicy,
  pricing_plans: baseTypePolicy,
  billing_intervals: baseTypePolicy,
  customer_usages: baseTypePolicy,
  subscriptions: baseTypePolicy,
  companies: baseTypePolicy,
};
