import { useAuth0 } from '@auth0/auth0-react';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import StarIcon from '@mui/icons-material/Star';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Test_StatsFragmentFragment,
  UserTestTrialPartialFragment,
} from '../../../@generated/types';
import { assessmentFeedbackLabels } from '../../../components/AssessmentFeedback/data';
import { useBilling } from '../../../hooks/useBilling';
import {
  formatCompanyRankingPositionDescription,
  getCompletionPercentageColor,
  getPercentageDifference,
} from '../../../utils/common';
import {
  ICompletionDetails,
  downloadFile,
  getDateTime,
  getTestStatus,
  getTestTimeTaken,
  getUserCommandHistory,
  isNumber,
  removeBackticks,
  replaceTextWithEmojis,
} from '../utils';
import TestDescriptionCard from './TestDescriptionCard';
interface ICandidatesPageDetailsBodyProps {
  testTrial: UserTestTrialPartialFragment;
  globalTestMetrics: Test_StatsFragmentFragment | Record<string, never>;
  onGettingCommandHistoryError: (message: string) => void;
}
export function CandidatesPageDetailsBody({
  globalTestMetrics,
  testTrial,
  onGettingCommandHistoryError,
}: ICandidatesPageDetailsBodyProps) {
  const {
    passed,
    total_tasks,
    completed_tasks,
    completion_details,
    completion_score,
    submitted_at,
    time_taken_seconds,
    ai_summary,
    ai_questions,
    test_trial_issues,
    test_feedback,
    test,
    user_test_stat,
  } = testTrial;
  const { average_passed_time, total_passed } = globalTestMetrics;
  const { getAccessTokenSilently } = useAuth0();
  const [expanded, setExpanded] = useState<string | false>(false);
  const { canAccessProFeatures } = useBilling();

  const timeCompletedBelow = time_taken_seconds < average_passed_time;

  const timeCompletedDiff = getPercentageDifference(
    time_taken_seconds,
    average_passed_time
  );

  const completion_details_object: ICompletionDetails =
    JSON.parse(completion_details);

  const { data: historyFileContent = '' } = useQuery<string>(
    'historyFileContent' + testTrial.id,
    async () => {
      if (!testTrial.submitted_at) return '';

      const token = await getAccessTokenSilently();
      const filesData = await getUserCommandHistory(
        token,
        testTrial.candidate.userhash,
        testTrial.test.brokee_id,
        false,
        testTrial.started_at,
        testTrial.submitted_at
      );

      let res = '';
      const downloadUrl = filesData?.files[0]?.download_url;
      if (downloadUrl) {
        res += await fetch(downloadUrl)
          .then((response) => response.text())
          .then((data) => {
            return data;
          });
      }
      return res;
    },
    {
      refetchOnMount: 'always',
    }
  );

  async function onDownloadCommandHistory() {
    try {
      const token = await getAccessTokenSilently();
      const filesData = await getUserCommandHistory(
        token,
        testTrial.candidate.userhash,
        testTrial.test.id,
        false,
        testTrial.started_at,
        testTrial.submitted_at
      );

      const download_url = filesData?.files[0]?.download_url;
      if (!download_url) {
        onGettingCommandHistoryError(
          "Oops, we couldn't retrieve the commands history, or this may not exist due to candidate inactivity. Contact us via chat or at info@brokee.io for assistance."
        );
      } else {
        downloadFile(
          download_url,
          `${testTrial.candidate.email}_commands_history.txt`
        );
      }
    } catch (error) {
      onGettingCommandHistoryError(
        "Oops, we couldn't retrieve the commands history, or this may not exist due to candidate inactivity. Contact us via chat or at info@brokee.io for assistance."
      );
      Bugsnag.notify(error as NotifiableError);
    }
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Card elevation={3}>
      <CardHeader
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              gap: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h5">{testTrial.test.name}</Typography>
              <Chip
                sx={{ mt: 0.5 }}
                color={
                  test.test_difficulty?.level.toLowerCase() === 'hard'
                    ? 'error'
                    : test.test_difficulty?.level.toLowerCase() === 'easy'
                    ? 'success'
                    : 'warning'
                }
                size="small"
                label={`${test.test_difficulty?.level.toLowerCase()}`}
              />
            </Box>
            {/* {passed === true && user_test_stat && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip
                  title={`Candidate is ranked ${user_test_stat.ranking_position}th globally`}
                >
                  <Chip
                    label={`${user_test_stat.ranking_position}`}
                    size="medium"
                    color="default"
                    variant="outlined"
                    icon={<StarsIcon color="inherit" />}
                  ></Chip>
                </Tooltip>
              </Box>
            )} */}
          </Box>
        }
      />
      <List>
        <ListItem divider={passed != null}>
          <ListItemText
            sx={{ display: 'flex' }}
            primary={
              <Typography variant={'subtitle2'} fontWeight={500} width="50%">
                Status:
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} width="50%">
                {getTestStatus(passed)}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        {passed === true && user_test_stat && (
          <ListItem divider>
            <ListItemText
              sx={{ display: 'flex' }}
              primary={
                <Typography variant={'subtitle2'} fontWeight={500} width="50%">
                  Ranking:
                </Typography>
              }
              secondary={
                <Tooltip
                  title={`Candidate is ranked ${user_test_stat.ranking_position} globally`}
                >
                  <Box display="flex" alignItems="center">
                    <Chip
                      label={`${user_test_stat.ranking_position}`}
                      size="small"
                      color="default"
                      variant="outlined"
                      icon={<WorkspacePremiumIcon color="inherit" />}
                    ></Chip>
                    <Typography
                      variant={'body2'}
                      color={'black'}
                      paddingLeft={2}
                    >
                      {formatCompanyRankingPositionDescription(
                        user_test_stat.ranking_position,
                        total_passed
                      )}
                    </Typography>
                  </Box>
                </Tooltip>
              }
            ></ListItemText>
          </ListItem>
        )}
        {isNumber(total_tasks) && isNumber(completed_tasks) && (
          <ListItem divider>
            <ListItemText
              sx={{ display: 'flex' }}
              primary={
                <Typography variant={'subtitle2'} fontWeight={500} width="100%">
                  Completed Tasks:
                </Typography>
              }
              secondary={
                <Typography variant={'body2'} width="100%">
                  {completed_tasks}/{total_tasks}
                </Typography>
              }
            ></ListItemText>
          </ListItem>
        )}

        {completion_score !== null && (
          <ListItem divider>
            <ListItemText
              sx={{ display: 'flex' }}
              primary={
                <Typography variant={'subtitle2'} fontWeight={500} width="100%">
                  Completion Score:
                </Typography>
              }
              secondary={
                <Typography
                  variant={'body2'}
                  width="100%"
                  component="div"
                  color={getCompletionPercentageColor(completion_score || 0)}
                >
                  {completion_score}%
                </Typography>
              }
            ></ListItemText>
          </ListItem>
        )}
        {time_taken_seconds !== null && (
          <ListItem divider>
            <ListItemText
              sx={{ display: 'flex' }}
              primary={
                <Typography
                  variant={'subtitle2'}
                  fontWeight={500}
                  component="div"
                  width="50%"
                >
                  Time Taken:
                </Typography>
              }
              secondary={
                <Tooltip
                  title={
                    passed === true &&
                    time_taken_seconds !== average_passed_time &&
                    (timeCompletedBelow
                      ? `This completion time is ${timeCompletedDiff}% faster than the average time of ${getTestTimeTaken(
                          average_passed_time
                        )}.`
                      : `This completion time is ${timeCompletedDiff}% slower than the average time of ${getTestTimeTaken(
                          average_passed_time
                        )}.`)
                  }
                >
                  <Box display="flex" alignItems="center" component="div">
                    <Typography
                      variant={'body2'}
                      component="div"
                      color={'black'}
                    >
                      {time_taken_seconds === 0
                        ? 'Submitted in under 1 minute'
                        : getTestTimeTaken(time_taken_seconds)}
                    </Typography>
                    {passed === true &&
                      time_taken_seconds !== average_passed_time &&
                      (timeCompletedBelow ? (
                        <TrendingUpIcon
                          color="success"
                          style={{ marginLeft: 8 }}
                        />
                      ) : (
                        <TrendingDownIcon
                          color="error"
                          style={{ marginLeft: 8 }}
                        />
                      ))}
                    {passed === true &&
                      time_taken_seconds !== average_passed_time && (
                        <Typography
                          variant="body2"
                          sx={{ ml: 1 }}
                          component="div"
                          color={
                            timeCompletedBelow ? 'success.main' : 'error.main'
                          }
                        >
                          {`${timeCompletedDiff}%`}
                        </Typography>
                      )}
                  </Box>
                </Tooltip>
              }
            />
          </ListItem>
        )}
        {submitted_at !== null && (
          <ListItem>
            <ListItemText
              sx={{ display: 'flex' }}
              primary={
                <Typography variant={'subtitle2'} fontWeight={500} width="100%">
                  Submitted at:
                </Typography>
              }
              secondary={
                <Typography variant={'body2'} width="100%">
                  {submitted_at && getDateTime(submitted_at)}
                </Typography>
              }
            ></ListItemText>
          </ListItem>
        )}
      </List>
      {completion_details_object && (
        <Accordion
          disableGutters
          elevation={0}
          square
          expanded={expanded === 'panel1'}
          onChange={handleChange(expanded !== 'panel1' ? 'panel1' : '')}
        >
          <AccordionSummary
            expandIcon={<Icon icon={arrowIosForwardFill} />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography variant={'subtitle2'} fontWeight={500} width="100%">
              Automated Checks{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {completion_details_object.completed_tasks &&
              completion_details_object.completed_tasks.map((task) => (
                <Typography
                  variant={'body2'}
                  width="100%"
                  sx={{
                    fontSize: 15,
                    p: 1,
                  }}
                  key={task}
                >
                  {removeBackticks(replaceTextWithEmojis(task))}
                </Typography>
              ))}
            {completion_details_object?.failed_tasks &&
              completion_details_object?.failed_tasks.map((task) => (
                <Typography
                  variant={'body2'}
                  sx={{
                    fontSize: 15,
                    p: 1,
                  }}
                  width="100%"
                  key={task}
                >
                  {removeBackticks(replaceTextWithEmojis(task))}
                </Typography>
              ))}
            {completion_details_object.timing_info && (
              <Typography
                variant={'body2'}
                sx={{
                  fontSize: 15,
                  p: 1,
                }}
                width="100%"
                key={completion_details_object.timing_info}
              >
                {removeBackticks(
                  replaceTextWithEmojis(completion_details_object.timing_info)
                )}
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {canAccessProFeatures() && test_trial_issues.length > 0 && (
        <Accordion
          disableGutters
          elevation={0}
          square
          expanded={expanded === 'panel3'}
          onChange={handleChange(expanded !== 'panel3' ? 'panel3' : '')}
        >
          <AccordionSummary
            expandIcon={<Icon icon={arrowIosForwardFill} />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography variant="subtitle2" fontWeight={500} width="100%">
              Problem Set{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {test_trial_issues.map((issue, index) => [
              <Grid
                container
                key={index}
                alignItems="center"
                sx={{
                  backgroundColor: index % 2 === 0 ? 'neutral.100' : 'inherit',
                  borderRadius: '0.825rem',
                  padding: '0.6rem',
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    width="100%"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      m: '0.525rem',
                    }}
                  >
                    {index + 1}. {issue.issue?.title}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" width="100%">
                    {issue.issue?.explanation}
                  </Typography>
                </Grid>
              </Grid>,
            ])}
          </AccordionDetails>
        </Accordion>
      )}
      {historyFileContent && (
        <Accordion
          disableGutters
          elevation={0}
          square
          expanded={expanded === 'panel55'}
          onChange={handleChange(expanded !== 'panel55' ? 'panel55' : '')}
        >
          <AccordionSummary
            expandIcon={<Icon icon={arrowIosForwardFill} />}
            aria-controls="panel55-content"
            id="panel44-header"
          >
            <Typography variant="subtitle2" fontWeight={500} width="100%">
              History Of commands{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>
              <code>{historyFileContent}</code>
            </pre>
          </AccordionDetails>
        </Accordion>
      )}

      {test_feedback && (
        <Accordion
          disableGutters
          elevation={0}
          square
          expanded={expanded === 'panel6'}
          onChange={handleChange(expanded !== 'panel6' ? 'panel6' : '')}
        >
          <AccordionSummary
            expandIcon={<Icon icon={arrowIosForwardFill} />}
            aria-controls="panel6-content"
            id="panel6-header"
          >
            <Typography variant="subtitle2" fontWeight={500} width="100%">
              Feedback
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant={'body2'}
                sx={{ fontSize: 15, display: 'inline', m: 1, fontWeight: 550 }}
              >
                Experience Rating:
              </Typography>
              <Rating
                name="test-feedback"
                value={test_feedback.rating}
                readOnly
                precision={1}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              <Box sx={{ ml: 2, fontSize: 15 }}>
                {assessmentFeedbackLabels[test_feedback.rating]}
              </Box>
            </Box>
            <Typography
              variant={'body2'}
              sx={{ fontSize: 15, m: 1, fontWeight: 550 }}
              width="100%"
              key={test_feedback.comments}
            >
              Review Comment:{' '}
              <span style={{ fontWeight: 400 }}>
                {test_feedback.comments
                  ? test_feedback.comments
                  : 'No comments from the candidate yet'}
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion
        disableGutters
        elevation={0}
        square
        expanded={expanded === 'panel44'}
        onChange={handleChange(expanded !== 'panel44' ? 'panel44' : '')}
      >
        <AccordionSummary
          expandIcon={<Icon icon={arrowIosForwardFill} />}
          aria-controls="panel44-content"
          id="panel44-header"
        >
          <Typography variant="subtitle2" fontWeight={500} width="100%">
            Test Description{' '}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TestDescriptionCard
            title={test['name'] || ''}
            description={test['description_human'] || ''}
            descriptionLong={test['description_long'] || ''}
            image={test['arch_diagram_image'] || ''}
          />
        </AccordionDetails>
      </Accordion>
      {canAccessProFeatures() && ai_summary && (
        <Accordion
          disableGutters
          elevation={0}
          square
          expanded={expanded === 'panel2'}
          onChange={handleChange(expanded !== 'panel2' ? 'panel2' : '')}
        >
          <AccordionSummary
            expandIcon={<Icon icon={arrowIosForwardFill} />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Box
              sx={{
                display: 'inline-flex',
                m: '0',
                alignItems: 'center',
                gap: 0.5,
              }}
              width="100%"
            >
              <Typography variant={'subtitle2'} fontWeight={500}>
                Summary of Candidate Performance{' '}
              </Typography>
              <Chip size="small" color="secondary" label="AI Generated" />{' '}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              component="div"
              lineHeight={2}
              display="block"
              color="text"
              gutterBottom
              sx={{
                fontSize: 15,
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                p: 1,
                pt: 0,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: ai_summary }} />
            </Typography>
          </AccordionDetails>
          <Divider />
        </Accordion>
      )}
      {canAccessProFeatures() && ai_questions && (
        <Accordion
          disableGutters
          elevation={0}
          square
          expanded={expanded === 'panel4'}
          onChange={handleChange(expanded !== 'panel4' ? 'panel4' : '')}
        >
          <AccordionSummary
            expandIcon={<Icon icon={arrowIosForwardFill} />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Box
              sx={{
                display: 'inline-flex',
                m: '0',
                alignItems: 'center',
                gap: 0.5,
              }}
              width="100%"
            >
              <Typography variant={'subtitle2'} fontWeight={500}>
                Follow-up Questions for Candidate{' '}
              </Typography>
              <Chip size="small" color="secondary" label="AI Generated" />{' '}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              component="div"
              lineHeight={2}
              display="block"
              color="text"
              gutterBottom
              sx={{
                fontSize: 15,
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                p: 1,
                pt: 0,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: ai_questions }} />
            </Typography>
          </AccordionDetails>
          <Divider />
        </Accordion>
      )}
      {passed != null && (
        <CardActions
          sx={{
            p: '12px !important',
          }}
        >
          <Stack alignItems="flex-start">
            <Button
              variant="contained"
              color="primary"
              onClick={onDownloadCommandHistory}
              disabled={passed == null}
            >
              Download Commands History
            </Button>
            <Typography
              variant={'caption'}
              sx={{
                color: 'text.secondary',
              }}
            >
              See all actions the candidate took to complete the test.
            </Typography>
          </Stack>
        </CardActions>
      )}
    </Card>
  );
}
