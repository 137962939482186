import { Routes, Route, Navigate } from 'react-router-dom';
import { TestInsightsList } from './TestInsightsList';
export default function TestInsightsPage() {
  return (
    <Routes>
      <Route path="/" element={<TestInsightsList />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}
