import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  AlertColor,
  Box,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  useGetUniqueTestTrialsByIdQuery,
  useSubscribeTestTrialsCountSubscription,
} from '../../../@generated/facadeClient';
import { useDebounce } from '../../../hooks/useDebounce';
import { isCandidate } from '../../../utils/auth';
import useConditionalTestTrialsQuery from '../hooks/useConditionalTestTrialsQuery';
import {
  getTestTrialsCountQueryVariables,
  getTestTrialsQueryVariables,
  isValidLimit,
  isValidPage,
} from '../utils';
import CandidatesTable from './CandidatesTable';
import {
  EFilterByResultKeys,
  ESortByKeys,
  defaultLimit,
  defaultPage,
  filterByTestResult,
  sortBy,
} from './data';
export default function TestTrialsPageList() {
  const { user } = useAuth0();

  const [searchParams, setSearchParams] = useSearchParams({
    search: '',
    filterByTest: 'All',
    sort: ESortByKeys.invitedAtDown,
    filterByResult: EFilterByResultKeys.all,
    page: defaultPage.toString(),
    limit: defaultLimit.toString(),
  });

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');
  const isValidSortOption = (searchParams.get('sort') || '') in ESortByKeys;
  const isValidFilterByResultOption =
    (searchParams.get('filterByResult') || '') in EFilterByResultKeys;

  const page = isValidPage(Number(searchParams.get('page')))
    ? Number(searchParams.get('page'))
    : defaultPage;

  const limit = isValidLimit(Number(searchParams.get('limit')))
    ? Number(searchParams.get('limit'))
    : defaultLimit;

  const search = searchParams.get('search') || '';
  const sort = isValidSortOption
    ? (searchParams.get('sort') as ESortByKeys)
    : ESortByKeys.invitedAtDown;

  const filterByResult = isValidFilterByResultOption
    ? (searchParams.get('filterByResult') as EFilterByResultKeys)
    : EFilterByResultKeys.all;

  const filterByTest = searchParams.get('filterByTest') || '';

  const debouncedSearch = useDebounce(search, 1000);
  const { data: { test_trials } = { test_trials: [] } } =
    useGetUniqueTestTrialsByIdQuery();
  const sortedTestTrials = [...test_trials].sort((a, b) =>
    a.test.name.localeCompare(b.test.name)
  );

  const selectedTestFilterId =
    sortedTestTrials
      .find((i) => i.test.name === filterByTest)
      ?.test.brokee_id.toString() || 'All';
  const { assessment_link_id } = useParams<{ assessment_link_id?: string }>();

  const generalQueryVariables = getTestTrialsQueryVariables({
    limit,
    page,
    search: debouncedSearch,
    sort,
    filterByTest: selectedTestFilterId,
    filterByResult,
  });

  const aggregateQueryVariable = getTestTrialsCountQueryVariables({
    search: debouncedSearch,
    sort,
    filterByTest: selectedTestFilterId,
    filterByResult,
  });

  const assessmentLinkQueryVariables = {
    ...generalQueryVariables,
    variables: {
      ...generalQueryVariables.variables,
      assessmentLinkId: assessment_link_id,
    },
  };

  const { query, variables } = useConditionalTestTrialsQuery(
    assessment_link_id,
    generalQueryVariables,
    assessmentLinkQueryVariables
  );

  const { data: testTrialsData, loading: testTrialsLoading } = query(variables);
  const { data: testTrialsCountData } = useSubscribeTestTrialsCountSubscription(
    aggregateQueryVariable
  );
  useEffect(() => {
    // search params guard
    if (
      !isValidPage(Number(searchParams.get('page'))) ||
      !isValidLimit(Number(searchParams.get('limit'))) ||
      !isValidSortOption ||
      !isValidFilterByResultOption
    ) {
      setSearchParams((prev) => {
        !isValidPage(Number(searchParams.get('page'))) &&
          prev.set('page', defaultPage.toString());
        !isValidLimit(Number(searchParams.get('limit'))) &&
          prev.set('limit', defaultLimit.toString());
        !isValidSortOption && prev.set('sort', ESortByKeys.invitedAtDown);
        !isValidFilterByResultOption &&
          prev.set('filterByResult', EFilterByResultKeys.all);
        return prev;
      });
    }
  }, [
    searchParams,
    setSearchParams,
    isValidFilterByResultOption,
    isValidSortOption,
  ]);

  const { test_trials: testTrials } = testTrialsData ?? {};

  const testTrialsAggregate = testTrialsCountData?.test_trials_aggregate;
  const total = testTrialsAggregate?.aggregate?.count ?? 0;

  const trialResultsBasePath = isCandidate(user)
    ? '/candidate/test-invites'
    : '/company/tests-history';

  const handleLimitChange = (limitBy: number) => {
    setSearchParams((prev) => {
      prev.set('limit', limitBy.toString());
      return prev;
    });
  };

  const handlePageChange = (pageBy: number) => {
    setSearchParams((prev) => {
      prev.set('page', pageBy.toString());
      return prev;
    });
  };

  const handleSortByChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => {
      prev.set('sort', event.target.value);
      return prev;
    });
  };

  const handleFilterByTestChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => {
      prev.set('filterByTest', event.target.value);
      prev.set('limit', '0');
      return prev;
    });
  };

  const handleFilterByResultChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => {
      prev.set('filterByResult', event.target.value);
      prev.set('limit', '0');
      return prev;
    });
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleSuccess = (message: string) => {
    setShowSnackbar(true);
    setSeverity('success');
    setMessage(message);
  };

  const handleError = (error: string) => {
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(error);
  };

  return (
    <>
      {/* {!isCandidate(user) && <PersonalSubscriptionAlert />} */}
      <Box
        sx={{
          mt: '1rem',
          mb: '2rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap-reverse',
        }}
      >
        <Typography variant="h3" display="block">
          {isCandidate(user) ? 'Test Invites' : 'Previous Tests'}
        </Typography>
      </Box>
      <Paper elevation={3}>
        <Grid container sx={{ p: '1rem' }} spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              fullWidth
              label="Sort By"
              value={sort}
              onChange={handleSortByChange}
              SelectProps={{
                native: true,
              }}
            >
              {sortBy.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              fullWidth
              label="Test Name"
              value={filterByTest}
              onChange={handleFilterByTestChange}
              SelectProps={{
                native: true,
              }}
            >
              <option value={'All'}>All</option>
              {sortedTestTrials.map(({ test }) => (
                <option key={`challenge${test.brokee_id}`} value={test.name}>
                  {test.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              fullWidth
              label="Test Result"
              value={filterByResult}
              onChange={handleFilterByResultChange}
              SelectProps={{
                native: true,
              }}
            >
              {filterByTestResult?.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <CandidatesTable
          testTrials={testTrials ?? []}
          trialResultsBasePath={trialResultsBasePath}
          onSuccess={handleSuccess}
          onError={handleError}
          onChangeLimit={handleLimitChange}
          onChangePage={handlePageChange}
          page={page}
          limit={limit}
          total={total}
          loading={testTrialsLoading}
        />
      </Paper>
      {showSnackbar && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            variant="filled"
            onClose={handleSnackbarClose}
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
