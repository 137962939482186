import { EPricingPlans, EPurchaseType } from '../../constants/billing';
import { axiosInstance } from '../../utils/axios';

export interface IExpandedAccess {
  access_granted: boolean;
  available_credits: number;
  is_active_subscription: boolean;
  plan_name: EPricingPlans;
  purchase_type: EPurchaseType;
  stripe_subscription_id: string | null;
}

export const verifyAccess = async (
  accessToken: string,
  company_id: number
): Promise<IExpandedAccess> => {
  const { data } = await axiosInstance.get(
    `/api/stripe/customers/${company_id}/verify-assessment-access?expand=true`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const verifyAccessForAssessment = async (
  accessToken: string,
  companyId: number,
  assessmentId: number
): Promise<IExpandedAccess> => {
  const { data } = await axiosInstance.get(
    `/api/stripe/customers/${companyId}/verify-assessment-access?test_id=${assessmentId}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};
