import { ApolloProvider } from '@apollo/client';
import { ReactElement, ReactNode } from 'react';
import { useAccessToken } from '../../hooks/useAccessToken';
import { getApolloClient } from '../../utils/graphQL';

export default function Provider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const idToken = useAccessToken();
  const client = getApolloClient(idToken);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
