import React from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

Bugsnag.start({
  releaseStage: process.env.REACT_APP_NODE_ENV as string,
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY as string,
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY as string,
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

export default ErrorBoundary;
