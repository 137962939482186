import { useAuth0 } from '@auth0/auth0-react';
import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { SubscriptionAlert } from '../../../components/SubscriptionAlert';

import { isCompany } from '../../../utils/auth';

import { useBilling } from '../../../hooks/useBilling';

import { ESubscriptionFeaturesList } from '../../../constants/features';
import FailedTestsOverview from './FailedTestsOverview';
import MostAttemptedOverview from './MostAttemptedOverview';
import TrendingTestsOverview from './TrendingTestsOverview';

const TestInsightsList: FC = () => {
  const { user } = useAuth0();
  const { canAccessProFeatures } = useBilling();

  return (
    <>
      {!canAccessProFeatures() && isCompany(user) && (
        <Box sx={{ width: '100%' }}>
          <SubscriptionAlert
            features={ESubscriptionFeaturesList.TEST_INSIGHTS_PAGE}
          />
        </Box>
      )}
      <Typography
        variant="h3"
        display="block"
        sx={{
          mt: '1rem',
          mb: '2rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap-reverse',
        }}
      >
        Test Insights
      </Typography>
      <Box sx={{ '& > *': { mb: 3 } }}>
        <TrendingTestsOverview />
        {isCompany(user) && canAccessProFeatures() && <MostAttemptedOverview />}
        {isCompany(user) && canAccessProFeatures() && <FailedTestsOverview />}
      </Box>
    </>
  );
};

export default TestInsightsList;
