import { Box, Button, Card, CardContent, Typography } from '@mui/material';

interface IErrorPageAlertProps {
  message?: string;
  onClose: () => void;
}

const AssessmentAccessDenied: React.FC<IErrorPageAlertProps> = ({
  message,
  onClose,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        p: 2,
      }}
    >
      <Card
        sx={{
          width: '100%',
          maxWidth: '700px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          boxShadow: 3,
          borderRadius: 2,
          p: 2,
        }}
      >
        <CardContent>
          <Typography variant="h6" component="div">
            {'Access Denied'}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {
              'You do not have permission to access this assessment. This may be due to insufficient credits or lack of an invitation.'
            }
            {message}
          </Typography>
        </CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1rem',
            mt: 1,
          }}
        >
          {onClose && (
            <Button
              id="assessment-access-denied"
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              Close
            </Button>
          )}
        </Box>
      </Card>
    </Box>
  );
};
export default AssessmentAccessDenied;
