import { Box, Typography } from '@mui/material';

interface IDescriptionProps {
  descriptionWithHash: string;
  descriptionHTML: React.FC<{ description: string }>;
}

export default function DescriptionFrame({
  descriptionWithHash,
  descriptionHTML,
}: IDescriptionProps) {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        p: '1.5rem',
        maxHeight: '100%',
        overflowY: 'auto',
      }}
    >
      <Typography
        variant="body2"
        display="block"
        color="text.primary"
        gutterBottom
        component="div"
        lineHeight={1.7}
        sx={{
          fontSize: '1rem',
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        {descriptionWithHash &&
          descriptionHTML({
            description: descriptionWithHash,
          })}
        <br />
      </Typography>
    </Box>
  );
}
