import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StarIcon from '@mui/icons-material/Star';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Rating,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  PersonalTestTrialPartialFragment,
  Test_Tech_Services_Resources,
} from '../../../@generated/types';
import { AssessmentAlert } from '../../../components/AssessmentAlert';
import { assessmentFeedbackLabels } from '../../../components/AssessmentFeedback/data';
import { copyBadgePublicUrl } from '../../../components/Badges/data';
import useClaimBadge from '../../../components/Badges/useClaimBadge';
import LearningResourcesView from '../../../components/LearningResourcesView';
import {
  formatRegularRankingPositionDescription,
  getCompletionPercentageColor,
} from '../../../utils/common';
import {
  ICompletionDetails,
  getDateTime,
  getTestStatus,
  getTestStatusColor,
  getTestTimeTaken,
  isNumber,
  removeBackticks,
  replaceTextWithEmojis,
} from '../utils';
import TestDescriptionCard from './TestDescriptionCard';

interface IPersonalPageDetailsBodyProps {
  testTrial: PersonalTestTrialPartialFragment;
}
export function PersonalPageDetailsBody({
  testTrial,
}: IPersonalPageDetailsBodyProps) {
  const {
    candidate,
    completed_tasks,
    completion_details,
    completion_score,
    submitted_at,
    test,
    test_feedback,
    total_tasks,
    passed,
    requester_id,
    user_test_stat,
  } = testTrial;

  const {
    claimAlertMessage,
    claimAlertSeverity,
    claimAlertOpen,
    claimBadge,
    claiming,
    handleClaimAlertClose,
  } = useClaimBadge({
    badgeId: test.badge?.badge_id || '',
    recipientEmail: candidate.email,
    testId: test.brokee_id,
    userId: candidate.id,
  });
  const selfStarted = requester_id === candidate.id;

  const badgeAwards = test.badge_awards;
  const awardAvailable = test.badge?.badge_id && badgeAwards.length > 0;
  const awardNeedsClaiming = test.badge?.badge_id && badgeAwards.length === 0;

  const learningResources = test.test_tech_services_resources;

  const [expanded, setExpanded] = useState<string | false>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const completion_details_object: ICompletionDetails =
    JSON.parse(completion_details);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleBadgeAction = () => {
    if (awardNeedsClaiming) {
      claimBadge();
    } else if (awardAvailable) {
      const publicUrl = badgeAwards[0].public_url;
      const recipient = badgeAwards[0].recipient_email;
      copyBadgePublicUrl(publicUrl, recipient, setSnackbarOpen);
    }
  };

  return (
    <>
      <Card elevation={3}>
        <CardHeader
          title={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h5">{testTrial.test.name}</Typography>
                <Chip
                  sx={{ mt: 0.5 }}
                  color={
                    test.test_difficulty?.level.toLowerCase() === 'hard'
                      ? 'error'
                      : test.test_difficulty?.level.toLowerCase() === 'easy'
                      ? 'success'
                      : 'warning'
                  }
                  size="small"
                  label={`${test.test_difficulty?.level.toLowerCase()}`}
                />
              </Box>
              {(awardNeedsClaiming || awardAvailable) && passed && (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', mt: 1, gap: 1 }}
                >
                  <Tooltip
                    arrow
                    title={
                      awardNeedsClaiming
                        ? 'Claim your badge for completing the assessment.'
                        : 'Copy the badge link to share your achievement.'
                    }
                  >
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      disabled={claiming}
                      startIcon={<EmojiEventsIcon fontSize="small" />}
                      onClick={handleBadgeAction}
                    >
                      {awardNeedsClaiming ? 'Claim Badge' : 'Copy Link'}
                      {claiming && (
                        <CircularProgress
                          size={20}
                          thickness={1}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </Button>
                  </Tooltip>
                </Box>
              )}
            </Box>
          }
        />
        <List>
          <ListItem divider={passed != null}>
            <ListItemText
              sx={{ display: 'flex' }}
              primary={
                <Typography variant={'subtitle2'} fontWeight={500} width="100%">
                  Status:
                </Typography>
              }
              secondary={
                <Typography
                  variant={'body2'}
                  width="100%"
                  sx={{
                    color: getTestStatusColor(passed),
                  }}
                >
                  {getTestStatus(passed)}
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          {isNumber(total_tasks) && isNumber(completed_tasks) && (
            <ListItem divider>
              <ListItemText
                sx={{ display: 'flex' }}
                primary={
                  <Typography
                    variant={'subtitle2'}
                    fontWeight={500}
                    width="100%"
                  >
                    Completed Tasks:
                  </Typography>
                }
                secondary={
                  <Typography variant={'body2'} width="100%">
                    {completed_tasks}/{total_tasks}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          )}
          {isNumber(total_tasks) && isNumber(completed_tasks) && (
            <ListItem divider>
              <ListItemText
                sx={{ display: 'flex' }}
                primary={
                  <Typography
                    variant={'subtitle2'}
                    fontWeight={500}
                    width="100%"
                  >
                    Completion Score:
                  </Typography>
                }
                secondary={
                  <Typography
                    variant={'body2'}
                    width="100%"
                    color={getCompletionPercentageColor(completion_score || 0)}
                  >
                    {completion_score}%
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          )}
          {passed === true && user_test_stat && test.test_stat && (
            <ListItem divider>
              <ListItemText
                sx={{ display: 'flex' }}
                primary={
                  <Typography
                    variant={'subtitle2'}
                    fontWeight={500}
                    width="50%"
                  >
                    Ranking:
                  </Typography>
                }
                secondary={
                  <Tooltip
                    title={`Your rank is ${user_test_stat.ranking_position} globally`}
                  >
                    <Box display="flex" alignItems="center">
                      <Chip
                        label={`${user_test_stat.ranking_position}`}
                        size="small"
                        color="default"
                        variant="outlined"
                        icon={<WorkspacePremiumIcon color="inherit" />}
                      ></Chip>
                      <Typography
                        variant={'body2'}
                        color={'black'}
                        paddingLeft={2}
                      >
                        {formatRegularRankingPositionDescription(
                          user_test_stat.ranking_position,
                          test.test_stat.total_passed
                        )}
                      </Typography>
                    </Box>
                  </Tooltip>
                }
              ></ListItemText>
            </ListItem>
          )}
          {testTrial.time_taken_seconds && (
            <ListItem divider>
              <ListItemText
                sx={{ display: 'flex' }}
                primary={
                  <Typography
                    variant={'subtitle2'}
                    fontWeight={500}
                    width="100%"
                  >
                    Time Taken:
                  </Typography>
                }
                secondary={
                  <Typography variant={'body2'} width="100%">
                    {getTestTimeTaken(testTrial.time_taken_seconds)}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          )}
          {submitted_at && (
            <ListItem>
              <ListItemText
                sx={{ display: 'flex' }}
                primary={
                  <Typography
                    variant={'subtitle2'}
                    fontWeight={500}
                    width="100%"
                  >
                    Submitted At:
                  </Typography>
                }
                secondary={
                  <Typography variant={'body2'} width="100%">
                    {submitted_at && getDateTime(submitted_at)}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          )}
        </List>
        {completion_details_object && (
          <Accordion
            disableGutters
            elevation={0}
            square
            expanded={expanded === 'panel1'}
            onChange={handleChange(expanded !== 'panel1' ? 'panel1' : '')}
          >
            <AccordionSummary
              expandIcon={<Icon icon={arrowIosForwardFill} />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography variant={'subtitle2'} fontWeight={500} width="100%">
                Automated Checks{' '}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {completion_details_object.completed_tasks &&
                completion_details_object.completed_tasks.map((task) => (
                  <Typography
                    variant={'body2'}
                    sx={{
                      fontSize: 15,
                      p: 1,
                    }}
                    width="100%"
                    key={task}
                  >
                    {removeBackticks(replaceTextWithEmojis(task))}
                  </Typography>
                ))}
              {completion_details_object?.failed_tasks &&
                completion_details_object?.failed_tasks.map((task) => (
                  <Typography
                    variant={'body2'}
                    sx={{
                      fontSize: 15,
                      p: 1,
                    }}
                    width="100%"
                    key={task}
                  >
                    {removeBackticks(replaceTextWithEmojis(task))}
                  </Typography>
                ))}
              {completion_details_object.timing_info && (
                <Typography
                  variant={'body2'}
                  width="100%"
                  key={completion_details_object.timing_info}
                  sx={{
                    fontSize: 15,
                    p: 1,
                  }}
                >
                  {removeBackticks(
                    replaceTextWithEmojis(completion_details_object.timing_info)
                  )}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          disableGutters
          elevation={0}
          square
          expanded={expanded === 'panel2'}
          onChange={handleChange(expanded !== 'panel2' ? 'panel2' : '')}
        >
          <AccordionSummary
            expandIcon={<Icon icon={arrowIosForwardFill} />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="subtitle2" fontWeight={500} width="100%">
              Description{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TestDescriptionCard
              title={test['name'] || ''}
              description={test['description_human'] || ''}
              descriptionLong={test['description_long'] || ''}
              image={test['arch_diagram_image'] || ''}
            />
          </AccordionDetails>
        </Accordion>
        {!passed && learningResources.length > 0 && (
          <Accordion
            disableGutters
            elevation={0}
            square
            expanded={expanded === 'panel3'}
            onChange={handleChange(expanded !== 'panel3' ? 'panel3' : '')}
          >
            <AccordionSummary
              expandIcon={<Icon icon={arrowIosForwardFill} />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography variant={'subtitle2'} fontWeight={500} width="100%">
                Learning Resources
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant={'body1'} fontWeight={500} width="100%" m={1}>
                Here are learning resources to help you better prepare for the
                assessment and understand the topics it covers.
              </Typography>
              <LearningResourcesView
                resources={learningResources as Test_Tech_Services_Resources[]}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {test_feedback && !selfStarted && (
          <Accordion
            disableGutters
            elevation={0}
            square
            expanded={expanded === 'panel4'}
            onChange={handleChange(expanded !== 'panel4' ? 'panel4' : '')}
          >
            <AccordionSummary
              expandIcon={<Icon icon={arrowIosForwardFill} />}
              aria-controls="panel4-content"
              id="panel6-header"
            >
              <Typography variant="subtitle2" fontWeight={500} width="100%">
                Your feedback
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant={'body2'}
                  sx={{
                    fontSize: 15,
                    display: 'inline',
                    m: 1,
                    fontWeight: 550,
                  }}
                >
                  Experience Rating:
                </Typography>
                <Rating
                  name="test-feedback"
                  value={test_feedback.rating}
                  readOnly
                  precision={1}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                <Box sx={{ ml: 2, fontSize: 15 }}>
                  {assessmentFeedbackLabels[test_feedback.rating]}
                </Box>
              </Box>
              <Typography
                variant={'body2'}
                sx={{ fontSize: 15, m: 1, fontWeight: 550 }}
                width="100%"
                key={test_feedback.comments}
              >
                Review Comment:{' '}
                <span style={{ fontWeight: 400 }}>
                  {test_feedback.comments
                    ? test_feedback.comments
                    : "You haven't commented on your experience"}
                </span>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
      </Card>
      <AssessmentAlert
        text={claimAlertMessage}
        open={claimAlertOpen}
        severity={claimAlertSeverity}
        onClose={handleClaimAlertClose}
      />
      <AssessmentAlert
        text={'Public link for your badge copied!'}
        open={snackbarOpen}
        severity={'success'}
        variant={'standard'}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}
