import { useAuth0 } from '@auth0/auth0-react';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useUpdateTrialStartDateMutation } from '../../@generated/facadeClient';
import { EInfraStatuses } from '../../constants/assessment';
import {
  EnvironmentInfrastructure,
  createEnvironmentInfra,
  retrieveEnvironmentInfra,
} from './Services/ProvisionerService';

interface InfrastructureContextType {
  createInfrastructure: (
    assessmentID: string,
    assessmentType: string,
    assessmentCategory: string,
    companyID: string,
    terminalSecret: string,
    testIssuesIDs: number[],
    userEmail: string | undefined,
    userHash: string
  ) => Promise<void>;
  fetchInfrastructure: (
    userHash: string,
    testId: string,
    accessToken: string
  ) => Promise<void>;
  infrastructure: EnvironmentInfrastructure | null;
  isInfrastructureCreated: boolean;
  isInfrastructureCreating: boolean;
  infrastructureCreationTime?: Date;
  initialFetchDone: boolean;
  initialFetchInfrastructure: (
    userHash: string,
    testId: string
  ) => Promise<void>;
  infraLoading: boolean;
  isInfraError: boolean;
  startPollingInfrastructure: (userHash: string, testId: string) => void;
}

const InfrastructureContext = createContext<InfrastructureContextType | null>(
  null
);

export const InfrastructureProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [infrastructure, setInfrastructure] =
    useState<EnvironmentInfrastructure | null>(null);
  const [infraLoading, setInfraLoading] = useState(false);
  const [isInfraError, setInfraError] = useState(false);
  const [isInfrastructureCreated, setIsInfrastructureCreated] =
    useState<boolean>(false);
  const [isInfrastructureCreating, setIsInfrastructureCreating] =
    useState<boolean>(false);

  const [infrastructureCreationTime, setInfrastructureCreationTime] = useState<
    Date | undefined
  >(undefined);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const isRequestOngoing = useRef<boolean>(false);

  const pollingInterval = useRef<NodeJS.Timeout | null>(null);
  const [updateTrialStartDate] = useUpdateTrialStartDateMutation();
  const trialId = Number(sessionStorage.getItem('trial_id'));

  const updateStartDate = useCallback(
    (trialId: number, startDate: Date) => {
      try {
        updateTrialStartDate({
          variables: {
            id: trialId,
            input: {
              started_at: startDate,
            },
          },
        });
        // eslint-disable-next-line
      } catch (error: any) {
        Bugsnag.notify(error as NotifiableError);
      }
    },
    [updateTrialStartDate]
  );

  const createInfrastructure = async (
    assessmentID: string,
    assessmentType: string,
    assessmentCategory: string,
    companyID: string,
    terminalSecret: string,
    testIssuesIDs: number[],
    userEmail: string | undefined,
    userHash: string
  ) => {
    try {
      setInfraLoading(true);
      setIsInfrastructureCreating(true);
      const accessToken = await getAccessTokenSilently();
      await createEnvironmentInfra(
        accessToken,
        assessmentID,
        assessmentType,
        assessmentCategory,
        companyID,
        terminalSecret,
        testIssuesIDs,
        userEmail,
        userHash
      );
      startPollingInfrastructure(userHash, assessmentID);
    } catch (error) {
      setInfraLoading(false);
      setIsInfrastructureCreating(false);
      setInfraError(true);
    }
  };

  const fetchInfrastructure = useCallback(
    async (userHash: string, testId: string, accessToken: string) => {
      if (isInfrastructureCreated || isRequestOngoing.current) return;
      try {
        isRequestOngoing.current = true;
        setInfraLoading(true);
        setInfraError(false);
        const response = await retrieveEnvironmentInfra(accessToken, {
          userHash,
          testId,
        });

        const { lastStatus, lastUpdate, updateInProgress } = response;

        if (lastStatus === EInfraStatuses.SUCCEEDED && !updateInProgress) {
          setIsInfrastructureCreated(true);
          setIsInfrastructureCreating(false);
          stopPollingInfrastructure();

          setInfrastructureCreationTime(new Date(lastUpdate));

          const storedCreationTime = sessionStorage.getItem(
            `creation-time-saved-${trialId}`
          );

          if (!storedCreationTime) {
            updateStartDate(trialId, new Date(lastUpdate));
            sessionStorage.setItem(`creation-time-saved-${trialId}`, 'true');
          }
          setInfrastructure(response);
          return;
        }
        if (lastStatus === EInfraStatuses.IN_PROGRESS && updateInProgress) {
          setIsInfrastructureCreating(true);
          setIsInfrastructureCreated(false);
        }

        if (lastStatus === EInfraStatuses.FAILED && !updateInProgress) {
          setInfraError(true);
          stopPollingInfrastructure();
          setIsInfrastructureCreating(false);
        }

        setIsInfrastructureCreated(false);
        setInfrastructure(response);
        // eslint-disable-next-line
      } catch (error: any) {
        isRequestOngoing.current = false;
        stopPollingInfrastructure();
        setIsInfrastructureCreated(false);
        setIsInfrastructureCreating(false);

        if (error.response && error.response.status === 404) {
          setInfraError(false);
          setInfraLoading(false);
        } else {
          setInfraError(true);
        }
      } finally {
        isRequestOngoing.current = false;
        setInitialFetchDone(true);
        setInfraLoading(false);
      }
    },
    [isInfrastructureCreated, trialId, updateStartDate]
  );

  const initialFetchInfrastructure = useCallback(
    async (userHash: string, testId: string) => {
      if (!initialFetchDone) {
        try {
          const accessToken = await getAccessTokenSilently();
          await fetchInfrastructure(userHash, testId, accessToken);
          setInitialFetchDone(true);
        } catch (error) {}
      }
    },
    [fetchInfrastructure, getAccessTokenSilently, initialFetchDone]
  );

  const startPollingInfrastructure = (userHash: string, testId: string) => {
    const poll = async () => {
      if (!isInfrastructureCreated && !isRequestOngoing.current) {
        const accessToken = await getAccessTokenSilently();
        await fetchInfrastructure(userHash, testId, accessToken);
      }
    };

    if (!pollingInterval.current && !isPolling) {
      pollingInterval.current = setInterval(poll, 7000);
      setIsPolling(true);
    }
  };

  const stopPollingInfrastructure = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;
      setIsPolling(false);
    }
  };

  // Memoize context value
  const value: InfrastructureContextType = {
    createInfrastructure,
    fetchInfrastructure,
    initialFetchInfrastructure,
    startPollingInfrastructure,
    isInfrastructureCreated,
    isInfrastructureCreating,
    isInfraError,
    infrastructureCreationTime,
    initialFetchDone,
    infrastructure,
    infraLoading,
  };

  return (
    <InfrastructureContext.Provider value={value}>
      {children}
    </InfrastructureContext.Provider>
  );
};

// Custom hook to use infrastructure context
export const useInfrastructure = () => {
  const context = useContext(InfrastructureContext);
  if (!context) {
    throw new Error(
      'useInfrastructure must be used within InfrastructureProvider'
    );
  }
  return context;
};
