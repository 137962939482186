import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import {
  getCompanyName,
  isCandidate,
  isCompany,
  isPersonal,
} from '../../utils/auth';

export default function AccountIdentity() {
  const { user } = useAuth0();
  const style = { mt: 0.5 };

  return (
    <>
      {isCompany(user) && !isPersonal(user) && (
        <Typography color="neutral.400" variant="body2" sx={style}>
          {getCompanyName(user)}
        </Typography>
      )}
      {isPersonal(user) && (
        <Typography color="neutral.400" variant="body2" sx={style}>
          Personal Account
        </Typography>
      )}
      {isCandidate(user) && (
        <Typography color="neutral.400" variant="body2" sx={style}>
          Candidate Account
        </Typography>
      )}
    </>
  );
}
