import { Routes, Route, Navigate } from 'react-router-dom';
import TestTrialPageDetails from './TestsHistoryDetails/TrialPageDetails';
import TestTrialsPageList from './TestsHistoryList/TestTrialsPageList';
export default function TrialsHistoryList() {
  return (
    <Routes>
      <Route path=":id/tests/:testId" element={<TestTrialPageDetails />} />
      <Route path="/" element={<TestTrialsPageList />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}
