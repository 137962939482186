import { Box, CardContent, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EnvironmentInfrastructure } from '../../Services/ProvisionerService';
interface ICloudFrameProps {
  assessmentId: number | undefined;
  categoryName: string;
  challengeOutputs: EnvironmentInfrastructure['outputs'] | undefined;
}
export default function CloudFrame({
  assessmentId,
  categoryName,
  challengeOutputs,
}: ICloudFrameProps) {
  const navigate = useNavigate();

  const handleNavigateToSummary = () => {
    navigate(`/assessments/${assessmentId}/environment/summary`);
  };

  return (
    <>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="div">
            Working Environment
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ textAlign: 'center', pb: '2rem', py: '2rem' }}
        >
          This assessment provides you with live {categoryName} infrastructure.
          For additional information, check the{' '}
          <span
            onClick={handleNavigateToSummary}
            style={{
              color: 'inherit',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Environment Summary
          </span>
        </Typography>
      </Box>
    </>
  );
}
