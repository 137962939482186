import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface IConfirmationDialogProps {
  title?: string;
  message: string;
  onClose(): void;
  onConfirm(): void;
}

export default function ConfirmationDialog({
  title,
  message,
  onClose,
  onConfirm,
}: IConfirmationDialogProps) {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      {title && <DialogTitle>{title}</DialogTitle>}
      {message && (
        <DialogContent>
          <DialogContentText variant="subtitle1">{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          id="setting-page-cancel-action"
          onClick={onClose}
          variant="text"
          color="error"
        >
          Cancel
        </Button>
        <Button
          id="setting-page-confirm-action"
          variant="contained"
          color="success"
          onClick={() => {
            onConfirm();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
