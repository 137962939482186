import { Grid, Paper, Typography } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { isPersonal } from '../../utils/auth';
import { generalQuestions, personalAccountQuestions } from './data';
import Question from './Question';

export default function FAQ() {
  const [checked, setChecked] = useState('');
  const { user } = useAuth0();
  const displayedQuestions = isPersonal(user)
    ? personalAccountQuestions
    : generalQuestions;

  function toggle(text: string) {
    if (text === checked) {
      setChecked('');
    } else {
      setChecked(text);
    }
  }
  return (
    <Grid container direction="row" justifyContent="center" xs={10}>
      <Paper
        variant="elevation"
        elevation={0}
        sx={{
          width: '100%',
          padding: 1,
          backgroundColor: 'transparent',
          border: 'none',
        }}
      >
        <Typography
          variant="h4"
          sx={{ textAlign: 'center', mb: '2.5rem', pt: '1rem' }}
        >
          Frequently Asked Questions
        </Typography>
        <Stack spacing={2}>
          {displayedQuestions.map((question) => (
            <Question
              key={question.text}
              {...question}
              toggle={toggle}
              opened={checked === question.text}
            />
          ))}
        </Stack>
      </Paper>
    </Grid>
  );
}
