import checkMarkSuccess from '@iconify/icons-twemoji/check-mark-button';
import crossMarkFailed from '@iconify/icons-twemoji/cross-mark';
import clock from '@iconify/icons-twemoji/four-oclock';
import notePad from '@iconify/icons-twemoji/spiral-notepad';
import { Icon } from '@iconify/react';
import { IconifyIcon } from '@iconify/types';
import { Box, SvgIcon, Typography } from '@mui/material';
import { getTestTimeTaken } from '../../CandidatesPage/utils';

export interface IEvaluationResultsCardProps {
  value: string | number | null | undefined;
  label: string;
  bgcolor: string;
  icon: IconifyIcon;
}

export const getEvaluationResultCardMapper = (
  time_taken_seconds: number,
  passed: boolean | null | undefined,
  completed_tasks: number,
  total_tasks: number
) => {
  return [
    {
      value: getTestTimeTaken(time_taken_seconds),
      bgcolor: 'rgb(255, 250, 235)',
      label: 'Time Taken',
      icon: clock,
    },
    {
      value: passed ? 'Yes' : 'No',
      bgcolor: passed ? 'rgb(240, 253, 249)' : 'rgb(255, 240, 240)',
      label: 'Passed',
      icon: passed ? checkMarkSuccess : crossMarkFailed,
    },
    {
      value: `${completed_tasks}/${total_tasks}`,
      bgcolor: 'rgb(230, 220, 250)',
      label: 'Tasks',
      icon: notePad,
    },
  ];
};
export default function EvaluationResultsCard({
  value,
  label,
  bgcolor,
  icon,
}: IEvaluationResultsCardProps) {
  return (
    <Box
      sx={{
        backgroundColor: bgcolor,
        borderRadius: '20px',
        p: '1.5rem',
        overflow: 'hidden',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <SvgIcon
        sx={{ width: '2.5rem', height: '2.5rem', marginBottom: '0.5rem' }}
      >
        <Icon icon={icon} />
      </SvgIcon>
      <Typography
        variant="body1"
        sx={{ color: 'text.secondary', lineHeight: 1.57 }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography variant="h6" sx={{ lineHeight: 1.2 }}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
}
