import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { formatISO } from 'date-fns';
import { useMutation } from 'react-query';
import { useUpdateTestTrialsByUserIdMutation } from '../../@generated/facadeClient';
import { TestTrialPartialFragment } from '../../@generated/types';

interface IDeleteDialogProps {
  testTrial: TestTrialPartialFragment;
  onClose(): void;
  onSuccess(message: string): void;
  onError(error: string): void;
}

// TODO: Should be delete user now
export default function DeleteDialog({
  testTrial,
  onClose,
  onSuccess,
  onError,
}: IDeleteDialogProps) {
  const [updateTestTrialByUserId] = useUpdateTestTrialsByUserIdMutation();

  const deleteCandidate = useMutation(
    async () => {
      // keep user and mark all user tests as deleted instead of one test
      await updateTestTrialByUserId({
        variables: {
          user_id: testTrial.user_id,
          set: { deleted_at: formatISO(new Date()) },
        },
      });
    },
    {
      onSuccess: () => {
        onSuccess(`User ${testTrial.candidate.email} successfully deleted!`);
      },
      onError: () => {
        onError(`Something went wrong. Please try again.`);
      },
    }
  );

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle>Delete {testTrial.candidate.email}?</DialogTitle>
      <DialogContent>
        <DialogContentText variant="subtitle1">
          This user, and all their tests will be deleted. This action cannot be
          undone. Do you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="user-cancel-deletion"
          onClick={onClose}
          variant="text"
          color="error"
        >
          Cancel
        </Button>
        <Button
          id="user-delete"
          variant="contained"
          color="error"
          onClick={() => {
            deleteCandidate.mutate();
          }}
        >
          Yes, Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
