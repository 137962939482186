import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { EstimatedExpenseCardProps } from './data';

export default function ExpenseResultCard({
  planName,
  priceItem,
}: EstimatedExpenseCardProps) {
  return (
    <Card
      sx={{
        '&:hover': {
          boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
        },
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        minWidth: '270px',
      }}
    >
      <CardHeader
        title={planName}
        subheader={`Total $${(priceItem.total_price / 100).toFixed(
          2
        )} ${priceItem.currency.toUpperCase()}`}
      />
      <Divider />
      <CardContent>
        {priceItem.breakdown.map((item, index) => (
          <Box key={index}>
            <Typography variant="subtitle2">{item.tier}</Typography>
            <Typography variant="body2">
              {item.quantity} x ${(item.unit_amount / 100).toFixed(2)} = $
              {(item.total_price / 100).toFixed(2)}
            </Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
}
