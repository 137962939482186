import { useNavigate } from 'react-router-dom';

export const styleIcon = {
  backgroundColor: 'primary.main',
  color: 'white',
  '&:hover': {
    borderColor: 'primary.dark',
    color: 'white',
    backgroundColor: 'primary.dark',
  },
  mb: '1rem',
  '& .MuiButton-startIcon': {
    color: 'primary.main',
  },
  padding: '12px',
  ml: 1.5,
};

export function useHandleLaunchTest() {
  const navigate = useNavigate();

  const handleLaunchTest = (testId: number) => {
    navigate(`/assessments/${testId}`);
  };

  return handleLaunchTest;
}
