import arrowForwardFill from '@iconify/icons-eva/arrow-forward-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useBilling } from '../../hooks/useBilling';
import CandidatesStatisticOverview from './CandidatesStatisticOverview';
import SavingsOverview from './SavingsOverview';
import { dashboardTiles } from './data';

export default function DashboardPage() {
  const navigate = useNavigate();
  const { canAccessProFeatures } = useBilling();

  return (
    <>
      <Typography
        variant="h3"
        display="block"
        gutterBottom
        sx={{ mt: '1rem', wordBreak: 'break-word' }}
      >
        Overview
      </Typography>
      <Grid container spacing={3} sx={{ mt: 0 }}>
        <Grid item xs={12}>
          <CandidatesStatisticOverview />
        </Grid>
        <Grid item xs={12} md={12}>
          {canAccessProFeatures() && <SavingsOverview />}
        </Grid>
        {dashboardTiles.map(
          ({
            sectionIcon,
            sectionName,
            title,
            description,
            navigateTo,
            buttonName,
          }) => (
            <Grid key={sectionName.replaceAll(/\s/g, '')} item xs={12} sm={6}>
              <Card sx={{ height: '100%' }} elevation={3}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SvgIcon
                      sx={{
                        color: 'primary.main',
                        fontSize: 'subtitle1',
                        mr: '0.5rem',
                      }}
                    >
                      <Icon icon={sectionIcon} />
                    </SvgIcon>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      display="inline"
                    >
                      {sectionName}
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ mt: '1rem' }}>
                    {title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="GrayText"
                    sx={{ mt: '0.5rem' }}
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </CardContent>
                {navigateTo && (
                  <>
                    <Divider />
                    <Button
                      id="candidate-invite-from-dashboard"
                      variant="text"
                      color="inherit"
                      endIcon={<Icon icon={arrowForwardFill} />}
                      sx={{ m: '0.5rem' }}
                      onClick={() => {
                        navigate(navigateTo);
                      }}
                    >
                      {buttonName}
                    </Button>
                  </>
                )}
              </Card>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
}
