import { Button, Card, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';

export default function EmptyResultsPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: 2,
      }}
    >
      <Card
        sx={{
          minWidth: 300,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Typography variant="h6" component="div" gutterBottom>
            Assessment Results Unavailable
          </Typography>
          <Box sx={{ pt: 2 }} />
          <Typography variant="body1" gutterBottom>
            The assessment attempt ID you entered does not have any results.
            Complete an attempt to see your results here.
          </Typography>
        </CardContent>
        <Box sx={{ pr: 3, pb: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            id="assessment-unavailable-results"
            variant="outlined"
            color="primary"
            onClick={() => (window.location.href = '/')}
          >
            Go Home
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
