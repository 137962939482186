import {
  CloudConsoleLoginUrls,
  EAssessmentCategoryType,
  EnvironmentServiceUrls,
} from '../../constants/assessment';
import { axiosInstance } from '../../utils/axios';

export const setUserHashInAssessmentDescription = (
  assessmentId: number,
  description: string,
  userHash: string
): string => {
  return description.replace(/IDENTIFIER/g, `${userHash}-${assessmentId}`);
};

export const getCloudConsoleLoginLink = (
  assessmentCategory: string,
  challengeOutputs: any
): string => {
  let urlTemplate = '';

  switch (assessmentCategory) {
    case EAssessmentCategoryType.AWS:
      urlTemplate = CloudConsoleLoginUrls.AWS;
      return urlTemplate.replace(
        '{{awsAccountId}}',
        challengeOutputs.awsAccountId
      );

    case EAssessmentCategoryType.Azure:
      urlTemplate = CloudConsoleLoginUrls.Azure;
      const loginHint = encodeURIComponent(challengeOutputs.Login);
      return urlTemplate.replace('{{loginHint}}', loginHint);

    case EAssessmentCategoryType.GCP:
      urlTemplate = CloudConsoleLoginUrls.GCP;
      const projectId =
        challengeOutputs.projectId || challengeOutputs.gcpProjectId;
      return urlTemplate.replace('{{projectId}}', projectId);
    case EAssessmentCategoryType.TERRAFORM:
      if (challengeOutputs && challengeOutputs.awsAccountId) {
        urlTemplate = CloudConsoleLoginUrls.AWS;
        return urlTemplate.replace(
          '{{awsAccountId}}',
          challengeOutputs.awsAccountId
        );
      } else if (challengeOutputs && challengeOutputs.ResourceGroupName) {
        urlTemplate = CloudConsoleLoginUrls.Azure;
        const loginHint = encodeURIComponent(challengeOutputs.Login);
        return urlTemplate.replace('{{loginHint}}', loginHint);
      } else if (
        challengeOutputs &&
        (challengeOutputs.gcpProjectId || challengeOutputs.projectId)
      ) {
        urlTemplate = CloudConsoleLoginUrls.GCP;
        const projectId =
          challengeOutputs.projectId || challengeOutputs.gcpProjectId;
        return urlTemplate.replace('{{projectId}}', projectId);
      } else {
        return '';
      }

    default:
      return '';
  }
};

export const getWorkingEnvServiceUrl = (
  assessmentID: number,
  environmentType: string,
  terminalSecret: string,
  userHash: string
): string => {
  const urlTemplate =
    EnvironmentServiceUrls[environmentType] ||
    EnvironmentServiceUrls['terminal']; // Default to terminal

  return urlTemplate
    .replace(/{{userHash}}/g, userHash)
    .replace(/{{assessmentID}}/g, String(assessmentID))
    .replace(/{{terminalSecret}}/g, terminalSecret);
};

export const splitCamelCaseToWords = (input: string): string => {
  const words = input
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(/(?=[A-Z][a-z])|\s+/);

  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);

  const result = words.join(' ');

  return result;
};

export const getPercentile = async (
  accessToken: string,
  {
    id,
    time,
  }: {
    id: number;
    time: number;
  }
) => {
  const { data } = await axiosInstance.get('/api/storage-commons/percentile', {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: { id, time },
  });

  return data;
};

export function appendPercentileSuffix(percentile: number): string {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const value = percentile % 100;

  if (value >= 11 && value <= 13) {
    return `${percentile}${suffixes[0]}`;
  }

  switch (value % 10) {
    case 1:
      return `${percentile}${suffixes[1]}`;
    case 2:
      return `${percentile}${suffixes[2]}`;
    case 3:
      return `${percentile}${suffixes[3]}`;
    default:
      return `${percentile}${suffixes[0]}`;
  }
}

export function percentileToPercentage(percentile: number): string {
  if (percentile < 0 || percentile > 100) {
    throw new Error('Percentile must be between 0 and 100');
  }

  if (percentile <= 49) {
    return `Bottom ${percentile}%`;
  } else if (percentile >= 50) {
    return `Top ${100 - percentile}%`;
  }

  return 'Something went wrong...';
}
