import { Box, Card, CardContent, Divider, Typography } from '@mui/material';

interface IAssessmentDiagramProps {
  imageUrl: string;
}

export default function AssessmentDiagram({
  imageUrl,
}: IAssessmentDiagramProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Full viewport height
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Card
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardContent>
          <Typography variant="h6" component="div">
            Assessment Diagram
          </Typography>
        </CardContent>
        <Divider></Divider>
        {imageUrl ? (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1rem',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            <Box
              component="img"
              src={imageUrl}
              alt="Challenge Diagram"
              sx={{
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              flex: 1,
              padding: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6">
              We will add a diagram soon for better experience 👀
            </Typography>
          </Box>
        )}
      </Card>
    </Box>
  );
}
