import { QueryHookOptions } from '@apollo/client';
import { ITestFilters } from '../interfaces';
export const getTestFilterQueryVariables = ({
  limit,
  page,
  search,
  company_id,
  filters: {
    tech_roles,
    tech_services,
    test_difficulties,
    test_types,
    test_categories,
    tech_service_categories,
    availability,
    sort_by,
    dynamic_issues,
  },
}: {
  limit: number;
  page: number;
  company_id: number;
  filters: ITestFilters;
  search: string;
}): QueryHookOptions => ({
  variables: {
    filter: {
      ...(availability === 'All'
        ? {}
        : {
            requires_payment: {
              _eq: availability === 'premium' ? true : false,
            },
          }),
      ...(dynamic_issues === 'All'
        ? {}
        : { dynamic_issues: { _eq: dynamic_issues } }),
      status: { _eq: 'ready' },
      _or: [
        {
          public: { _eq: true },
        },
        company_id
          ? {
              company_tests: {
                company_id: { _eq: company_id },
              },
            }
          : null,
      ].filter(Boolean),
      ...(!!search.length
        ? {
            _or: [
              { name: { _ilike: '%' + search + '%' } },
              { description_human: { _iregex: search } },
              { description_short: { _iregex: search } },
            ],
          }
        : {}),
      ...(tech_roles === 'All'
        ? {}
        : {
            test_tech_roles: { tech_role: { id: { _eq: Number(tech_roles) } } },
          }),
      ...(tech_service_categories === 'All' && tech_services === 'All'
        ? {}
        : {
            test_tech_services: {
              tech_service: {
                _and: [
                  ...(tech_service_categories === 'All'
                    ? []
                    : [
                        {
                          category_id: { _eq: Number(tech_service_categories) },
                        },
                      ]),
                  ...(tech_services === 'All'
                    ? []
                    : [{ id: { _eq: Number(tech_services) } }]),
                ],
              },
            },
          }),
      ...(test_categories === 'All'
        ? {}
        : { test_category: { id: { _eq: Number(test_categories) } } }),
      ...(test_types === 'All'
        ? {}
        : { test_type: { id: { _eq: Number(test_types) } } }),
      ...(test_difficulties === 'All'
        ? {}
        : {
            test_difficulty: { id: { _eq: Number(test_difficulties) } },
          }),
    },
    ...(sort_by === 'All'
      ? {}
      : {
          order_by: { name: sort_by },
        }),
    limit,
    offset: limit * page,
  },
  fetchPolicy: 'network-only',
  nextFetchPolicy: 'cache-and-network',
});

export const TestCatalogueDefaultFilters = {
  tech_roles: 'All',
  tech_services: 'All',
  test_difficulties: 'All',
  test_types: 'All',
  test_categories: 'All',
  availability: 'All',
  tech_service_categories: 'All',
  dynamic_issues: 'All',
  sort_by: 'asc',
  search: '',
};

export enum Difficulty_levels {
  EASY = 'Easy',
  MEDIUM = 'Medium',
  HARD = 'Hard',
}
export enum TLimit {
  TESTS_LIMIT_5 = '5',
  TESTS_LIMIT_10 = '10',
  TESTS_LIMIT_25 = '25',
}
export enum TPage {
  TESTS_PAGE_0 = '0',
  TESTS_PAGE_1 = '1',
  TESTS_PAGE_2 = '2',
}
export enum DefaultTValue {
  TESTS_TOTAL_0 = '0',
  TESTS_PAGE_0 = '0',
  TESTS_LIMIT_5 = '5',
}
export enum TOtherFilters {
  SELECT_ALL = 'All',
  AVAILABILITY = 'Availability',
  SORT_BY = 'Sort By',
  PREMIUM = 'premium',
  FREE = 'free',
  ASC = 'asc',
  DESC = 'desc',
  TNAME_ASC = 'Name (ASC)',
  TNAME_DESC = 'Name (DESC)',
  ALL_Plans = 'All Plans',
  PRO_PLAN = 'Paid Plan',
  FREE_TRIAL = 'Free Trial',
  STATIC_ISSUES = 'Static',
  DYNAMIC_ISSUES = 'Dynamic',
  ISSUES = 'Problem Set',
}

export function formatFilterKey(filterKey: string): string {
  return filterKey
    .replace(/(?:^|_)(\w)/g, (_, letter) => ` ${letter.toUpperCase()}`)
    .replace(
      /\b(\w)(\w*)\b/g,
      (_, firstLetter, restOfWord) =>
        `${firstLetter.toUpperCase()}${restOfWord}`
    );
}
