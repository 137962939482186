import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import infoIcon from '@iconify/icons-eva/info-outline';
import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import {
  useGetPrivateTestsQuery,
  useSubscribeTestTrialsSubscription,
} from '../../@generated/facadeClient';
import {
  TestTrialCandidatePartialFragment,
  TestTrialPartialFragment,
} from '../../@generated/types';
import { SubscriptionAlert } from '../../components/SubscriptionAlert';
import { VALID_LINKEDIN_REG_EXP } from '../../constants/regexp';
import { useBilling } from '../../hooks/useBilling';
import { getCompanyId, getUserMetaData } from '../../utils/auth';
import { calculateProblemsThreshold } from '../../utils/common';
import TestDescription from './TestDescription';
import { addAnotherTestForUser } from './utils';
interface IAddAnotherTestDialogProps {
  handleClose(): void;
  onSuccess(user: TestTrialCandidatePartialFragment): void;
  onError(error: string): void;
  testTrial: TestTrialPartialFragment;
}

const schema = yup
  .object({
    email: yup
      .string()
      .email('Please enter a valid email address.')
      .required('Please enter email address.'),
    linkedIn: yup
      .string()
      .matches(VALID_LINKEDIN_REG_EXP, {
        excludeEmptyString: true,
        message: 'Please enter a valid LinkedIn profile url.',
      })
      .nullable()
      .optional(),
    testId: yup.string().required('Please select test.'),
    issuesPerTest: yup.array().of(yup.object()),
  })
  .required();

export default function AddAnotherTestDialog({
  handleClose,
  onSuccess,
  onError,
  testTrial,
}: IAddAnotherTestDialogProps) {
  const { getAccessTokenSilently, user } = useAuth0();
  const { canAccessProFeatures, hasActiveFreeTrial } = useBilling();
  const companyId = getCompanyId(user);

  const { data: { tests } = { tests: [] } } = useGetPrivateTestsQuery({
    variables: { company_id: companyId },
  });

  const { data: takenTestTrialsData, loading: takenTestsLoading } =
    useSubscribeTestTrialsSubscription({
      variables: {
        filter: {
          deleted_at: { _is_null: true },
          candidate: { userhash: { _eq: testTrial.candidate.userhash } },
        },
      },
      fetchPolicy: 'network-only',
    });

  const takenTests = useMemo(
    () => takenTestTrialsData?.test_trials || [],
    [takenTestTrialsData]
  );

  const availableTests = useMemo(
    () =>
      tests.filter(({ brokee_id, requires_payment }) => {
        return (
          !takenTests.find((i) => brokee_id === i.test.brokee_id) &&
          (hasActiveFreeTrial() ? !requires_payment : true)
        );
      }),
    [hasActiveFreeTrial, takenTests, tests]
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    resetField,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: testTrial.candidate?.email ?? '',
      testId: '',
      linkedIn: testTrial?.candidate?.linkedin ?? null,
      issuesPerTest: undefined,
    },
  });

  const testId = useWatch({ control, name: 'testId' });

  useEffect(() => {
    resetField('issuesPerTest');
  }, [testId, resetField]);

  const selectedTest = useMemo(
    () =>
      availableTests?.find(({ brokee_id }) => brokee_id.toString() === testId),
    [availableTests, testId]
  );
  const { minThreshold, maxThreshold } = calculateProblemsThreshold(
    selectedTest?.issues_per_test || 0
  );

  useEffect(() => {
    reset({
      email: testTrial.candidate?.email ?? '',
      testId: '',
      linkedIn: testTrial?.candidate?.linkedin ?? null,
    });
  }, [
    availableTests,
    reset,
    testTrial.candidate?.email,
    testTrial.candidate?.linkedin,
  ]);

  const { isLoading, mutate } = useMutation(
    async ({ testId, issuesPerTest = [] }: FieldValues) => {
      const token = await getAccessTokenSilently();
      return await addAnotherTestForUser(token, {
        userhash: testTrial?.candidate?.userhash ?? '',
        test_id: testId,
        company_id: getUserMetaData(user)?.company_id,
        requester_id: getUserMetaData(user)?.local_user_id,
        issues_per_test: selectedTest?.issues_per_test ?? 0,
        available_issues: selectedTest?.test_issues.length ?? 0,
        issues_list: issuesPerTest.map((test: any) => test?.issue_id),
      });
    },
    {
      onSuccess: () => {
        testTrial?.candidate &&
          onSuccess(
            testTrial.candidate as unknown as TestTrialCandidatePartialFragment
          );
      },
      onError: (error: AxiosError) => {
        onError(
          (error?.response?.data as { message: string })?.message ??
            `Something went wrong. Please try again.`
        );
      },
    }
  );

  const onSubmit = async (data: FieldValues) => {
    if (
      selectedTest?.dynamic_issues &&
      data.issuesPerTest &&
      (data.issuesPerTest.length < minThreshold ||
        data.issuesPerTest.length > maxThreshold)
    ) {
      setError('issuesPerTest', {
        type: 'custom',
        message: `Select a number of problems between ${minThreshold} and ${maxThreshold} to meet the test requirements.`,
      });
      return;
    }
    mutate(data);
  };

  if (!takenTestsLoading && !availableTests.length) {
    onError(
      "This user can't be invited for more tests as they have been invited for all available tests."
    );
    handleClose();

    return null;
  }

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="lg" scroll="body">
      <DialogTitle>
        Add another test for {testTrial?.candidate?.email ?? ''}
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent>
          <Grid container spacing={2}>
            {!canAccessProFeatures() && (
              <Grid item xs={12}>
                <SubscriptionAlert text="Unlock advanced tests." />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={7}>
              <FormControl
                fullWidth
                required
                margin="normal"
                error={!!errors.email}
              >
                <TextField
                  {...register('email')}
                  id="email"
                  autoFocus
                  disabled
                  label="Email Address"
                  type="email"
                  variant="filled"
                  autoComplete="off"
                  aria-describedby="email-helper"
                />
                <FormHelperText id="email-helper">
                  {(errors.email?.message ?? '') as string}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal" error={!!errors.linkedIn}>
                <TextField
                  {...register('linkedIn')}
                  id="linkedIn"
                  disabled
                  label="LinkedIn Profile URL (Optional)"
                  variant="filled"
                  aria-describedby="linkedIn-helper"
                />
                <FormHelperText id="linkedIn-helper">
                  {(errors.linkedIn?.message ?? '') as string}
                </FormHelperText>
              </FormControl>
              {takenTestsLoading && <CircularProgress color="primary" />}
              {!takenTestsLoading && (
                <FormControl
                  fullWidth
                  required
                  margin="normal"
                  error={!!errors.testId}
                >
                  <TextField
                    {...register('testId')}
                    select
                    required
                    label="Test"
                    aria-describedby="test-helper"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {availableTests.map(
                      ({ brokee_id, name, dynamic_issues }) => (
                        <option key={`test${brokee_id}`} value={brokee_id}>
                          {name} {dynamic_issues && '🎲'}
                        </option>
                      )
                    )}
                  </TextField>
                  <FormHelperText id="test-helper">
                    {(errors.testId?.message ?? '') as string}
                  </FormHelperText>
                </FormControl>
              )}
              {selectedTest?.dynamic_issues && (
                <FormControl
                  fullWidth
                  required
                  margin="normal"
                  error={!!errors.issuesPerTest}
                >
                  <Controller
                    name="issuesPerTest"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        options={selectedTest?.test_issues ?? []}
                        disableCloseOnSelect
                        onChange={(_, value) => {
                          clearErrors('issuesPerTest');
                          return field.onChange(value);
                        }}
                        value={field.value}
                        getOptionLabel={(option) => option.title ?? ''}
                        size="medium"
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                justifyItems: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Box>
                                <Checkbox
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.title}
                              </Box>
                              <Box>
                                <Tooltip title={option.explanation ?? ''}>
                                  <Icon icon={infoIcon} />
                                </Tooltip>
                              </Box>
                            </Box>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Set of problems" />
                        )}
                        componentsProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, 10],
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  />
                  <FormHelperText id="issuesPerTest-helper" error>
                    {(errors.issuesPerTest?.message ?? '') as string}
                  </FormHelperText>
                  <Typography variant="caption">
                    {`By default, the test will generate a random set of ${selectedTest.issues_per_test} problems.
                    If you require specific issues to be included, you have the
                    option to select a custom set of problems.`}
                  </Typography>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              {selectedTest && <TestDescription test={selectedTest} />}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            id="cancel-adding-test-for-candidate"
            onClick={handleClose}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            id="add-another-test-for-candidate"
            type="submit"
            variant="contained"
            disabled={isLoading}
          >
            Add another test
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
