const numOfHoursPerWorkingDay = 8;
const numOfWorkingDayPerWeek = 5;
const numOfWeekPerMonth = 4;
const numOfWeeksPerYear = 52;
const numberOfWorkingHoursPerWeek =
  numOfHoursPerWorkingDay * numOfWorkingDayPerWeek;
const numberOfWorkingHoursPerMonth =
  numberOfWorkingHoursPerWeek * numOfWeekPerMonth;
const numberOfWorkingHoursPerYear =
  numberOfWorkingHoursPerWeek * numOfWeeksPerYear;

export function convertNumOfHoursToReadableFormat(numOfHours: number) {
  const { years, months, weeks, days, hours } = calcNumOfYMWDH(numOfHours);

  const yearsString = years > 0 ? `${years}y ` : '';
  const monthsString = months > 0 ? `${months}m ` : '';
  const weeksString = weeks > 0 ? `${weeks}w ` : '';
  const daysString = days > 0 ? `${days}d ` : '';
  const hoursString = hours > 0 ? `${hours}h` : '';

  return `${yearsString}${monthsString}${weeksString}${daysString}${hoursString}`;
}

function calcNumOfYMWDH(numOfHours: number) {
  const years = Math.floor(numOfHours / numberOfWorkingHoursPerYear);
  const months = Math.floor(
    (numOfHours - years * numberOfWorkingHoursPerYear) /
      numberOfWorkingHoursPerMonth
  );
  const weeks = Math.floor(
    (numOfHours -
      years * numberOfWorkingHoursPerYear -
      months * numberOfWorkingHoursPerMonth) /
      numberOfWorkingHoursPerWeek
  );
  const days = Math.floor(
    (numOfHours -
      years * numberOfWorkingHoursPerYear -
      months * numberOfWorkingHoursPerMonth -
      weeks * numberOfWorkingHoursPerWeek) /
      numOfHoursPerWorkingDay
  );
  const hours = Math.floor(
    numOfHours -
      years * numberOfWorkingHoursPerYear -
      months * numberOfWorkingHoursPerMonth -
      weeks * numberOfWorkingHoursPerWeek -
      days * numOfHoursPerWorkingDay
  );

  return { years, months, weeks, days, hours };
}

export function getFormatCaption(numOfHours: number) {
  const { months, weeks, days } = calcNumOfYMWDH(numOfHours);
  const caption =
    (months > 0 ? 'm=4w ' : '') +
    (weeks > 0 ? 'w=5d ' : '') +
    (days > 0 ? 'd=8h' : '');

  return caption;
}
