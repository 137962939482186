import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  Link as MuiLink,
  Paper,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as LogoComponent } from '../../assets/logos/brokee_short_ua.svg';
import { privacyPolicyUrl, termsOfUseUrl } from '../../constants/links';
import { VALID_LINKEDIN_REG_EXP } from '../../constants/regexp';
import { createCandidateForAssessment } from './utils';

const InvitationLink = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [invalidLinkedIn, setInvalidLinkedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSteps, setShowSteps] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const steps = [
    {
      title: 'Register:',
      description:
        'Join the assessment using your existing login credentials or create a new account.',
    },
    {
      title: 'Login:',
      description: 'Access your account to explore the assessment.',
    },
    {
      title: 'Read Description:',
      description: 'Understand the assessment details and requirements.',
    },
    {
      title: 'Take Assessment:',
      description: 'Demonstrate your proficiency.',
    },
    {
      title: 'Celebrate:',
      description:
        'Celebrate your achievements and get ready for the next steps.',
    },
  ];

  const submitForm = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (name && email && !invalidLinkedIn) {
      const userMetadata = {
        name: name,
        linkedIn: invalidLinkedIn,
        assessment_link_id: params.id,
      };

      try {
        const lowercasedEmail = email.toLocaleLowerCase();
        const response = await createCandidateForAssessment({
          email: lowercasedEmail,
          password,
          userMetadata,
        });

        if (response.test_id) {
          navigate(`/assessments/${response.test_id}`);
        }
        setLoading(false);
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          if (error.response.data.test_id) {
            navigate(`/assessments/${error.response.data.test_id}`);
          } else {
            showPopup(
              error.response.data.message ||
                'An error occurred. Please try again.'
            );
          }
        } else {
          showPopup('Something went wrong. Please try again later.');
        }
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const showPopup = (message: string) => {
    setPopupMessage(message);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      setPopupMessage('');
    }, 7000);
  };

  const toggleAllSteps = () => {
    setShowSteps(!showSteps);
  };

  const validateLinkedIn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value.trim();
    setLinkedIn(url);
    if (url === '') {
      setInvalidLinkedIn(false);
    } else {
      setInvalidLinkedIn(!VALID_LINKEDIN_REG_EXP.test(url));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        bgcolor: 'neutral.900',
        color: 'neutral.200',
        width: '100%',
        p: isSmallScreen ? 2 : 4,
        overflow: 'auto',
      }}
    >
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid item xs={12} md={7.5}>
          <Box
            sx={{
              p: isSmallScreen ? 2 : 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: isSmallScreen ? 3 : 0,
            }}
          >
            <Typography
              variant="h3"
              fontWeight="bold"
              textAlign="center"
              gutterBottom
              sx={{ fontSize: isSmallScreen ? '1.5rem' : '2.5rem' }}
            >
              Welcome to Brokee!
            </Typography>
            <Box textAlign="left" mt={2} width="100%">
              <Typography
                variant="body1"
                onClick={toggleAllSteps}
                sx={{
                  cursor: 'pointer',
                  mb: 2,
                  fontSize: isSmallScreen ? 16 : 21,
                  textAlign: 'center',
                }}
              >
                This is your gateway to showcase your skills! Follow these
                simple steps to embark on your journey.
              </Typography>
              <Paper
                elevation={0}
                sx={{
                  py: 1,
                  px: isSmallScreen ? 2 : 5,
                  bgcolor: 'neutral.900',
                  color: 'white',
                }}
              >
                <List>
                  {steps.map((step, index) => (
                    <ListItem
                      key={index}
                      sx={{ display: 'flex', alignItems: 'flex-start' }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            component="div"
                            fontWeight="bold"
                          >
                            {step.title}
                          </Typography>
                        }
                        secondary={step.description}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
              <Typography
                variant="h6"
                fontWeight="bold"
                mt={2}
                textAlign={'center'}
              >
                We're excited to witness your skills! Click 'Register Now' to
                kickstart your journey.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4.5}>
          <Paper
            elevation={4}
            sx={{
              borderRadius: 4,
              boxShadow: 3,
              width: '100%',
              p: isSmallScreen ? 2 : 4,
            }}
          >
            <Box display="flex" justifyContent="center" width={'100%'}>
              <SvgIcon
                sx={{
                  width: isSmallScreen ? 75 : 125,
                  height: isSmallScreen ? 75 : 125,
                  p: 1,
                  m: 1,
                }}
                inheritViewBox
              >
                <LogoComponent />
              </SvgIcon>
            </Box>
            <Typography
              variant="h6"
              textAlign="center"
              color={'black'}
              gutterBottom
              // sx={{ fontSize: isSmallScreen ? '1.25rem' : '1.5rem' }}
            >
              Register For The Assessment
            </Typography>
            <Box display="flex" justifyContent="center" width={'100%'} p={3}>
              <form onSubmit={submitForm} style={{ width: '100%' }}>
                <FormControl fullWidth margin="normal">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    textAlign="left"
                    gutterBottom
                    sx={{ fontSize: isSmallScreen ? 14 : 16 }}
                  >
                    Name
                  </Typography>
                  <TextField
                    id="name"
                    label="Full Name"
                    value={name}
                    variant="standard"
                    onChange={(e) => setName(e.target.value)}
                    required
                    sx={{ borderRadius: '25px' }}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    textAlign="left"
                    gutterBottom
                    sx={{ fontSize: isSmallScreen ? 14 : 16 }}
                  >
                    Email
                  </Typography>
                  <TextField
                    id="email"
                    label="hello@example.com"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    variant="standard"
                    sx={{ borderRadius: '25px' }}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    textAlign="left"
                    gutterBottom
                    sx={{ fontSize: isSmallScreen ? 14 : 16 }}
                  >
                    Password
                  </Typography>
                  <TextField
                    id="password"
                    label="Strong Password"
                    type="password"
                    variant="standard"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    inputProps={{ minLength: 8 }}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    textAlign="left"
                    gutterBottom
                    sx={{ fontSize: isSmallScreen ? 14 : 16 }}
                  >
                    Linkedin
                  </Typography>
                  <TextField
                    id="linkedIn"
                    label="https://www.linkedin.com/in/hello"
                    value={linkedIn}
                    onChange={validateLinkedIn}
                    variant="standard"
                    sx={{ borderRadius: '25px' }}
                  />
                  {invalidLinkedIn && (
                    <Typography color="error" textAlign="center">
                      Please enter a valid LinkedIn URL
                    </Typography>
                  )}
                </FormControl>
                <Typography
                  variant="body2"
                  fontSize={isSmallScreen ? 12 : 15}
                  color={'black'}
                  textAlign="center"
                  mt={1}
                  mb={2}
                >
                  Use this email and password for future sign-in
                </Typography>
                <Box mt={4} textAlign="center">
                  <Button
                    id="invitation-link-registration"
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    sx={{ borderRadius: 3, py: 1.5 }}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Register Now'}
                  </Button>
                </Box>
                <Box mt={3} textAlign="center">
                  <Typography
                    variant="body2"
                    fontSize={isSmallScreen ? 12 : 15}
                    color="black"
                    textAlign="center"
                    mt={1}
                    mb={2}
                    display="inline"
                  >
                    Already registered?
                  </Typography>
                  <MuiLink
                    href="/"
                    target="_self"
                    display="inline"
                    sx={{
                      mx: 1,
                      textDecoration: 'none',
                      color: 'primary.main',
                      '&:hover': {
                        textDecoration: 'underline',
                        color: 'primary.dark',
                      },
                    }}
                  >
                    Log in
                  </MuiLink>
                </Box>
                <Box mt={4} textAlign="center">
                  <MuiLink
                    href={termsOfUseUrl}
                    target="_blank"
                    sx={{
                      mx: 1,
                      textDecoration: 'none',
                      color: 'inherit',
                      '&:hover': {
                        textDecoration: 'underline',
                        color: 'inherit',
                      },
                    }}
                  >
                    Terms
                  </MuiLink>
                  {' • '}
                  <MuiLink
                    href={privacyPolicyUrl}
                    target="_blank"
                    sx={{
                      mx: 1,
                      textDecoration: 'none',
                      color: 'inherit',
                      '&:hover': {
                        textDecoration: 'underline',
                        color: 'inherit',
                      },
                    }}
                  >
                    Services
                  </MuiLink>
                </Box>
              </form>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {showMessage && (
        <Alert
          severity="error"
          variant="filled"
          sx={{
            position: 'fixed',
            top: '1rem',
            zIndex: 100,
            boxShadow: 3,
          }}
        >
          <AlertTitle>Oh no!</AlertTitle>
          <Typography>{popupMessage}</Typography>
        </Alert>
      )}
    </Box>
  );
};

export default InvitationLink;
