import { Theme, paperClasses } from '@mui/material';

export default function Paper(theme: Theme) {
  return {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 20,
          [`&.${paperClasses.elevation1}`]: {
            boxShadow:
              '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)',
          },
        },
      },
    },
  };
}
