import { useAuth0 } from '@auth0/auth0-react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Slider,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { estimateCostUsage } from '../../company/SettingsPage/utils';
import {
  CostEstimatorProps,
  EstimatedExpenseResult,
  generateCostSliderMarks,
} from './data';
import ExpenseResultCard from './ExpenseResultCard';
export default function PricingCalculator({
  minTests = 1,
  maxTests = 150,
  testStep = 25,
}: CostEstimatorProps) {
  const { getAccessTokenSilently } = useAuth0();
  const initialLoad = useRef(true);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const [quantity, setQuantity] = useState<number>(1);
  const [results, setResults] = useState<EstimatedExpenseResult>({});
  const [loading, setLoading] = useState<boolean>(false);

  const calculateCost = useCallback(
    async (quantity: number) => {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const estimatedPrices = await estimateCostUsage(token, { quantity });
      setResults(estimatedPrices);
      setLoading(false);
    },
    [getAccessTokenSilently]
  );

  useEffect(() => {
    if (initialLoad.current) {
      calculateCost(quantity);
      initialLoad.current = false;
    }
  }, [calculateCost, quantity]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCalculateCost = useCallback(
    debounce((value: number) => {
      calculateCost(value);
    }, 400),
    [calculateCost]
  );

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setQuantity(newValue as number);
    debouncedCalculateCost(newValue as number);
  };
  const marks = generateCostSliderMarks(minTests, maxTests, testStep);

  const sortedPlans = Object.keys(results)
    .map((planName) => ({
      planName,
      items: results[planName][0],
    }))
    .sort((a, b) => a.items.order - b.items.order);

  return (
    <>
      <Card>
        <CardContent>
          <Alert
            severity="success"
            icon={<MonetizationOnIcon fontSize="inherit" />}
            style={{
              overflow: 'hidden',
              width: '100%',
              boxSizing: 'border-box',
              padding: '0.75rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <AlertTitle>Estimate your future costs</AlertTitle>
              <Typography
                variant="subtitle2"
                style={{ wordBreak: 'break-word' }}
              >
                Calculate your expected expenses and compare different plans to
                find the best fit for your budget.
                <br /> For regular or heavy usage, the subscription{' '}
                <strong>Growth</strong> plan offers greater value and savings
                with tiered pricing. If your needs are occasional, the
                <strong> On-Demand</strong> pay-as-you-go model provides
                flexibility and control over your spending.
              </Typography>
            </Box>
          </Alert>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              px: '1.5rem',
              // padding: '0.8rem',
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                paddingTop: '1rem',
                marginBottom: '1.4rem',
                wordBreak: 'break-word',
              }}
            >
              Adjust Estimated Cost Based on Credits
            </Typography>
            <Slider
              sx={{ width: '100%' }}
              size="small"
              value={quantity}
              min={minTests}
              max={maxTests}
              marks={!isMobile && marks}
              aria-label="Small"
              valueLabelDisplay="auto"
              onChange={handleSliderChange}
            />
            <Box
              sx={{
                width: '100%',
                position: 'relative',
                mt: 1.5,
              }}
            >
              {loading && (
                <Box
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LinearProgress sx={{ width: '50%', height: '2px' }} />
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '2rem',
              padding: '0.5rem',
            }}
          >
            {sortedPlans.map(({ planName, items }) => (
              <ExpenseResultCard
                key={planName}
                planName={planName}
                priceItem={items}
              />
            ))}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
