import { useAuth0 } from '@auth0/auth0-react';
import editOutline from '@iconify/icons-eva/edit-outline';
import { Icon } from '@iconify/react';
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { useMutation } from 'react-query';
import {
  useGetActiveSubscriptionByCompanyIdQuery,
  useGetCompanyDetailsByCompanyIdQuery,
} from '../../../@generated/facadeClient';
import { getUserMetaData } from '../../../utils/auth';
import { startBillingPortalSession } from '../utils';
import { isPersonal } from '../../../utils/auth';

export default function BillingDetails() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');

  const { data: companyData, loading } = useGetCompanyDetailsByCompanyIdQuery({
    variables: {
      company_id: getUserMetaData(user)?.company_id,
    },
  });

  const { data: activeSubscription } = useGetActiveSubscriptionByCompanyIdQuery(
    {
      variables: {
        company_id: getUserMetaData(user)?.company_id,
      },
    }
  );

  const { mutate } = useMutation(
    async ({ customer }: { customer: string }) => {
      const token = await getAccessTokenSilently();
      return await startBillingPortalSession(token, { customer: customer });
    },
    {
      onSuccess(data) {
        window.open(data.portal_session_url, '_blank');
      },
      onError() {
        handleError(
          'Something goes wrong, please contact us us via chat or at info@brokee.io'
        );
      },
    }
  );

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleError = (error: string) => {
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(error);
  };

  function mangeBilling() {
    mutate({
      customer: companyData?.companies?.[0]?.stripe_customer_id || '',
    });
  }

  return (
    <Box>
      <Card>
        <CardHeader title={'Billing Details'} />
        <CardContent>
          {!loading && (
            <Box
              sx={{
                border: '1px solid rgb(242, 244, 247)',
                borderRadius: '0.5rem',
              }}
            >
              <List sx={{ p: 0 }}>
                {companyData?.companies?.[0]?.name && (
                  <ListItem divider>
                    <ListItemText
                      sx={{ display: 'flex', justifyItems: 'flex-start' }}
                      primary={
                        <Typography
                          variant={'subtitle2'}
                          fontWeight={500}
                          sx={{ minWidth: '180px' }}
                        >
                          {isPersonal(user) ? 'Account Name' : 'Company Name'}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant={'body2'}
                          color="text.secondary"
                          width="100%"
                        >
                          {companyData?.companies?.[0]?.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
                {companyData?.companies?.[0]?.stripe_billing_email && (
                  <ListItem divider>
                    <ListItemText
                      sx={{ display: 'flex', justifyItems: 'flex-start' }}
                      primary={
                        <Typography
                          variant={'subtitle2'}
                          fontWeight={500}
                          sx={{ minWidth: '180px' }}
                        >
                          Billing Email
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant={'body2'}
                          color="text.secondary"
                          width="100%"
                        >
                          {companyData?.companies?.[0]?.stripe_billing_email}
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
                {!!activeSubscription?.subscriptions?.[0] && (
                  <ListItem>
                    <ListItemText
                      sx={{ display: 'flex', justifyItems: 'flex-start' }}
                      primary={
                        <Typography
                          variant={'subtitle2'}
                          fontWeight={500}
                          sx={{ minWidth: '180px' }}
                        >
                          Next Billing Date
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant={'body2'}
                          color="text.secondary"
                          width="100%"
                        >
                          {format(
                            parseISO(
                              activeSubscription.subscriptions[0]
                                .current_period_end
                            ),
                            'PP'
                          )}
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </Box>
          )}
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            id="billing-details-edit"
            startIcon={<Icon icon={editOutline} />}
            variant="contained"
            onClick={mangeBilling}
          >
            Edit
          </Button>
        </CardActions>
      </Card>
      {showSnackbar && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            variant="filled"
            onClose={handleSnackbarClose}
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}
