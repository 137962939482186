import {
  useSubscribeTestTrialsSubscription,
  useGetTestTrialsByAssessmentLinkIdQuery,
} from '../../../@generated/facadeClient';
import { getTestTrialsQueryVariables } from '../utils'; // Import or define the correct type for the return value of the queries

function useConditionalTestTrialsQuery(
  assessmentLinkId: string | undefined,
  generalVariables: ReturnType<typeof getTestTrialsQueryVariables>,
  assessmentLinkQueryVariables: ReturnType<typeof getTestTrialsQueryVariables>
) {
  if (assessmentLinkId) {
    return {
      query: useGetTestTrialsByAssessmentLinkIdQuery,
      variables: assessmentLinkQueryVariables,
    };
  } else {
    return {
      query: useSubscribeTestTrialsSubscription,
      variables: generalVariables,
    };
  }
}

export default useConditionalTestTrialsQuery;
