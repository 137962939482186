import { TableSortLabel } from '@mui/material';
import { ESortByKeys, ISortCriteria } from './data';

interface ISortableTableHeaderProps {
  label: string;
  sortKey: ESortByKeys;
  currentSortCriteria: ISortCriteria[];
  onSortChange: (key: ESortByKeys) => void;
}
const SortableTableHeader: React.FC<ISortableTableHeaderProps> = ({
  label,
  sortKey,
  currentSortCriteria,
  onSortChange,
}) => {
  const currentCriteria = currentSortCriteria.find(
    ({ key }) => key === sortKey
  );

  const handleClick = () => {
    onSortChange(sortKey);
  };

  return (
    <TableSortLabel
      active={true}
      direction={currentCriteria ? currentCriteria.order : 'asc'}
      onClick={handleClick}
    >
      {label}
    </TableSortLabel>
  );
};
export default SortableTableHeader;
