import { ReactElement } from 'react';
import { Popover, Theme, PopoverProps, SxProps } from '@mui/material';

interface MenuPopoverProps extends PopoverProps {
  children: ReactElement;
}

export default function MenuPopover({
  children,
  sx,
  ...other
}: MenuPopoverProps) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          mt: '0.25rem',
          ml: '0.25rem',
          overflow: 'inherit',
          boxShadow: (theme: Theme) => theme.shadows[8],
          border: (theme: Theme) => `solid 1px ${theme.palette.divider}`,
          width: 200,
          ...sx,
        } as SxProps<Theme>,
      }}
      {...other}
    >
      {children}
    </Popover>
  );
}
