import { Routes, Route, Navigate } from 'react-router-dom';
import { CandidatesPageList } from './CandidatesPageList';
import { CandidatesPageDetails } from './CandidatesPageDetails';

export default function CandidatesPage() {
  return (
    <Routes>
      <Route path="/:assessment_link_id?" element={<CandidatesPageList />} />
      <Route path=":id/tests/:testId" element={<CandidatesPageDetails />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}
