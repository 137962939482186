import { useAuth0 } from '@auth0/auth0-react';
import icon1stPlaceMedal from '@iconify/icons-twemoji/1st-place-medal';
import brokenHeart from '@iconify/icons-twemoji/broken-heart';
import incomingEnvelope from '@iconify/icons-twemoji/incoming-envelope';
import { Card, CardContent, Grid, Typography } from '@mui/material';

import { useGetCandidatesStatisticsQuery } from '../../@generated/facadeClient';
import { getUserMetaData } from '../../utils/auth';
import CandidatesCountCard from './CandidatesCountCard';

export default function CandidatesStatisticOverview() {
  const { user } = useAuth0();
  const userMetaData = getUserMetaData(user);

  const { data: candidatesStatistics, loading } =
    useGetCandidatesStatisticsQuery({
      variables: {
        company_id: userMetaData.company_id,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    });

  const candidatesStatisticsCards = [
    {
      count: candidatesStatistics?.total_invented.aggregate?.count || 0,
      bgcolor: 'rgb(255, 250, 235)',
      label: 'Invited',
      icon: incomingEnvelope,
    },
    {
      count: candidatesStatistics?.total_passed.aggregate?.count || 0,
      bgcolor: 'rgb(240, 253, 249)',
      label: 'Success',
      icon: icon1stPlaceMedal,
    },
    {
      count: candidatesStatistics?.total_failed.aggregate?.count || 0,
      bgcolor: 'rgb(255, 240, 240)',
      label: 'Failed',
      icon: brokenHeart,
    },
  ];

  return (
    <Card elevation={3}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" display="block" sx={{ mb: '1rem' }}>
              Insights Into Your Candidates
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            {candidatesStatisticsCards.map((candidateStatistic) => {
              return (
                <Grid key={candidateStatistic.label} item xs={12} sm={4}>
                  <CandidatesCountCard
                    count={candidateStatistic.count}
                    bgcolor={candidateStatistic.bgcolor}
                    label={candidateStatistic.label}
                    icon={candidateStatistic.icon}
                    loading={loading}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
