import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';

export default function Question({
  text,
  answer,
  opened,
  toggle,
}: {
  text: string;
  answer: string;
  opened: boolean;
  toggle: (text: string) => void;
}) {
  return (
    <Card
      variant="outlined"
      sx={{
        mb: 1.5,
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: 'inherit',
      }}
    >
      <CardActions
        disableSpacing
        onClick={() => toggle(text)}
        sx={{ cursor: 'pointer', p: 1.75 }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ width: '100%' }}
        >
          <Typography variant="h6" sx={{ flex: 1 }}>
            {text}
          </Typography>
          <IconButton>
            <Icon
              icon={arrowIosForwardFill}
              width={24}
              height={24}
              rotate={opened ? 1 : undefined}
            />
          </IconButton>
        </Stack>
      </CardActions>
      <Collapse in={opened}>
        <CardContent sx={{ p: 1.5 }}>
          <Typography variant="body1">{answer}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
