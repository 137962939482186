import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './common/App';
import ErrorBoundary from './utils/errorBoundary';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

if (!ErrorBoundary) {
  throw new Error('Bugsnag ErrorBoundary failed to initialize');
}

createRoot(document.getElementById('root') as Element).render(
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>
);
