import { Grid } from '@mui/material';
import BillingDetails from './BillingDetails';
import Transactions from './TransactionsList';
import UpcomingInvoice from './UpcomingInvoice';

export default function Billings() {
  return (
    <Grid container spacing={4} sx={{ pt: '2rem' }}>
      <Grid item xs={12}>
        <BillingDetails />
      </Grid>
      <Grid item xs={12}>
        <UpcomingInvoice />
      </Grid>
      <Grid item xs={12}>
        <Transactions />
      </Grid>
    </Grid>
  );
}
