import { useAuth0 } from '@auth0/auth0-react';
import { Theme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { isCandidate } from '../../utils/auth';
import CandidateLayout from './CandidateLayout';
import CompanyLayout from './CompanyLayout';
import { getMenuItems } from './data';

export default function PrivateLayout() {
  const { user } = useAuth0();
  const menuItems = getMenuItems(user);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (lgUp) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [lgUp]);

  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  return isCandidate(user) ? (
    <CandidateLayout
      isSidebarOpen={isSidebarOpen}
      menuItems={menuItems}
      handleSidebarOpen={handleSidebarOpen}
      handleSidebarClose={handleSidebarClose}
    />
  ) : (
    <CompanyLayout
      isSidebarOpen={isSidebarOpen}
      menuItems={menuItems}
      handleSidebarOpen={handleSidebarOpen}
      handleSidebarClose={handleSidebarClose}
    />
  );
}
