import checkmarkOutline from '@iconify/icons-eva/checkmark-outline';
import highVoltage from '@iconify/icons-twemoji/high-voltage';
import { Icon } from '@iconify/react';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { Stack, useTheme } from '@mui/system';
import { PricingPlansPartialFragment } from '../../@generated/types';

const pricingIntervalMap = {
  monthly: 'month',
  on_demand: 'credit',
};

interface IPricingCardProps extends PricingPlansPartialFragment {
  isActivePlan?: boolean;
  isCancelButton?: boolean;
  isDisabledButton?: boolean;
  onActionCall?: (planName: string) => void;
}

export function PricingCard({
  name,
  description,
  price,
  billing_interval,
  feature_list,
  is_preferred,
  call_to_action,
  isCancelButton,
  isDisabledButton,
  onActionCall,
  isActivePlan,
}: IPricingCardProps) {
  const theme = useTheme();
  const interval =
    billing_interval?.interval as keyof typeof pricingIntervalMap;

  return (
    <Paper
      sx={{
        maxWidth: '460px',
        ml: 'auto',
        mr: 'auto',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        height: '100%',
        outline: isActivePlan ? '4px solid' + theme.palette.success.main : '',
      }}
      elevation={isActivePlan ? 2 : 1}
    >
      <Box
        sx={{
          padding: '1.5rem',
        }}
      >
        <Box
          sx={{
            pb: '.4rem',
          }}
        >
          {is_preferred ? (
            <Icon icon={highVoltage} width="40" height="40" />
          ) : (
            <Box
              sx={{
                filter: 'grayscale(1)',
              }}
            >
              <Icon icon={highVoltage} width="40" height="40" />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h4">${price}</Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ alignSelf: 'flex-end', ml: '.5rem' }}
          >
            /{pricingIntervalMap[interval] || 'month'}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            mt: '1rem',
          }}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          color={'text.secondary'}
          sx={{
            mt: '1rem',
          }}
        >
          {description}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: '24px',
        }}
      >
        <Stack
          spacing={2}
          sx={{
            flexGrow: 1,
          }}
        >
          {feature_list?.map((feature) => (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              key={feature}
            >
              <Box width={24} height={24}>
                <Icon
                  icon={checkmarkOutline}
                  color={theme.palette.success.main}
                  width="24"
                  height="24"
                />
              </Box>
              <Typography variant="subtitle2">{feature}</Typography>
            </Stack>
          ))}
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '3rem',
          }}
        >
          {isDisabledButton ? (
            <Button
              id={`subscription-page-${name}`}
              variant={'outlined'}
              size="large"
              color="error"
              disabled
              sx={{ width: '100%' }}
              onClick={() => {
                onActionCall && onActionCall(name);
              }}
            >
              Active Plan
            </Button>
          ) : isCancelButton ? (
            <Button
              id={`subscription-page-${name}-cancel`}
              variant={'outlined'}
              size="large"
              color="error"
              sx={{ width: '100%' }}
              onClick={() => {
                onActionCall && onActionCall(name);
              }}
            >
              {call_to_action}
            </Button>
          ) : (
            <Button
              id={`subscription-page-${name}-purchase`}
              variant={is_preferred ? 'contained' : 'outlined'}
              size="large"
              color="primary"
              sx={{ width: '100%' }}
              onClick={() => {
                onActionCall && onActionCall(name);
              }}
            >
              {call_to_action}
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
