import { Box, Container, Theme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import PrivateFooter from '../../components/Footer/PrivateFooter';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar';
import { ILayoutProps } from './data';

export default function CandidateLayout({
  isSidebarOpen,
  menuItems,
  handleSidebarOpen,
  handleSidebarClose,
}: ILayoutProps) {
  return (
    <>
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          flex: '1 1 auto',
          maxWidth: '100%',
          paddingTop: '4rem',
          [theme.breakpoints.up('lg')]: {
            paddingLeft: '17.5rem',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Container sx={{ py: '2rem', pb: '1rem', minHeight: '100vh' }}>
            <Outlet />
          </Container>
          <PrivateFooter />
        </Box>
      </Box>
      <Navbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={isSidebarOpen}
        menuItems={menuItems}
      />
    </>
  );
}
