import { User, useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { EAccountTypes } from '../../../constants/accounts';
import { ECompanyRoles } from '../../../constants/roles';
import { getCompanyName, getUserMetaData } from '../../../utils/auth';
import { createUser } from '../utils';

interface ICreateDialogProps {
  handleClose(): void;
  onSuccess(user: User): void;
  onError(error: string): void;
}

const schema = yup
  .object({
    email: yup
      .string()
      .email('Please enter a valid email address.')
      .required('Please enter email address.'),
    name: yup.string().required('Please enter name.').nullable(),
  })
  .required();

export default function CreateDialog({
  handleClose,
  onSuccess,
  onError,
}: ICreateDialogProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      name: '',
    },
  });

  const name = useWatch({ control, name: 'name' });

  const { getAccessTokenSilently, user } = useAuth0();
  const { isLoading, mutate } = useMutation(
    async ({ email, name }: FieldValues) => {
      const lowercasedEmail = email.toLowerCase();
      const token = await getAccessTokenSilently();

      return await createUser(token, {
        email: lowercasedEmail,
        name,
        appMetadata: {
          account_type: EAccountTypes.company,
          requester: user?.name,
          company_id: getUserMetaData(user)?.company_id,
          requester_id: getUserMetaData(user)?.local_user_id,
          company_name: getCompanyName(user),
          company_role: ECompanyRoles.admin,
        },
        userMetadata: {
          name,
          company_name: getCompanyName(user),
        },
      });
    },
    {
      onSuccess: (data) => {
        onSuccess({ ...data, name });
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error?.response?.data as { error?: string })?.error ??
          'Something went wrong. Please try again.';
        // Remove the status code if present in the error message
        const formattedErrorMessage = errorMessage.replace(
          /^\d{3} \w+:\s*/,
          ''
        );
        onError(formattedErrorMessage);
      },
    }
  );

  const onSubmit = async (data: FieldValues) => {
    mutate(data);
  };

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle>Invite Admin</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <DialogContentText>
                Please enter the Admin's email address and Name.
              </DialogContentText>
              <FormControl
                fullWidth
                required
                margin="normal"
                error={!!errors.email}
              >
                <TextField
                  {...register('email')}
                  id="email"
                  autoFocus
                  required
                  label="Email Address"
                  type="email"
                  variant="filled"
                  autoComplete="off"
                  aria-describedby="email-helper"
                />
                <FormHelperText id="email-helper">
                  {(errors.email?.message ?? '') as string}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal" error={!!errors.name}>
                <TextField
                  {...register('name')}
                  id="name"
                  label="Name"
                  required
                  variant="filled"
                  aria-describedby="name-helper"
                />
                <FormHelperText id="name-helper">
                  {(errors.name?.message ?? '') as string}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="admin-cancel-invite" onClick={handleClose} variant="text">
            Cancel
          </Button>
          <Button
            id="admin-confirm-invite"
            type="submit"
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <CircularProgress
                  size={20}
                  thickness={1}
                  style={{ marginRight: 10 }}
                />
                Inviting
              </>
            ) : (
              'Send Invite'
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
