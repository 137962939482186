import { useAuth0 } from '@auth0/auth0-react';
import dizzy from '@iconify/icons-twemoji/dizzy';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetTrailSubmissionResultsSubscription } from '../../../@generated/facadeClient';
import { Test_Tech_Services_Resources } from '../../../@generated/types';
import { AssessmentAlert } from '../../../components/AssessmentAlert';
import { AssessmentFeedback } from '../../../components/AssessmentFeedback';
import ClaimBadge from '../../../components/Badges/ClaimBadge';
import { copyBadgePublicUrl } from '../../../components/Badges/data';
import useClaimBadge from '../../../components/Badges/useClaimBadge';
import { ErrorPage } from '../../../components/ErrorDialog';
import LearningResourcesView from '../../../components/LearningResourcesView';
import CircularProgressPage from '../../../components/ProgressLoader/ProgressLoader';
import { isCompany } from '../../../utils/auth';
import { formatRegularRankingPositionDescription } from '../../../utils/common';
import { appendPercentileSuffix } from '../utils';
import EmptyAssessmentResultsPage from './EmptyResultsPage';
import EvaluationResultsCard, {
  getEvaluationResultCardMapper,
} from './EvaluationResultsCard';

export default function AssessmentResultsPage() {
  const { user } = useAuth0();
  const params = useParams<{ id: string }>();

  const { data, loading, error } = useGetTrailSubmissionResultsSubscription({
    variables: { trial_id: Number(params.id) },
    fetchPolicy: 'no-cache',
  });
  const hover = {
    boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  const {
    claimAlertMessage,
    claimAlertSeverity,
    claimAlertOpen,
    claimBadge,
    claiming,
    handleClaimAlertClose,
  } = useClaimBadge({
    badgeId: data?.test_trials_by_pk?.test?.badge?.badge_id || '',
    recipientEmail: user?.email || '',
    testId: data?.test_trials_by_pk?.test?.brokee_id || 0,
    userId: data?.test_trials_by_pk?.user_id || 0,
  });

  if (loading) return CircularProgressPage();

  if (error)
    return (
      <ErrorPage
        title="Failed to fetch results"
        onClose={goHome}
        message={'Please try again later.'}
      />
    );

  const trial = data?.test_trials_by_pk;

  if (!trial) return EmptyAssessmentResultsPage();

  const { time_taken_seconds, passed, total_tasks, completed_tasks, test } =
    trial;

  const selfStarted = trial.user_id === trial.requester_id;

  const testCategory = test.test_category?.name;

  const evaluationResultCards = getEvaluationResultCardMapper(
    time_taken_seconds,
    passed,
    completed_tasks || 0,
    total_tasks || 0
  );

  const rankingPosition = trial.user_test_stat?.ranking_position;
  const totalPassedTest = trial.test.test_stat?.total_passed;
  const formattedRankingPosition = appendPercentileSuffix(
    Number(rankingPosition)
  );
  const showRankingCard = rankingPosition && totalPassedTest && passed;

  const badgeAwards = test.badge_awards;
  const awardAvailable = test.badge?.badge_id && badgeAwards.length > 0;

  const handleBadgeAction = () => {
    if (awardAvailable) {
      const publicUrl = badgeAwards[0].public_url;
      const recipient = badgeAwards[0].recipient_email;
      copyBadgePublicUrl(publicUrl, recipient, setSnackbarOpen);
    } else {
      claimBadge();
    }
  };

  return (
    <>
      {claiming && <LinearProgress />}
      {passed && (
        <Confetti
          gravity={0.2}
          numberOfPieces={4000}
          recycle={false}
          wind={0.01}
          initialVelocityX={5}
          initialVelocityY={15}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
          pt: '4rem',
          pb: '4rem',
          pl: '14rem',
          pr: '14rem',
          m: 'auto',
          '@media (max-width: 1200px)': {
            pl: '8rem',
            pr: '8rem',
          },
          '@media (max-width: 768px)': {
            pl: '4rem',
            pr: '4rem',
          },
        }}
      >
        <Card
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            p: 1,
          }}
        >
          <CardHeader
            title={`Assessment's Submission Results`}
            subheader={test.name}
          />
          <Divider />
          <CardContent>
            <Paper
              sx={{
                p: 3,
                borderRadius: 2,
                overflow: 'hidden',
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" display="block" sx={{ pb: 2 }}>
                    Insights Into Your Performance
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={4}
                  sx={{ display: 'flex', alignItems: 'stretch' }}
                >
                  {evaluationResultCards.map((result) => (
                    <Grid
                      key={result.label}
                      item
                      xs={12}
                      sm={6}
                      md={showRankingCard ? 3 : 4}
                      sx={{ display: 'flex' }}
                    >
                      <EvaluationResultsCard
                        value={result.value}
                        bgcolor={result.bgcolor}
                        label={result.label}
                        icon={result.icon}
                      />
                    </Grid>
                  ))}

                  {rankingPosition && totalPassedTest && (
                    <Tooltip
                      title={formatRegularRankingPositionDescription(
                        rankingPosition,
                        totalPassedTest
                      )}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={showRankingCard ? 3 : 4}
                        sx={{ display: 'flex' }}
                      >
                        {passed && (
                          <EvaluationResultsCard
                            value={formattedRankingPosition}
                            bgcolor={'#ddf2d1'}
                            label={'Ranking'}
                            icon={dizzy}
                          />
                        )}
                      </Grid>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </CardContent>
          {!isCompany(user) && passed && test.badge?.badge_id && (
            <CardContent>
              <Card
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  p: 1,
                  '&:hover': hover,
                }}
              >
                <ClaimBadge
                  available={!!awardAvailable}
                  badgeName={test.badge?.title || ''}
                  onAction={handleBadgeAction}
                />
              </Card>
            </CardContent>
          )}
          {test.test_tech_services_resources.length > 0 && !passed && (
            <CardContent>
              <Paper
                sx={{
                  borderRadius: 2,
                  p: 3,
                }}
              >
                <Typography variant="h6">
                  Deepen Your Expertise in {testCategory}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ pb: 2, pt: 2 }}>
                  Here are learning resources that will help you understand the
                  topic covered in this test
                </Typography>
                <LearningResourcesView
                  resources={
                    test?.test_tech_services_resources as Test_Tech_Services_Resources[]
                  }
                />
              </Paper>
            </CardContent>
          )}

          <CardContent>
            <Paper
              elevation={3}
              sx={{
                borderRadius: 2,
                p: 3,
                width: '100%',
                margin: 'auto',
                '&:hover': hover,
              }}
            >
              <Typography variant="h6" mb={1}>
                Your opinion matter to us!
              </Typography>

              {selfStarted ? (
                <Typography gutterBottom variant="body1">
                  Please share your feedback for the Brokee team.
                </Typography>
              ) : (
                <Typography gutterBottom variant="body1">
                  Please share your feedback for the hiring team.
                </Typography>
              )}

              <AssessmentFeedback trialId={Number(params.id)} />
            </Paper>
          </CardContent>

          {!selfStarted && (
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h6" component="div">
                  Please await further instructions from your recruiter.
                </Typography>
              </Box>
            </CardContent>
          )}
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '1.5rem',
              }}
            >
              <Button
                id="go-home-from-evaluation-results"
                variant="outlined"
                color="inherit"
                onClick={goHome}
              >
                Go Home
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <AssessmentAlert
        text={claimAlertMessage}
        open={claimAlertOpen}
        severity={claimAlertSeverity}
        onClose={handleClaimAlertClose}
      />
      <AssessmentAlert
        text={'Public link for your badge copied!'}
        open={snackbarOpen}
        severity={'success'}
        variant={'standard'}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}
