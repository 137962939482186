import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IUpcomingInvoice } from '../utils';

interface IUpcomingInvoiceTableProps {
  upcomingInvoice: IUpcomingInvoice;
  loading: boolean;
}

export default function UpcomingInvoiceTable({
  loading = false,
  upcomingInvoice,
}: IUpcomingInvoiceTableProps) {
  return (
    <>
      <PerfectScrollbar>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>QTY</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {upcomingInvoice.lines.map((line) => {
              const { amount, description, quantity, unit_amount } = line;
              return (
                <TableRow hover key={`upcomingInvoice${amount}`}>
                  <TableCell>
                    {description.includes('×')
                      ? description.split('×')[1].trim()
                      : description}
                  </TableCell>
                  <TableCell>{quantity}</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'usd',
                    }).format(Number(unit_amount) / 100)}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ fontWeight: 'bold' }}>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'usd',
                      }).format(amount / 100)}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow hover key={`upcomingInvoice${upcomingInvoice.total}`}>
              <TableCell>{null}</TableCell>
              <TableCell>{null}</TableCell>
              <TableCell>
                <Box sx={{ fontWeight: 'bold' }}>Total</Box>
              </TableCell>
              <TableCell>
                <Box sx={{ fontWeight: 'bold' }}>
                  {new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(upcomingInvoice.total / 100)}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </PerfectScrollbar>
      {loading && <LinearProgress sx={{ width: '100%' }} />}
    </>
  );
}
