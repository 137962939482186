import { User } from '@auth0/auth0-react';
import { axiosInstance } from '../../utils/axios';
import { EstimatedExpenseResult } from '../../components/CostEstimator/data';
export const startCheckoutSession = async (
  accessToken: string,
  {
    product_plan,
    customer_id,
  }: {
    product_plan: string;
    customer_id: string;
  }
): Promise<{
  message: string;
  url: string;
}> => {
  const { data } = await axiosInstance.postForm(
    `/api/stripe/checkout/session`,
    {
      product_plan,
      customer_id,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const startBillingPortalSession = async (
  accessToken: string,
  {
    customer,
  }: {
    customer: string;
  }
): Promise<{
  message: string;
  portal_session_url: string;
}> => {
  const { data } = await axiosInstance.postForm(
    `/api/stripe/customers/billing-portal/sessions`,
    {
      customer,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const cancelSubscription = async (
  accessToken: string,
  {
    subscription_id,
  }: {
    subscription_id: string;
  }
): Promise<undefined> => {
  const { data } = await axiosInstance.delete(
    `/api/stripe/subscriptions/${subscription_id}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const createUser = async (
  accessToken: string,
  {
    email,
    name,
    appMetadata,
    userMetadata,
  }: {
    email: string;
    name: string;
    appMetadata: Record<string, unknown>;
    userMetadata: Record<string, unknown>;
  }
): Promise<User> => {
  const { data } = await axiosInstance.post(
    '/api/admin/users',
    {
      email,
      password: crypto.randomUUID(),
      verify_email: false,
      connection: 'Username-Password-Authentication',
      name,
      app_metadata: appMetadata,
      user_metadata: userMetadata,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const inviteUser = async (
  accessToken: string,
  { email, companyId }: { email: string; companyId: string }
): Promise<undefined> => {
  const formData = new FormData();
  formData.append('user_email', email);
  formData.append('company_id', companyId);
  const { data } = await axiosInstance.post(
    '/api/admin/users/invite',
    formData,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
};

export const deleteUser = async (
  accessToken: string,
  userID: string
): Promise<undefined> => {
  const { data } = await axiosInstance.delete(`/api/admin/users/${userID}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

export interface IUpcomingInvoiceLine {
  amount: number;
  description: string;
  quantity: number;
  unit_amount: string;
}

export interface IUpcomingInvoice {
  currency: string;
  lines: IUpcomingInvoiceLine[];
  next_payment_attempt: number;
  period_end: number;
  period_start: number;
  total: number;
}

export const getUpcomingInvoice = async (
  accessToken: string,
  { customer }: { customer: string }
): Promise<IUpcomingInvoice> => {
  const { data } = await axiosInstance.get(
    `/api/stripe/customers/${customer}/upcoming-invoice`,
    {
      params: {
        customer,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
};

export interface IHostedInvoice {
  message: string;
  hosted_invoice_url: string;
}

export const getHostedInvoiceUrl = async (
  accessToken: string,
  { invoiceId }: { invoiceId: string }
): Promise<IHostedInvoice> => {
  const { data } = await axiosInstance.get(
    `/api/stripe/invoices/${invoiceId}/hosted-invoice-url`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
};

export const estimateCostUsage = async (
  accessToken: string,
  { quantity }: { quantity: number }
): Promise<EstimatedExpenseResult> => {
  const { data } = await axiosInstance.get(
    `/api/stripe//customers/estimate-cost-usage`,
    {
      params: {
        quantity,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
};

export function redoFn(fn: () => void, times: number, interval: number) {
  const intervalIds: NodeJS.Timeout[] = [];
  for (let i = 1; i <= times; i++) {
    const intervalId = setTimeout(() => {
      fn();
    }, i * interval);
    intervalIds.push(intervalId);
  }

  function stop() {
    intervalIds.forEach((id) => {
      clearTimeout(id);
    });
  }

  return stop;
}
