import {
  EAssessmentCategoryType,
  EEnvironmentType,
} from '../../../constants/assessment';
import { axiosInstance } from '../../../utils/axios';

export interface EnvironmentInfrastructure {
  name: string;
  current: boolean;
  updateInProgress: boolean;
  resourceCount: number;
  outputs: Record<string, string>;
  lastUpdate: Date;
  lastStatus: 'in-progress' | 'succeeded' | 'failed';
}

interface TestData {
  candidateId: string;
  testId: string;
  terminalSecret: string;
  companyID: string;
  issuesIDs: string;
  email?: string;
  region?: string;
  zone?: string;
}

interface RequestBody {
  testData: TestData;
}

export const createEnvironmentInfra = async (
  accessToken: string,
  assessmentID: string,
  assessmentType: string,
  assessmentCategory: string,
  companyID: string,
  terminalSecret: string,
  testIssuesIDs: number[],
  userEmail: string | undefined,
  userHash: string
): Promise<string> => {
  if (!userHash || !assessmentID || !companyID) {
    throw new Error('Missing required fields for stack data');
  }

  const body: RequestBody = {
    testData: {
      candidateId: userHash,
      testId: assessmentID,
      terminalSecret,
      companyID,
      issuesIDs: JSON.stringify(testIssuesIDs),
    },
  };
  if (assessmentType === EEnvironmentType.CODING) {
    if (!userEmail) {
      throw new Error('Missing email for IDE test type');
    }
    body.testData.email = userEmail;
  }

  if (assessmentCategory === EAssessmentCategoryType.GCP) {
    if (!userEmail) {
      throw new Error('Missing email for GCP test category');
    }
    body.testData.email = userEmail;
    body.testData.region = 'us-central1';
    body.testData.zone = 'us-central1-a';
  }

  try {
    const response = await axiosInstance.post(
      '/api/provisioner/v1/stacks',
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to create environment: ${error}`);
  }
};

export const retrieveEnvironmentInfra = async (
  accessToken: string,
  { userHash, testId }: { userHash: string; testId: string }
): Promise<EnvironmentInfrastructure> => {
  const { data } = await axiosInstance.get(
    `/api/provisioner/v1/stacks/${testId}/${userHash}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};
