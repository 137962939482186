import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

interface ICountdownProps {
  startTime: number;
}

const Countdown: React.FC<ICountdownProps> = ({ startTime }) => {
  const calculateTimeLeft = useCallback(() => {
    const now = Date.now();
    const endTime = startTime + 3 * 60 * 60 * 1000;
    const difference = endTime - now;

    return difference > 0 ? Math.floor(difference / 1000) : 0;
  }, [startTime]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft, calculateTimeLeft]);

  const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const getColor = () => {
    return timeLeft < 600 ? 'red' : 'inherit'; // less than 10 minutes
  };

  return (
    <Tooltip title="After 3 hours, it starts evaluating results automatically and submits your solution">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box
          component="span"
          sx={{
            fontFamily: 'monospace',
            color: getColor(),
            width: '75px', // Fixed width to prevent shifting
            textAlign: 'center',
          }}
        >
          {formatTime(timeLeft)}
        </Box>
        <AccessTimeIcon />
      </Box>
    </Tooltip>
  );
};

export default Countdown;
