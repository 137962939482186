import { Card, CardContent, Grid, Typography } from '@mui/material';
import TrendingTestCard from '../TestCards/MostAttemptedTestCard';
import { useTopRunnigTestsPerAccountQuery } from '../../../@generated/facadeClient';

export default function MostAttemptedOverview() {
  const topRunningTests = useTopRunnigTestsPerAccountQuery({
    variables: { limit: 3 },
  });
  const topRunningTestsData = topRunningTests.data?.tests;

  const tests = topRunningTestsData?.map((test) => ({
    id: test.brokee_id,
    name: test.name,
    icon: test.featured_image,
    attempts: test.total_test_trials_aggregate.aggregate?.count,
    completionTime:
      test.passed_test_trials_aggregate.aggregate?.avg?.time_taken_seconds,
    description: test.description_short,
    requires_payment: test.requires_payment,
  }));

  return (
    <Card elevation={3}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" display="block">
              Most Attempted Assessments
            </Typography>
            <Typography
              variant="subtitle1"
              display="block"
              sx={{ mb: '2rem', color: 'gray' }}
            >
              Check out the most attempted assessments within your company!
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {tests?.map(
              (test) =>
                (test.attempts ?? 0) >= 1 && (
                  <Grid item xs={12} sm={6} md={4} key={test.id}>
                    <TrendingTestCard test={test} />
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
