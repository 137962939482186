import { useAuth0 } from '@auth0/auth0-react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { format, getTime } from 'date-fns';
import { useQuery } from 'react-query';
import {
  useGetActiveSubscriptionByCompanyIdQuery,
  useGetCompanyDetailsByCompanyIdQuery,
} from '../../../@generated/facadeClient';
import { getUserMetaData } from '../../../utils/auth';
import { getUpcomingInvoice } from '../utils';
import UpcomingInvoiceTable from './UpcomingInvoiceTable';

export default function Transactions() {
  const { user, getAccessTokenSilently } = useAuth0();
  const { data: activeSubscription } = useGetActiveSubscriptionByCompanyIdQuery(
    {
      variables: {
        company_id: getUserMetaData(user)?.company_id,
      },
    }
  );

  const { data: companyData, loading } = useGetCompanyDetailsByCompanyIdQuery({
    variables: {
      company_id: getUserMetaData(user)?.company_id,
    },
  });

  const companyStripeCustomerId =
    companyData?.companies?.[0]?.stripe_customer_id;

  const upcomingInvoiceQuery = useQuery(
    'upcomingInvoice',
    async () => {
      const token = await getAccessTokenSilently();

      return await getUpcomingInvoice(token, {
        customer: companyStripeCustomerId || '',
      });
    },
    {
      enabled:
        !!companyStripeCustomerId && !!activeSubscription?.subscriptions?.[0],
    }
  );

  if (!activeSubscription?.subscriptions?.[0] || !upcomingInvoiceQuery.data) {
    return null;
  }

  const upcomingInvoice = upcomingInvoiceQuery.data;

  return (
    <Card elevation={3}>
      <CardHeader
        title="Upcoming Invoice"
        subheader={`This is a preview of the invoice that will be billed on ${format(
          getTime(upcomingInvoice.period_end * 1000),
          'MMM d, y'
        )}. It may change if the subscription is updated.`}
      />
      <CardContent>
        <UpcomingInvoiceTable
          upcomingInvoice={upcomingInvoice}
          loading={loading}
        />
      </CardContent>
    </Card>
  );
}
