import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  AlertColor,
  CircularProgress,
  Grid,
  Snackbar,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import {
  useGetCompanyDetailsByCompanyIdQuery,
  useGetPricingPlansQuery,
} from '../../@generated/facadeClient';
import { PricingPlansPartialFragment } from '../../@generated/types';
import FAQ from '../../common/Pricing/FAQ';
import { PricingCard } from '../../common/Pricing/PricingCard';
import { EAccountTypeIds } from '../../constants/accounts';
import { EPricingPlans } from '../../constants/billing';
import { useBilling } from '../../hooks/useBilling';
import { getUserMetaData, isPersonal, isCompany } from '../../utils/auth';
import ConfirmationDialog from './ConfirmationDialog';
import { EConfirmationActions, confirmationMessagesMap } from './data';
import { cancelSubscription, startCheckoutSession } from './utils';
import { CostEstimator } from '../../components/CostEstimator';
export default function SubscriptionTab() {
  const { getAccessTokenSilently, user } = useAuth0();
  const [confirmationAction, setConfirmationAction] =
    useState<EConfirmationActions>();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');

  const {
    companyAccessDetails,
    companyAccessDetailsLoading,
    hasActiveFreeTrial,
    hasCanceledGrowthPlan,
    hasCanceledPersonalPlan,
    refetchCompanyAccessDetails,
  } = useBilling();

  const accountTypeId = isPersonal(user)
    ? EAccountTypeIds.personal
    : EAccountTypeIds.company;

  const { data: pricingPlans, loading } = useGetPricingPlansQuery({
    variables: {
      accountTypeIds: [accountTypeId],
    },
  });

  const { data: companyData } = useGetCompanyDetailsByCompanyIdQuery({
    variables: {
      company_id: getUserMetaData(user)?.company_id,
    },
  });

  const availablePlans = useMemo(() => {
    if (!pricingPlans?.pricing_plans) {
      return [];
    }

    if (hasActiveFreeTrial()) {
      return pricingPlans?.pricing_plans;
    }

    return pricingPlans?.pricing_plans.filter((plan) => {
      return plan.name !== EPricingPlans.Free;
    });
  }, [hasActiveFreeTrial, pricingPlans?.pricing_plans]);

  const {
    isActiveGrowth,
    isActiveOnDemand,
    isActivePersonalLite,
    isActiveOnDemandPersonal,
  } = useMemo(
    () => ({
      isActiveGrowth:
        companyAccessDetails?.plan_name === EPricingPlans.Growth &&
        companyAccessDetails?.is_active_subscription,
      isActiveOnDemand:
        companyAccessDetails?.plan_name === EPricingPlans.OnDemand &&
        companyAccessDetails?.available_credits > 0,
      isActivePersonalLite:
        companyAccessDetails?.plan_name === EPricingPlans.PersonalLite &&
        companyAccessDetails?.is_active_subscription,
      isActiveOnDemandPersonal:
        companyAccessDetails?.plan_name === EPricingPlans.OnDemandPersonal &&
        companyAccessDetails?.available_credits > 0,
    }),
    [
      companyAccessDetails?.available_credits,
      companyAccessDetails?.is_active_subscription,
      companyAccessDetails?.plan_name,
    ]
  );

  const { mutate: subscribe } = useMutation(
    async (planName: string) => {
      const token = await getAccessTokenSilently();

      return await startCheckoutSession(token, {
        product_plan: planName,
        customer_id: companyData?.companies?.[0]?.stripe_customer_id ?? '',
      });
    },
    {
      onSuccess: (data) => {
        window.location.assign(data.url);
      },
      onError: () => {
        handleError(
          'Subscription failed, please contact us us via chat or at info@brokee.io'
        );
      },
      retry: 5,
      retryDelay: 500,
    }
  );

  const { mutate: unsubscribe } = useMutation(
    async ({ subscriptionId }: { subscriptionId: string }) => {
      const token = await getAccessTokenSilently();

      return await cancelSubscription(token, {
        subscription_id: subscriptionId,
      });
    },
    {
      onSuccess: refetchCompanyAccessDetails,
    }
  );

  const confirmationActionsMap = useMemo(
    () => ({
      [EConfirmationActions.CANCEL_GROWTH]: () => {
        unsubscribe(
          {
            subscriptionId: companyAccessDetails?.stripe_subscription_id || '',
          },
          {
            onSuccess() {
              handleSuccess(
                'Canceled successfully. Changes may take a few seconds to reflect. '
              );
              refetchCompanyAccessDetails();
            },
            onError() {
              handleError(
                'Cancelation failed, please contact us via chat or at info@brokee.io'
              );
            },
          }
        );
      },
      [EConfirmationActions.CANCEL_GROWTH_AND_SUB_TO_ON_DEMAND]: () => {
        unsubscribe(
          {
            subscriptionId: companyAccessDetails?.stripe_subscription_id || '',
          },
          {
            onSuccess() {
              subscribe(EPricingPlans.OnDemand);
            },
            onError() {
              handleError(
                'Cancelation failed, please contact us via chat or at info@brokee.io'
              );
            },
          }
        );
      },
      [EConfirmationActions.GO_FROM_ON_DEMAND_TO_GROWTH]: () => {
        subscribe(EPricingPlans.Growth);
      },
      [EConfirmationActions.MOVE_FROM_FREE_TO_GROWTH]: () => {
        subscribe(EPricingPlans.Growth);
      },
      [EConfirmationActions.MOVE_FROM_FREE_TO_ON_DEMAND]: () => {
        subscribe(EPricingPlans.OnDemand);
      },
      [EConfirmationActions.CANCEL_PERSONAL]: () => {
        unsubscribe(
          {
            subscriptionId: companyAccessDetails?.stripe_subscription_id || '',
          },
          {
            onSuccess() {
              handleSuccess(
                'Canceled successfully. Changes may take a few seconds to reflect.'
              );
              refetchCompanyAccessDetails();
            },
            onError() {
              handleError(
                'Cancelation failed, please contact us via chat or at info@brokee.io'
              );
            },
          }
        );
      },
      [EConfirmationActions.MOVE_FROM_FREE_TO_PERSONAL]: () => {
        subscribe(EPricingPlans.PersonalLite);
      },
      [EConfirmationActions.MOVE_FROM_FREE_TO_ON_DEMAND_PERSONAL]: () => {
        subscribe(EPricingPlans.OnDemandPersonal);
      },
      [EConfirmationActions.GO_FROM_PERSONAL_TO_ON_DEMAND_PERSONAL]: () => {
        subscribe(EPricingPlans.OnDemandPersonal);
      },
    }),
    [
      companyAccessDetails?.stripe_subscription_id,
      refetchCompanyAccessDetails,
      subscribe,
      unsubscribe,
    ]
  );

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleSuccess = (message: string) => {
    setShowSnackbar(true);
    setSeverity('success');
    setMessage(message);
  };

  const handleError = (error: string) => {
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(error);
  };

  const onClose = () => {
    setConfirmationAction(undefined);
  };

  function onAction(selectedPlan: string) {
    switch (selectedPlan as EPricingPlans) {
      case EPricingPlans.Growth: {
        if (isActiveGrowth) {
          setConfirmationAction(EConfirmationActions.CANCEL_GROWTH);
        } else if (companyAccessDetails?.plan_name === EPricingPlans.OnDemand) {
          setConfirmationAction(
            EConfirmationActions.GO_FROM_ON_DEMAND_TO_GROWTH
          );
        } else if (hasActiveFreeTrial()) {
          setConfirmationAction(EConfirmationActions.MOVE_FROM_FREE_TO_GROWTH);
        } else if (hasCanceledGrowthPlan()) {
          subscribe(selectedPlan);
        }
        return;
      }
      case EPricingPlans.OnDemand: {
        if (isActiveGrowth) {
          setConfirmationAction(
            EConfirmationActions.CANCEL_GROWTH_AND_SUB_TO_ON_DEMAND
          );
        } else if (companyAccessDetails?.plan_name === EPricingPlans.OnDemand) {
          subscribe(selectedPlan);
        } else if (hasActiveFreeTrial()) {
          setConfirmationAction(
            EConfirmationActions.MOVE_FROM_FREE_TO_ON_DEMAND
          );
        } else if (hasCanceledGrowthPlan()) {
          subscribe(selectedPlan);
        }
        return;
      }
      case EPricingPlans.PersonalLite: {
        if (isActivePersonalLite) {
          setConfirmationAction(EConfirmationActions.CANCEL_PERSONAL);
        } else if (hasActiveFreeTrial()) {
          setConfirmationAction(
            EConfirmationActions.MOVE_FROM_FREE_TO_PERSONAL
          );
        } else if (hasCanceledPersonalPlan()) {
          subscribe(selectedPlan);
        } else {
          subscribe(selectedPlan);
        }
        return;
      }
      case EPricingPlans.OnDemandPersonal: {
        if (isActivePersonalLite) {
          setConfirmationAction(
            EConfirmationActions.GO_FROM_PERSONAL_TO_ON_DEMAND_PERSONAL
          );
        } else if (hasActiveFreeTrial()) {
          setConfirmationAction(
            EConfirmationActions.MOVE_FROM_FREE_TO_ON_DEMAND_PERSONAL
          );
        } else {
          subscribe(selectedPlan);
        }
        return;
      }
    }
  }

  function getCallToActionText(plan: PricingPlansPartialFragment) {
    if (isActiveGrowth && plan.name === EPricingPlans.Growth) {
      return 'Cancel Subscription';
    }
    if (isActivePersonalLite && plan.name === EPricingPlans.PersonalLite) {
      return 'Cancel Subscription';
    }
    if (isActiveOnDemand && plan.name === EPricingPlans.OnDemand)
      return 'Buy More Tests';

    return plan.call_to_action;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      spacing={4}
      sx={{ pt: '2rem' }}
    >
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        spacing={4}
        xs={12}
        sx={{ pb: '4rem' }}
      >
        {(companyAccessDetailsLoading || loading) && (
          <Grid item xs={12}>
            <CircularProgress
              color="primary"
              sx={{ display: 'block', margin: 'auto' }}
            />
          </Grid>
        )}
        {isCompany(user) && (
          <Grid item xs={12}>
            <CostEstimator />
          </Grid>
        )}
        {!companyAccessDetailsLoading &&
          availablePlans?.map((plan) => {
            return (
              <Grid
                item
                xs={12}
                sm={hasActiveFreeTrial() ? 4 : 4}
                key={plan.name}
              >
                <PricingCard
                  {...plan}
                  isActivePlan={
                    plan.name === companyAccessDetails?.plan_name &&
                    (hasActiveFreeTrial() ||
                      isActiveGrowth ||
                      isActiveOnDemand ||
                      isActivePersonalLite ||
                      isActiveOnDemandPersonal)
                  }
                  isCancelButton={
                    (isActiveGrowth && plan.name === EPricingPlans.Growth) ||
                    (isActivePersonalLite &&
                      plan.name === EPricingPlans.PersonalLite)
                  }
                  isDisabledButton={
                    hasActiveFreeTrial() && plan.name === EPricingPlans.Free
                  }
                  call_to_action={getCallToActionText(plan)}
                  onActionCall={onAction}
                />
              </Grid>
            );
          })}
      </Grid>
      <FAQ />
      {confirmationAction && (
        <ConfirmationDialog
          title={confirmationMessagesMap[confirmationAction].title}
          message={confirmationMessagesMap[confirmationAction].message}
          onClose={onClose}
          onConfirm={() => {
            confirmationActionsMap[confirmationAction]();
            onClose();
          }}
        />
      )}
      {showSnackbar && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            variant="filled"
            onClose={handleSnackbarClose}
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </Grid>
  );
}
