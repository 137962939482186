import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import infoIcon from '@iconify/icons-eva/info-outline';
import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AxiosError } from 'axios';
import { formatISO } from 'date-fns';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import {
  useGetPrivateTestsQuery,
  useUpdateAssessmentLinkMutation,
} from '../../@generated/facadeClient';
import { AssessmentLinksPartialFragment } from '../../@generated/types';
import { SubscriptionAlert } from '../../components/SubscriptionAlert';
import { useBilling } from '../../hooks/useBilling';
import { getCompanyId, getUserMetaData } from '../../utils/auth';
import { IAssessmentLinkResponse } from './data';
import { createInvitationLink } from './utils'; // Ensure you have updateInvitationLink
import { calculateProblemsThreshold } from '../../utils/common';
interface IInvitationLinkDialogProps {
  linkData?: AssessmentLinksPartialFragment; // Optional for edit mode
  handleClose(): void;
  onEditSuccess(): void;
  onSuccess(data: IAssessmentLinkResponse): void;
  onError(error: string): void;
  propTestId?: number;
}

const schema = yup
  .object({
    jobTitle: yup.string().required('Please add job title.'),
    testId: yup.string().required('Please select test.').nullable(),
    expiresAt: yup
      .date()
      .typeError('Please enter a valid date.')
      .nullable()
      .min(new Date(), 'Please choose future date.'),
    maxApplicants: yup.number().nullable(),
    issuesPerTest: yup.array().of(yup.object()),
  })
  .required();

export default function InvitationLinkDialog({
  linkData,
  handleClose,
  onSuccess,
  onEditSuccess,
  onError,
  propTestId,
}: IInvitationLinkDialogProps) {
  const { getAccessTokenSilently, user } = useAuth0();
  const { hasActiveFreeTrial, canAccessProFeatures } = useBilling();
  const [updateAssessmentLink] = useUpdateAssessmentLinkMutation();
  const companyId = getCompanyId(user);

  const { loading, data: { tests } = { tests: [] } } = useGetPrivateTestsQuery({
    variables: { company_id: companyId },
  });

  const { pathname } = useLocation();
  const s = pathname.split('/');
  const test = s[s.length - 2];
  const isTestCatalogue = test === 'test-details';
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    resetField,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      jobTitle: linkData?.job_title || '',
      testId: isTestCatalogue
        ? propTestId?.toString()
        : linkData?.test?.toString() || '',
      expiresAt: linkData?.expires_at || null,
      maxApplicants: linkData?.max_applicants || null,
      issuesPerTest: undefined,
    },
  });

  const testId = useWatch({ control, name: 'testId' });

  useEffect(() => {
    resetField('issuesPerTest');
  }, [testId, resetField]);

  const [maxApplicants, setMaxApplicants] = useState(
    linkData?.max_applicants || null
  );
  const selectedTest = useMemo(
    () => tests?.find(({ brokee_id }) => brokee_id.toString() === testId),
    [tests, testId]
  );
  const { minThreshold, maxThreshold } = calculateProblemsThreshold(
    selectedTest?.issues_per_test || 0
  );

  const handleMaxApplicantsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = +event.target.value;

    if (value >= 0) {
      setMaxApplicants(value);
    }
  };

  const { isLoading, mutate } = useMutation(
    async ({
      jobTitle,
      testId,
      maxApplicants,
      expiresAt,
      issuesPerTest,
    }: FieldValues) => {
      const token = await getAccessTokenSilently();
      const payload = {
        test_id: parseInt(testId, 10),
        company_id: getUserMetaData(user)?.company_id,
        requester_id: getUserMetaData(user)?.local_user_id,
        job_title: jobTitle,
        max_applicants: maxApplicants === 0 ? null : maxApplicants,
        expires_at: expiresAt ? formatISO(expiresAt) : undefined,
        issues_list: issuesPerTest
          ? issuesPerTest.map((test: any) => test?.issue_id).toString()
          : null,
      };

      if (linkData) {
        updateAssessmentLink({
          variables: {
            id: linkData.id,
            job_title: jobTitle,
            max_applicants: maxApplicants === 0 ? null : maxApplicants,
            expires_at: expiresAt ? formatISO(expiresAt) : undefined,
          },
          onCompleted: (response) => {
            onEditSuccess();
          },
          onError: (error: Error) => {
            onError('Something went wrong. Please try again.');
          },
        });
      } else {
        return await createInvitationLink(token, payload);
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          reset();
          onSuccess(data);
        }
      },
      onError: (error: AxiosError) => {
        onError(
          (error?.response?.data as { message: string })?.message ??
            `Something went wrong. Please try again.`
        );
      },
    }
  );

  const onSubmit = async (data: FieldValues) => {
    if (
      selectedTest?.dynamic_issues &&
      data.issuesPerTest &&
      (data.issuesPerTest.length < minThreshold ||
        data.issuesPerTest.length > maxThreshold)
    ) {
      setError('issuesPerTest', {
        type: 'custom',
        message: `Select a number of problems between ${minThreshold} and ${maxThreshold} to meet the test requirements.`,
      });
      return;
    }
    mutate(data);
  };

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle>{linkData ? 'Edit' : 'Create'} Invitation Link</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent>
          <Grid container spacing={2}>
            {!canAccessProFeatures() && (
              <Grid item xs={12}>
                <SubscriptionAlert text="Unlock advanced tests." />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl
                fullWidth
                required
                margin="normal"
                error={!!errors.jobTitle}
              >
                <TextField
                  {...register('jobTitle')}
                  id="jobTitle"
                  required
                  autoFocus
                  label="Job Title"
                  aria-describedby="job-title-error"
                  type="text"
                  variant="filled"
                />
                <FormHelperText id="job-title-error">
                  {(errors.jobTitle?.message ?? '') as string}
                </FormHelperText>
              </FormControl>
              {!linkData && (
                <FormControl
                  fullWidth
                  required
                  margin="normal"
                  error={!!errors.testId}
                  disabled={loading}
                >
                  <TextField
                    {...register('testId')}
                    select
                    required
                    disabled={isTestCatalogue}
                    label="Test"
                    aria-describedby="test-helper"
                    SelectProps={{ native: true }}
                    defaultValue={testId}
                  >
                    {!tests.some(
                      ({ brokee_id }) => brokee_id === Number(testId)
                    ) && <option value={testId}>{selectedTest?.name}</option>}
                    {tests
                      .filter(({ requires_payment }) =>
                        hasActiveFreeTrial() ? !requires_payment : true
                      )
                      .map(({ brokee_id, name, dynamic_issues }) => (
                        <option key={`test${brokee_id}`} value={brokee_id}>
                          {name} {dynamic_issues && '🎲'}
                        </option>
                      ))}
                  </TextField>
                  <FormHelperText id="test-helper">
                    {(errors.testId?.message ?? '') as string}
                  </FormHelperText>
                  <Typography variant="caption">
                    Tests marked with a (🎲) icon are dynamic: each candidate
                    gets a random set of problems.
                  </Typography>
                </FormControl>
              )}
              <FormControl fullWidth margin="normal">
                <Controller
                  name="expiresAt"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      label="Expires At"
                      onChange={(date) => field.onChange(date)}
                      value={field.value}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  )}
                />
                <FormHelperText id="expiresAt-error">
                  {(errors.expiresAt?.message ?? '') as string}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  {...register('maxApplicants', {
                    valueAsNumber: true,
                    validate: (value) =>
                      Number(value) >= 0 || 'Number cannot be negative',
                  })}
                  id="maxApplicants"
                  label="Max Candidates (0 for unlimited)"
                  type="number"
                  variant="filled"
                  value={maxApplicants}
                  onChange={handleMaxApplicantsChange}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
                <Typography variant="caption">
                  Enter the maximum number of candidates who can use this link.
                  This field helps you control company expenses by limiting the
                  number of candidates invited through this link.
                </Typography>
              </FormControl>
              {selectedTest?.dynamic_issues && (
                <FormControl
                  fullWidth
                  required
                  margin="normal"
                  error={!!errors.issuesPerTest}
                >
                  <Controller
                    name="issuesPerTest"
                    control={control}
                    render={({ field }) => (
                      <Tooltip
                        title={
                          !canAccessProFeatures()
                            ? 'Subscribe to be able to select set of problems'
                            : ''
                        }
                        placement="top"
                      >
                        <Autocomplete
                          {...field}
                          multiple
                          disabled={!canAccessProFeatures()}
                          options={selectedTest?.test_issues ?? []}
                          disableCloseOnSelect
                          onChange={(_, value) => {
                            clearErrors('issuesPerTest');
                            field.onChange(value);
                          }}
                          value={field.value || []}
                          getOptionLabel={(option) => option.title ?? ''}
                          size="medium"
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  justifyItems: 'space-between',
                                  alignItems: 'center',
                                  width: '100%',
                                }}
                              >
                                <Box>
                                  <Checkbox
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.title}
                                </Box>
                                <Box>
                                  <Tooltip title={option.explanation ?? ''}>
                                    <Icon icon={infoIcon} />
                                  </Tooltip>
                                </Box>
                              </Box>
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Set of problems" />
                          )}
                          componentsProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, 10],
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </Tooltip>
                    )}
                  />
                  <FormHelperText id="issuesPerTest-helper" error>
                    {(errors.issuesPerTest?.message ?? '') as string}
                  </FormHelperText>
                  <Typography variant="caption">
                    {`By default, the test will generate a random set of ${selectedTest.issues_per_test} problems.
                    If you require specific issues to be included, you have the
                    option to select a custom set of problems.`}
                  </Typography>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            id="invitation-link-close-view"
            onClick={handleClose}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            id="invitation-link-modify"
            type="submit"
            variant="contained"
            disabled={isLoading || loading}
          >
            {linkData ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
