export const hubspotGetStarted =
  'https://share.hsforms.com/11GNkzmczS4-NGhtBwLhUZgd9i9c';

export const candidateWebsite = `${process.env.REACT_APP_DOMAIN_URL}`;

export const assessmentFeedbackUrl = 'https://forms.gle/mpFDaeRoN7cLpCro9';
export const hubspotCalendar = 'https://meetings.hubspot.com/maksym-lushpenko';

export const socialLinkedIn = 'https://www.linkedin.com/company/brokee/';

export const socialFacebook =
  'https://www.facebook.com/people/Brokeeio/100088689581172/';

export const termsOfUseUrl =
  'https://app.termly.io/document/terms-of-service/28563b2a-51a4-4f4e-abb3-02f3ec3b9c3a';

export const privacyPolicyUrl =
  'https://app.termly.io/document/privacy-policy/76c77320-69de-4250-8cc4-01d816158db2';

export const welcomeVideoForCompany =
  'https://www.loom.com/embed/5f8a8de72f9b4cdb8a92868f739a3e90?sid=1501c7bb-e304-44c9-a128-d11f794ab93e';
export const blogAboutUs = '/we-are-brokee';

export const blogOurTeam = '/meet-brokee-team';

export const blogContactUs = '/contact-us';

export const homeUrl = '/';

export const pricing = '/pricing';

export const publicImages = {
  contactUs:
    'https://storage.googleapis.com/brokee-public/img/public-website-images/contact-us.jpg',
  coding:
    'https://storage.googleapis.com/brokee-public/img/public-website-images/coding.jpeg',
  ourTeam:
    'https://storage.googleapis.com/brokee-public/img/public-website-images/our-team.jpg',
  maksym:
    'https://storage.googleapis.com/brokee-public/img/public-website-images/maksym.webp',
  meghan:
    'https://storage.googleapis.com/brokee-public/img/public-website-images/meghan.webp',
  yuliia:
    'https://storage.googleapis.com/brokee-public/img/public-website-images/yuliia.jpeg',
  aboutUs:
    'https://storage.googleapis.com/brokee-public/img/public-website-images/about-us.jpg',
};
