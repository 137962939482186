import { axiosInstance } from '../../utils/axios';

export interface IUpgradeToPersonalAccount {
  account_type_id: number;
  account_type: string;
  auth0_id: string;
  email: string;
  company_role_id: number;
  company_role: string;
  company_name: string;
}
interface IUpgradeToPersonalAccountResponse {
  message: string;
}

export const upgradeToPersonalAccount = async (
  accessToken: string,
  accountDetails: IUpgradeToPersonalAccount
): Promise<IUpgradeToPersonalAccountResponse> => {
  const {
    account_type_id,
    account_type,
    auth0_id,
    email,
    company_role_id,
    company_role,
    company_name,
  } = accountDetails;

  const { data } = await axiosInstance.post(
    '/api/storage-commons/accounts/upgrade-to-personal',
    {
      account_type_id,
      account_type,
      auth0_id,
      email,
      company_role_id,
      company_role,
      company_name,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};
