import { axiosInstance } from './axios';

export const createStripeCustomer = async (
  accessToken: string,
  name: string,
  email: string
): Promise<undefined> => {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('email', email);
  const { data } = await axiosInstance.post('api/stripe/customers', formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

export const startFreeTrial = async (
  accessToken: string,
  accountType: string,
  companyId: number
): Promise<undefined> => {
  const formData = new FormData();
  formData.append('account_type', `${accountType}`);
  formData.append('company_id', `${companyId}`);
  const { data } = await axiosInstance.post(
    'api/stripe/customers/claim-free-trial-credits',
    formData,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
};
