import { Theme } from '@mui/material';

import Button from './Button';
import Card from './Card';
import CssBaseline from './CssBaseline';
import Table from './Table';
import Input from './Input';
import Avatar from './Avatar';
import Form from './Form';
import Tab from './Tab';
import Paper from './Paper';
import Accordion from './Accordion';

export default function ComponentsOverrides(theme: Theme) {
  return {
    ...CssBaseline(theme),
    ...Button(theme),
    ...Card(theme),
    ...Table(theme),
    ...Input(theme),
    ...Avatar(theme),
    ...Form(theme),
    ...Tab(theme),
    ...Paper(theme),
    ...Accordion(theme),
  };
}
