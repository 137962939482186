export const defaultLimit = 10;

export const defaultPage = 0;

export const defaultFilters = {
  deleted_at: { _is_null: true },
};

export enum ESortByKeys {
  invitedAtUp = 'invitedAtUp',
  invitedAtDown = 'invitedAtDown',
  timeUp = 'timeUp',
  timeDown = 'timeDown',
}

export const sortByMap = {
  [ESortByKeys.invitedAtUp]: {
    invited_at: 'asc',
  },
  [ESortByKeys.invitedAtDown]: {
    invited_at: 'desc',
  },
  [ESortByKeys.timeUp]: {
    time_taken_seconds: 'desc_nulls_last',
  },
  [ESortByKeys.timeDown]: {
    time_taken_seconds: 'asc_nulls_last',
  },
};

export const sortBy = [
  {
    value: ESortByKeys.invitedAtDown,
    label: 'Invited At (Newest First)',
  },
  {
    value: ESortByKeys.invitedAtUp,
    label: 'Invited At (Oldest First)',
  },
  {
    value: ESortByKeys.timeDown,
    label: 'Time taken (Shortest First)',
  },
  {
    value: ESortByKeys.timeUp,
    label: 'Time taken  (Longest First)',
  },
];

export enum EFilterByResultKeys {
  all = 'all',
  passed = 'passed',
  failed = 'failed',
  none = 'none',
}

export const filterByResultMaps = {
  [EFilterByResultKeys.all]: {},
  [EFilterByResultKeys.passed]: { passed: { _eq: true } },
  [EFilterByResultKeys.failed]: { passed: { _eq: false } },
  [EFilterByResultKeys.none]: { passed: { _is_null: true } },
};

export const filterByTestResult = [
  {
    value: EFilterByResultKeys.all,
    label: 'All',
  },
  {
    value: EFilterByResultKeys.none,
    label: 'Pending',
  },
  {
    value: EFilterByResultKeys.passed,
    label: 'Passed',
  },
  {
    value: EFilterByResultKeys.failed,
    label: 'Failed',
  },
];
