import infoIcon from '@iconify/icons-eva/info-outline';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Popover,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useBilling } from '../../../hooks/useBilling';
import UpgradePlanDialog from '../TestCatalogueDetail/UIComponents/UpgradePlanDialog';
import { TestCatalogProps } from '../interfaces';
import { Difficulty_levels } from './utils';
export const TestCatalog: FC<TestCatalogProps> = ({
  tests,
  page,
  limit,
  total,
  loading,
  onChangeLimit,
  onChangePage,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverContent, setPopoverContent] = useState('');
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    description: string
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setPopoverContent(description);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };

  const open = Boolean(anchorEl);

  const { canAccessProFeatures } = useBilling();
  const [showUpgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filters = searchParams.get('filtersParams');
  const isDataLoaded = tests && tests.length > 0;
  const handleNavigation = (testId: string, payment_required: boolean) => {
    if (canAccessProFeatures() || !payment_required) {
      navigate(`/company/tests-catalogue/test-details/${testId}`, {
        state: {
          filters,
          page,
          limit,
        },
      });
    } else {
      showUpgradePlanDialog();
    }
  };

  const handleLaunchTest = (testId: string, payment_required: boolean) => {
    if (canAccessProFeatures() || !payment_required) {
      navigate(`/assessments/${testId}`, {
        state: {
          filters,
          page,
          limit,
        },
      });
    } else {
      showUpgradePlanDialog();
    }
  };

  const showUpgradePlanDialog = () => {
    setUpgradePlanDialogOpen(true);
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(newPage);
  };

  return (
    <Paper elevation={0}>
      {showUpgradePlanDialogOpen && (
        <UpgradePlanDialog
          open={showUpgradePlanDialogOpen}
          handleClose={() => setUpgradePlanDialogOpen(false)}
        />
      )}
      {loading ? (
        <LinearProgress sx={{ width: '100%' }} />
      ) : (
        <Typography
          variant="body1"
          sx={{
            padding: !isDataLoaded ? '1.25rem' : '0',
            wordBreak: 'break-word',
          }}
        >
          {!isDataLoaded &&
            'There are no tests available for the selected filter criteria. Try changing the filters.'}
        </Typography>
      )}
      <Grid container sx={{ p: '1rem' }} spacing={3}>
        {tests?.map((test) => (
          <Grid key={test.brokee_id} item xs={12} sm={6} md={6}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <CardContent sx={{ padding: '24px 16px 1px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '10px',
                    flex: '1 0 auto',
                  }}
                >
                  {test.featured_image && (
                    <img
                      src={test.featured_image}
                      alt={test.name}
                      loading="lazy"
                      style={{
                        width: '10rem',
                        height: '12rem',
                        objectFit: 'contain',
                        objectPosition: 'center',
                        margin: '1rem',
                      }}
                    />
                  )}
                </Box>
                <CardContent sx={{ padding: '32px 16px 0px !important' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'start',
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{ fontWeight: 'bold', mb: 2 }}
                    >
                      {test.name}
                    </Typography>
                    <Tooltip placement="top" title="More Description">
                      <IconButton
                        id="test-catalog-more-description"
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        onClick={(e) =>
                          handlePopoverOpen(e, test.description_human || '')
                        }
                      >
                        <Icon icon={infoIcon} />
                      </IconButton>
                    </Tooltip>
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Box sx={{ p: '1rem', maxWidth: '30rem' }}>
                        <Typography variant="body1">
                          {popoverContent || 'No additional description'}
                        </Typography>
                      </Box>
                    </Popover>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      minHeight: '73px',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {test.description_short}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center',
                      width: '100%',
                      gap: 1,
                      pt: 1,
                    }}
                  >
                    <Box sx={{ textTransform: 'capitalize' }}>
                      <Chip
                        label={test?.test_difficulty?.level}
                        sx={{
                          mr: 1,
                          padding: '2px 2px',
                          bgcolor:
                            test.test_difficulty?.level ===
                            Difficulty_levels.HARD
                              ? 'error.main'
                              : test.test_difficulty?.level ===
                                Difficulty_levels.EASY
                              ? 'success.main'
                              : 'warning.main',
                          fontWeight: 'bold',
                          color: 'common.white',
                        }}
                      />
                    </Box>
                    {test.dynamic_issues && (
                      <Tooltip
                        title="Each attempt generates a random set of problems"
                        placement="top"
                      >
                        <Chip color={'info'} label={'Dynamic 🎲'} />
                      </Tooltip>
                    )}
                  </Box>
                </CardContent>
              </CardContent>
              <CardActions
                sx={{
                  padding: '2px 2px 1px',
                  justifyContent: 'right',
                  mr: 2,
                }}
              >
                <ButtonGroup
                  size="small"
                  variant="outlined"
                  aria-label="Test Catalog Button Group"
                  sx={{ mt: 1 }}
                >
                  <Button
                    id="test-catalog-launch-test"
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      fontSize: '0.875rem',
                      fontWeight: 'medium',
                      minWidth: '140px',
                      borderColor: 'primary.main',
                      ':hover': {
                        backgroundColor: 'primary.main',
                        color: 'white',
                      },
                    }}
                    onClick={(e) => {
                      // e.stopPropagation();
                      handleLaunchTest(
                        test.brokee_id.toString(),
                        test?.requires_payment
                      );
                    }}
                  >
                    Launch Test
                  </Button>
                  <Button
                    id="test-catalog-test-details"
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      fontSize: '0.875rem',
                      fontWeight: 'medium',
                      minWidth: '140px',
                      borderColor: 'primary.main',
                      ':hover': {
                        backgroundColor: 'primary.main',
                        color: 'white',
                      },
                    }}
                    onClick={() => {
                      if (test?.brokee_id) {
                        handleNavigation(
                          test.brokee_id.toString(),
                          test?.requires_payment
                        );
                      }
                    }}
                  >
                    {'Learn More ->'}
                  </Button>
                </ButtonGroup>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {!loading && (
        <TablePagination
          component="div"
          count={total ?? 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
    </Paper>
  );
};

export default TestCatalog;
