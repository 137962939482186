import { Box, CircularProgress } from '@mui/material';

export default function CircularProgressPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <CircularProgress color="primary" size={160} thickness={1} />
    </Box>
  );
}
