import { ChangeEvent, FC } from 'react';
import { TextField } from '@mui/material';
import { Option } from '../interfaces';

const CustomFilter: FC<{
  label: string;
  value: string;
  defaultLabel?: string;
  defaultValue: string;
  onChange: (event: ChangeEvent<{ value: unknown }>) => void;
  options: Option[];
}> = ({ label, value, onChange, options, defaultValue, defaultLabel }) => {
  return (
    <TextField
      select
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      SelectProps={{
        native: true,
        displayEmpty: true,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    >
      {defaultValue !== '' && (
        <option value={defaultValue}>{defaultLabel}</option>
      )}
      {options.map(({ value: optionValue, label: optionLabel }) => (
        <option key={optionValue} value={optionValue}>
          {optionLabel}
        </option>
      ))}
    </TextField>
  );
};

export default CustomFilter;
