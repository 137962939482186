import { IconifyIcon } from '@iconify/types';

export interface IMenuOption {
  label: string;
  icon: IconifyIcon;
  linkTo: string;
}

export const MENU_OPTIONS: Array<IMenuOption> = [
  /* {
    label: 'Profile',
    icon: personFill,
    linkTo: '#',
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: '#',
  }, */
];
