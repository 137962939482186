import { Alert, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ESettingsTabs } from '../../company/SettingsPage/data';

export default function SubscriptionAlert({
  text = 'Unlock premium features.',
  features = '',
}) {
  const lines = features.split('\n').filter((line) => line.trim() !== '');
  return (
    <Alert severity="warning">
      {text}{' '}
      <Link
        id="subscription-alert"
        to={`/company/settings/${ESettingsTabs.subscription}`}
      >
        Subscribe now
      </Link>{' '}
      for an enhanced experience.
      {lines.length > 0 && (
        <Box sx={{ mt: 2 }}>
          {lines.map((line, index) => (
            <Typography
              key={index}
              variant="body2"
              component="p"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {index > 0 && (
                <Box component="span" sx={{ mr: 1 }}>
                  ✨
                </Box>
              )}
              {line.trim()}
            </Typography>
          ))}
        </Box>
      )}
    </Alert>
  );
}
