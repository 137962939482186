export function calculateTechnicalInterviewsSavedMoney(
  numberOfCandidates: number,
  technicalInterviewTime: number,
  interviewerCostPerHour: number
) {
  return numberOfCandidates * technicalInterviewTime * interviewerCostPerHour;
}

export function calculateAdditionalInterviewsSavedMoney(
  numberOfCandidates: number,
  totalPassedCandidates: number,
  additionalInterviewerCostPerHour: number,
  additionalInterviewTime: number
) {
  return (
    (numberOfCandidates - totalPassedCandidates) *
    additionalInterviewTime *
    additionalInterviewerCostPerHour
  );
}

export function calculateTechnicalInterviewsSavedTime(
  numberOfCandidates: number,
  technicalInterviewTime: number
) {
  return numberOfCandidates * technicalInterviewTime;
}

export function calculateAdditionalInterviewsSavedTime(
  numberOfCandidates: number,
  totalPassedCandidates: number,
  additionalInterviewTime: number
) {
  return (numberOfCandidates - totalPassedCandidates) * additionalInterviewTime;
}

export function calculateTotalSavedMoney(
  numberOfCandidates: number,
  totalPassedCandidates: number,
  technicalInterviewTime: number,
  technicalInterviewerCostPerHour: number,
  additionalInterviewTime: number,
  additionalInterviewerCostPerHour: number
) {
  return (
    calculateTechnicalInterviewsSavedMoney(
      numberOfCandidates,
      technicalInterviewTime,
      technicalInterviewerCostPerHour
    ) +
    calculateAdditionalInterviewsSavedMoney(
      numberOfCandidates,
      totalPassedCandidates,
      additionalInterviewerCostPerHour,
      additionalInterviewTime
    )
  );
}

export function calculateTotalSavedTime(
  numberOfCandidates: number,
  totalPassedCandidates: number,
  technicalInterviewTime: number,
  additionalInterviewTime: number
) {
  return (
    calculateTechnicalInterviewsSavedTime(
      numberOfCandidates,
      technicalInterviewTime
    ) +
    calculateAdditionalInterviewsSavedTime(
      numberOfCandidates,
      totalPassedCandidates,
      additionalInterviewTime
    )
  );
}
