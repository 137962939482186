import { Theme } from '@mui/material';

export default function Avatar(theme: Theme) {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0,
        },
      },
    },
  };
}
