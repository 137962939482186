import { CssBaseline } from '@mui/material';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '../../components/ThemeProvider';
import ApolloProvider from './ApolloProvider';
import AppRoutes from './AppRoutes';
import Auth0ProviderWithRedirectCallback from './Auth0ProviderWithRedirectCallback';
import ScrollToTop from './scrollToTop';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

ReactGA.initialize('UA-245001269-1');

export default function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
        redirectUri={window.location.origin}
      >
        <ApolloProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline />
                <AppRoutes />
              </LocalizationProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </ApolloProvider>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}
