import { useAuth0 } from '@auth0/auth0-react';
import { Icon } from '@iconify/react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Theme,
  Typography,
  alpha,
} from '@mui/material';
import { useRef, useState } from 'react';
import { getCompanyName } from '../../utils/auth';
import { MenuPopover } from '../MenuPopover';
import { MENU_OPTIONS } from './data';
import { LANDING_PAGE_URL } from '../../data';
import { isAdmin } from '../../utils/auth';
export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { logout, user } = useAuth0();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        id="account-popover"
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme: Theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar alt={user?.name} src={user?.picture} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <>
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {user?.name}
            </Typography>
            {isAdmin(user) && (
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}
                noWrap
              >
                {getCompanyName(user)}
              </Typography>
            )}
          </Box>

          <Divider sx={{ my: 1 }} />

          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              component={Link}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />

              {option.label}
            </MenuItem>
          ))}

          <Box sx={{ p: '0.5rem', pt: 1.5 }}>
            <Button
              fullWidth
              color="inherit"
              variant="text"
              id="account-popover-logout"
              onClick={() => {
                logout({
                  returnTo: LANDING_PAGE_URL,
                });
              }}
            >
              Logout
            </Button>
          </Box>
        </>
      </MenuPopover>
    </>
  );
}
