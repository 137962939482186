import { candidateWebsite } from '../../constants/links';

let candidateWebsiteUrl = candidateWebsite;
if (process.env.REACT_APP_NODE_ENV === 'development') {
  candidateWebsiteUrl = 'http://localhost:3000/';
}

export const ASSESSMENT_LINK_PREFIX = `${candidateWebsiteUrl}invitation-links/`;

export interface IAssessmentLinkResponse {
  'invitation-link': string;
  message: string;
}

export enum EAssessmentLinkStatus {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}
export const filterOptionByLinkStatus = [
  {
    value: EAssessmentLinkStatus.ACTIVE,
    label: 'Active',
  },
  {
    value: EAssessmentLinkStatus.ARCHIVE,
    label: 'Archive',
  },
];
export const iconStyle = {
  backgroundColor: 'primary.main',
  color: 'white',
  '&:hover': {
    borderColor: 'primary.dark',
    color: 'white',
    backgroundColor: 'primary.dark',
  },
};
