import { Alert, AlertProps, Snackbar } from '@mui/material';

interface IAssessmentAlertProps {
  open: boolean;
  duration?: number;
  severity?: string;
  variant?: string;
  text?: string;
  onClose: () => void;
}

const AssessmentAlert: React.FC<IAssessmentAlertProps & AlertProps> = ({
  open,
  duration = 6000,
  severity = 'error',
  variant = 'filled',
  text = 'Something went wrong while processing your request.',
  onClose,
  ...props
}) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ mt: '3rem' }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant={variant}
        sx={{
          mr: 1,
          width: '100%',
        }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};
export default AssessmentAlert;
