import CloudIcon from '@mui/icons-material/Cloud';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  EAssessmentCategoryType,
  EEnvironmentStatuses,
  EEnvironmentType,
} from '../../../constants/assessment';
import { useInfrastructure } from '../InfrastructureContext';
import { IAssessmentProps } from '../data';
import { getCloudConsoleLoginLink, splitCamelCaseToWords } from '../utils';

export default function EnvironmentSummary({ assessment }: IAssessmentProps) {
  const {
    isInfrastructureCreated,
    isInfrastructureCreating,
    infraLoading,
    infrastructure,
  } = useInfrastructure();

  const envType = assessment?.environment_type?.type;
  const assessmentType = assessment?.test_category?.name;

  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleCopyToClipboard = (value: string | undefined | null) => {
    navigator.clipboard.writeText(value ?? '');
    setShowCopyTooltip(true);
  };

  const handleTooltipClose = () => {
    setShowCopyTooltip(false);
  };
  const handleAccessCloud = () => {
    try {
      const consoleLink = getCloudConsoleLoginLink(
        assessment?.test_category?.name || '',
        infrastructure?.outputs
      );
      window.open(consoleLink, '_blank', 'noopener,noreferrer');
    } catch {
      setOpenSnackBar(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ mt: '3rem' }}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          No valid cloud console access link found
        </Alert>
      </Snackbar>
      <Card
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '1.3rem',
            }}
          >
            <Typography variant="h6" component="div">
              Environment Summary
            </Typography>
            {isInfrastructureCreated &&
              (envType === EEnvironmentType.CONSOLE ||
                assessmentType === EAssessmentCategoryType.TERRAFORM) && (
                <Button
                  id="access-cloud-console"
                  startIcon={<CloudIcon />}
                  variant="contained"
                  onClick={handleAccessCloud}
                >
                  Access Cloud Console
                </Button>
              )}
          </Box>
        </CardContent>
        <Divider />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'left',
            pl: '2rem',
            pr: '2rem',
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          {infraLoading && !isInfrastructureCreating ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                p: '1rem',
              }}
            >
              <CircularProgress />
            </Box>
          ) : isInfrastructureCreated &&
            infrastructure?.outputs &&
            Object.keys(infrastructure.outputs).length > 0 ? (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {Object.entries(infrastructure.outputs).map(
                ([key, value], index) => (
                  <React.Fragment key={key}>
                    <ListItem disableGutters>
                      <ListItemText
                        primaryTypographyProps={{ variant: 'subtitle1' }}
                        primary={`${splitCamelCaseToWords(key)}:`}
                        secondary={value as string}
                        sx={{
                          minWidth: '200px',
                          textAlign: 'left',
                          flex: '1 1 auto',
                        }}
                      />
                      <Tooltip
                        open={showCopyTooltip}
                        onClose={handleTooltipClose}
                        followCursor
                        placement="top"
                        title="Copied!"
                      >
                        <IconButton
                          aria-label="copy"
                          id="env-summary-copy-value"
                          onClick={() => handleCopyToClipboard(value as string)}
                          edge="start"
                          size="medium"
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                    {index < Object.keys(infrastructure.outputs).length - 1 && (
                      <Divider />
                    )}
                  </React.Fragment>
                )
              )}
            </List>
          ) : (
            <Typography
              variant="subtitle1"
              sx={{ textAlign: 'center', py: '2rem', width: '100%' }}
            >
              {isInfrastructureCreating
                ? EEnvironmentStatuses.CREATING
                : isInfrastructureCreated
                ? EEnvironmentStatuses.NO_SUMMARY
                : EEnvironmentStatuses.NONEXISTENT}
            </Typography>
          )}
        </Box>
      </Card>
    </Box>
  );
}
