import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { formatISO } from 'date-fns';
import { useMutation } from 'react-query';
import { useUpdateTestTrialsMutation } from '../../@generated/facadeClient';
import { TestTrialPartialFragment } from '../../@generated/types';

interface IDeleteDialogProps {
  testTrial: TestTrialPartialFragment;
  onClose(): void;
  onSuccess(message: string): void;
  onError(error: string): void;
}

export default function DeleteDialog({
  testTrial,
  onClose,
  onSuccess,
  onError,
}: IDeleteDialogProps) {
  const [updateTestTrial] = useUpdateTestTrialsMutation();

  const deleteCandidate = useMutation(
    async () => {
      await Promise.all([
        updateTestTrial({
          variables: {
            id: testTrial.id,
            set: { deleted_at: formatISO(new Date()) },
          },
        }),
      ]);
    },
    {
      onSuccess: () => {
        onSuccess(
          `Test ${testTrial.test.name} for user ${testTrial.candidate.email} successfully deleted!`
        );
      },
      onError: () => {
        onError(`Something went wrong. Please try again.`);
      },
    }
  );

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle>
        Test {testTrial.test.name} for user {testTrial.candidate.email}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="subtitle1">
          This action cannot be undone. Do you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="trial-cancel-deletion"
          onClick={onClose}
          variant="text"
          color="error"
        >
          Cancel
        </Button>
        <Button
          id="trial-delete"
          variant="contained"
          color="error"
          onClick={() => {
            deleteCandidate.mutate();
          }}
        >
          Yes, Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
