import { SvgIcon, SxProps, Theme } from '@mui/material';

import { ReactComponent as ShortLogoComponent } from '../../assets/logos/brokee_short.svg';
import { ReactComponent as LongLogoComponent } from '../../assets/logos/brokee_long.svg';

interface ILogoProps {
  sx?: SxProps<Theme>;
  size?: 'short' | 'long';
}
export default function Logo({ size = 'short', sx = {} }: ILogoProps) {
  if (size === 'short') {
    return (
      <SvgIcon
        sx={{
          width: 30,
          height: 30,
          display: 'block',
          ...sx,
        }}
        inheritViewBox
      >
        <ShortLogoComponent />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      sx={{
        width: 125,
        height: 30,
        display: 'block',
        color: 'primary.main',
        ...sx,
      }}
      inheritViewBox
    >
      <LongLogoComponent />
    </SvgIcon>
  );
}
