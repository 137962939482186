import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';

export default function LinearProgressWithLabel(
  props: LinearProgressProps & {
    available_resources: number;
    total_resources: number;
  }
) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <LinearProgress {...props} />
      </Box>

      <Box
        sx={{
          bgcolor: 'neutral.100',
          boxShadow: 3,
          borderRadius: 3,
          padding: 1,
          m: 1,
        }}
      >
        <Typography variant="subtitle2" color="text.secondary">
          Upgrading infrastructure. This process may take a few minutes.{' '}
          {`Resources: ${props.available_resources}/${props.total_resources}`}
        </Typography>
      </Box>
    </Box>
  );
}
