import { Routes, Route, Navigate } from 'react-router-dom';
import { TestsCatalouguePageList } from '../TestCatalogues/TestCataloguePageList';
import TestCatalogueDetailPage from './TestCatalogueDetail/TestCatalogueDetailPage';
import ProtectedRoute from './ProtectedRoutes';

export default function TestsCatalouguePage() {
  return (
    <Routes>
      <Route path="/" element={<TestsCatalouguePageList />} />
      <Route
        path="/test-details/:testId"
        element={
          <ProtectedRoute
            redirectPath="/company/tests-catalogue"
            children={<TestCatalogueDetailPage />}
          />
        }
      />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}
