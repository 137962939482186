import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Chip,
  Divider,
  Drawer,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { LANDING_PAGE_URL } from '../../data';
import { isCandidate } from '../../utils/auth';
import { Scrollbar } from '../Scrollbar';
import AccountIdentity from './AccountIdentity';
import NavItem, { INavItemProps } from './NavItem';
import SubscriptionChip from './SubscriptionChip';

export default function Sidebar({
  open,
  onClose,
  menuItems,
}: {
  open: boolean;
  onClose(): void;
  menuItems: INavItemProps[];
}) {
  const { user } = useAuth0();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
        '& .simplebar-scrollbar:before': {
          background: 'neutral.400',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: '10px',
            }}
          >
            <div>
              <Typography color="inherit" variant="subtitle1">
                <Link
                  to={LANDING_PAGE_URL}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  Brokee
                </Link>{' '}
                {isCandidate(user) ? (
                  <Chip
                    label="Invitee"
                    variant="filled"
                    color="primary"
                    size="small"
                  />
                ) : (
                  <SubscriptionChip />
                )}
              </Typography>
              <AccountIdentity />
            </div>
          </Box>
        </Box>
        <Divider sx={{ borderColor: 'neutral.700' }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3,
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0,
            }}
          >
            {menuItems.map((item) => (
              <NavItem key={item.title} {...item} />
            ))}
          </Stack>
        </Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: 'common.white',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: 'common.white',
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}
