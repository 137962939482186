import { useAuth0 } from '@auth0/auth0-react';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { AssessmentAlert } from '../../../components/AssessmentAlert';
import { EAccountTypeIds, EAccountTypes } from '../../../constants/accounts';
import { ECompanyRoles, ECompanyRolesIds } from '../../../constants/roles';
import { upgradeToPersonalAccount } from '../utils';

export default function PersonalAccountUpgrade() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleUpgrade = async () => {
    setLoading(true);
    const accountDetails = {
      account_type_id: EAccountTypeIds.personal,
      account_type: EAccountTypes.personal,
      auth0_id: user?.sub || '',
      email: user?.email || '',
      company_role_id: ECompanyRolesIds.admin,
      company_role: ECompanyRoles.admin,
      company_name: user?.nickname || '',
    };

    try {
      const accessToken = await getAccessTokenSilently();
      await upgradeToPersonalAccount(accessToken, accountDetails);

      window.location.reload();
    } catch (err) {
      setSnackbarOpen(true);
      setLoading(false);
      Bugsnag.notify(err as NotifiableError);
    }
  };
  return (
    <>
      {loading && <LinearProgress />}
      <Grid
        container
        sx={{
          mt: '1rem',
          mb: '2rem',
        }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap-reverse',
          }}
        >
          <Typography variant="h3" display="block">
            Activate Personal Account Features
          </Typography>
          <Button
            id="upgrade-to-personal-account"
            variant="contained"
            disabled={loading}
            onClick={handleUpgrade}
          >
            {loading ? (
              <>
                <CircularProgress size={20} thickness={1} sx={{ mr: 0.3 }} />
                Upgrading
              </>
            ) : (
              'Upgrade now'
            )}
          </Button>
        </Grid>
        <Alert
          sx={{
            my: '1.5rem',
            p: '1.5rem',
            fontSize: '0.95rem',
            borderRadius: 2,
          }}
          severity="success"
        >
          <AlertTitle>
            Upgrade to Personal Account — <strong>It's Free and Fast!</strong>
          </AlertTitle>
          Unlock full access to our platform with a personal account, including
          a few free credits to explore new features and take more tests. The
          upgrade process is quick and easy, taking just a few seconds. You'll
          be able to track your progress, train without limits, and discover the
          skills that matter most for your next steps. Ready to upgrade? Start
          your journey today by clicking the button above!
        </Alert>
      </Grid>
      <AssessmentAlert
        text={
          'Upgrade Failed. Please try again later or contact support in the chat bellow.'
        }
        open={snackbarOpen}
        severity={'error'}
        variant={'filled'}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}
