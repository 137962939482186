import { User } from '@auth0/auth0-react';
import { EUserRoles, ECompanyRoles } from '../constants/roles';

const AUTH_KEYS = {
  appMetadata: 'https://brokee.io/app_metadata',
  userMetadata: 'https://brokee.io/user_metadata',
  roles: 'https://brokee.io/roles',
  testId: 'https://brokee.io/test_id',
};

export const getAppMetadata = (user: User | undefined) =>
  user?.[AUTH_KEYS.appMetadata];

export const getUserMetaData = (user: User | undefined) =>
  user?.[AUTH_KEYS.userMetadata];

export function isPersonal(user: User | undefined) {
  return getUserMetaData(user)?.account_type === EUserRoles.personal;
}

export const getCompanyId = (user: User | undefined) =>
  getAppMetadata(user)?.company_id ?? getUserMetaData(user)?.company_id;

export const getCompanyName = (user: User | undefined) =>
  getAppMetadata(user)?.company_name ?? getUserMetaData(user)?.company_name;

export const getUserHash = (user: User | undefined) =>
  getAppMetadata(user)?.userhash ?? getUserMetaData(user)?.userhash;

export const getUserTerminalSecret = (user: User | undefined) =>
  getAppMetadata(user)?.terminal_secret ??
  getUserMetaData(user)?.terminal_secret;

export const getRoles = (user: User | undefined) => user?.[AUTH_KEYS.roles];

export const isCompany = (user: User | undefined) =>
  getRoles(user)?.includes(EUserRoles.company) && !isPersonal(user);

export const isAdmin = (user: User | undefined) => {
  return (
    getUserMetaData(user)?.company_role === ECompanyRoles.admin ||
    getAppMetadata(user)?.company_role === ECompanyRoles.admin
  );
};

export function isCandidate(user: User | undefined) {
  return !isAdmin(user);
}
