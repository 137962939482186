import { Card, CardContent, Grid, Typography } from '@mui/material';
import FailedTestCard from '../TestCards/FailedTestCard';
import { useFailedTestsPerAccountQuery } from '../../../@generated/facadeClient';

export default function FailedTestsOverview() {
  const failedTests = useFailedTestsPerAccountQuery();
  const failedTestsData = failedTests.data?.tests;

  const topFailedTests = failedTestsData
    ?.slice()
    .sort((a, b) => {
      const countA = a.failed_test_trials_aggregate.aggregate?.count || 0;
      const countB = b.failed_test_trials_aggregate.aggregate?.count || 0;
      return countB - countA;
    })
    .slice(0, 3);

  const tests = topFailedTests?.map((test) => ({
    id: test.brokee_id,
    name: test.name,
    icon: test.featured_image,
    attempts: test.failed_test_trials_aggregate.aggregate?.count,
    completionTime:
      test.failed_test_trials_aggregate.aggregate?.avg?.time_taken_seconds,
    description: test.description_short,
    requires_payment: test.requires_payment,
  }));

  return (
    <Card elevation={3}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" display="block">
              Failed Assessments Overview
            </Typography>
            <Typography
              variant="subtitle1"
              display="block"
              sx={{ mb: '2rem', color: 'gray' }}
            >
              See where your candidates struggle the most.
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {tests?.map(
              (test) =>
                (test.attempts ?? 0) >= 1 && (
                  <Grid item xs={12} sm={6} md={4} key={test.id}>
                    <FailedTestCard test={test} />
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
