import { useAuth0 } from '@auth0/auth0-react';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import { useEffect, useState } from 'react';

export default function useAccessToken() {
  const [idToken, setIdToken] = useState<string | null>(null);
  const { user, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
        });
        setIdToken(accessToken);
      } catch (e) {
        console.log(e);
        Bugsnag.notify(e as NotifiableError);
      }
    };

    // getAccessToken();

    if (!isLoading && user) {
      getAccessToken();
    }
  }, [isLoading, getAccessTokenSilently, user]);
  // }, [getAccessTokenSilently, user?.sub]);

  return idToken;
}
