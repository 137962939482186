import { ArrowForward } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Slider,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { calculateProblemsThreshold } from '../../utils/common';
interface ProblemSelectionSliderProps {
  defaultProblemsAmount: number;
  onSubmit: (selectedProblems: number) => Promise<void>;
}

const ProblemSelectionSlider: React.FC<ProblemSelectionSliderProps> = ({
  defaultProblemsAmount,
  onSubmit,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [selectedProblems, setSelectedProblems] = useState<number>(1);
  const { minThreshold, maxThreshold } = calculateProblemsThreshold(
    defaultProblemsAmount
  );

  const handleChange = (event: Event, newValue: number | number[]) => {
    setSelectedProblems(newValue as number);
  };

  const handleSubmit = () => {
    setSubmitting(true);
    onSubmit(selectedProblems);
  };

  const difficultyMarks = [
    { value: minThreshold, label: 'Minimum' },
    { value: maxThreshold, label: 'Maximum' },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        p: 1.5,
      }}
    >
      <Card
        sx={{
          width: '100%',
          maxWidth: 800,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: 3,
          borderRadius: 2,
          p: 1.75,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '1rem',
              width: '100%',
            }}
          >
            <Typography variant="h6" component="div" gutterBottom>
              Problems Selection
              <Tooltip
                arrow
                title="Adjusting the slider will not change the difficulty level, but it will specify the number of problems to solve. All selected problems will be available for re-running."
                placement="right"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'zIndex',
                      enabled: true,
                      phase: 'afterWrite',
                      fn: ({ state }) => {
                        state.styles.popper.zIndex = '9999';
                      },
                    },
                  ],
                }}
              >
                <IconButton size="small" sx={{ ml: 1 }}>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <IconButton
              id="problem-selection-submit"
              aria-label="submit"
              size="small"
              disabled={submitting}
              onClick={handleSubmit}
              sx={{ border: 0.9 }}
            >
              <ArrowForward />
            </IconButton>
          </Box>
          {submitting && <LinearProgress />}
          <Typography variant="body1" sx={{ mt: 1 }}>
            This assessment allows you to select the number of problems. Adjust
            the slider to choose how many issues you'd like to troubleshoot.
          </Typography>
          <Slider
            defaultValue={defaultProblemsAmount}
            aria-label="Number of Problems"
            valueLabelDisplay="auto"
            step={1}
            marks={difficultyMarks}
            min={minThreshold}
            max={maxThreshold}
            sx={{ mt: 1 }}
            onChange={handleChange}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProblemSelectionSlider;
