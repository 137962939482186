import { Navigate, useParams } from 'react-router-dom';
import useGetIsAuthorizedToAccessTest from './hooks/useGetIsAuthorizedToAccessTest';
import { FC, ReactNode } from 'react';

interface ProtectedRouteProps {
  children: ReactNode;
  redirectPath?: string;
}
const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  redirectPath = '/company/tests-catalogue',
}) => {
  const { testId } = useParams();
  const [isAuthorized] = useGetIsAuthorizedToAccessTest(Number(testId));
  if (isAuthorized === null) {
    return null;
  }
  return isAuthorized ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectPath} replace />
  );
};
export default ProtectedRoute;
