import {
  Grid,
  Box,
  SvgIcon,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { IconifyIcon } from '@iconify/types';

export interface ICandidatesCountCardProps {
  count: number;
  label: string;
  bgcolor: string;
  icon: IconifyIcon;
  loading: boolean;
}

export default function CandidatesCountCard({
  count,
  label,
  bgcolor,
  icon,
  loading,
}: ICandidatesCountCardProps) {
  if (loading) {
    return (
      <Box
        sx={{
          backgroundColor: bgcolor,
          borderRadius: '20px',
          p: '2rem 1.5rem',
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: bgcolor,
        borderRadius: '20px',
        p: '2rem 1.5rem',
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={3} sm={5} md={3}>
          <Box
            sx={{
              width: '3rem',
              height: '3rem',
            }}
          >
            <SvgIcon sx={{ width: '100%', height: '100%' }}>
              <Icon icon={icon} />
            </SvgIcon>
          </Box>
        </Grid>
        <Grid item xs={9} sm={6} md={9}>
          <Typography
            variant="body2"
            display="block"
            sx={{ color: 'text.secondary', lineHeight: 1.57 }}
          >
            {label}
          </Typography>
          <Typography variant="h5" display="block" sx={{ lineHeight: 1.2 }}>
            {count}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
