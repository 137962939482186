import { PaletteOptions, alpha } from '@mui/material';

export const neutral = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927',
};

export default {
  neutral,
  action: {
    active: neutral[500],
    disabled: alpha(neutral[900], 0.38),
    disabledBackground: alpha(neutral[900], 0.12),
    focus: alpha(neutral[900], 0.16),
    hover: alpha(neutral[900], 0.04),
    selected: alpha(neutral[900], 0.12),
  },
  background: {
    default: '#F9FAFC',
    paper: '#FFFFFF',
  },
  divider: '#E6E8F0',
  primary: {
    main: '#009c78',
    light: '#52cea7',
    dark: '#006d4c',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#ffd600',
    light: '#ffff52',
    dark: '#c7a500',
    contrastText: '#000000',
  },
  success: {
    light: '#3FC79A',
    main: '#10B981',
    dark: '#0B815A',
    contrastText: '#FFFFFF',
  },
  info: {
    light: '#9778CE',
    main: '#7E57C2',
    dark: '#583C87',
    contrastText: '#FFFFFF',
  },
  warning: {
    light: '#FEF0C7',
    main: '#F79009',
    dark: '#B54708',
    contrastText: '#FFFFFF',
  },
  error: {
    light: '#FEE4E2',
    main: '#F04438',
    dark: '#B42318',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: neutral[900],
    secondary: neutral[500],
    disabled: alpha(neutral[900], 0.38),
  },
} as PaletteOptions;
