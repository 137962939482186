import { useAuth0 } from '@auth0/auth0-react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Edit } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import {
  useGetPrivateTestsQuery,
  useSubscribeAssessmentLinksByFilterSubscription,
  useSubscribeAssessmentLinksCountByFilterSubscription,
  useUpdateAssessmentLinksByIdMutation,
} from '../../@generated/facadeClient';
import {
  AssessmentLinksPartialFragment,
  ProblemSetPartialFragment,
} from '../../@generated/types';
import { SubscriptionAlert } from '../../components/SubscriptionAlert';
import { ESubscriptionFeaturesList } from '../../constants/features';
import { useBilling } from '../../hooks/useBilling';
import { getCompanyId, getUserMetaData } from '../../utils/auth';
import { isValidLimit, isValidPage } from '../CandidatesPage/utils';
import { defaultLimit, defaultPage } from '../SettingsPage/data';
import CreateInvitationLinkDialog from './CreateInvitationLinkDialog';
import { ProblemList } from './ProblemList';
import {
  ASSESSMENT_LINK_PREFIX,
  EAssessmentLinkStatus,
  IAssessmentLinkResponse,
  filterOptionByLinkStatus,
} from './data';

export default function InvitationLinksPage() {
  const { user } = useAuth0();
  const { canAccessProFeatures, hasActiveFreeTrial } = useBilling();
  const companyId = getCompanyId(user);

  const [searchParams, setSearchParams] = useSearchParams({
    filterByTest: 'All',
    filterByLinkStatus: EAssessmentLinkStatus.ACTIVE,
    page: defaultPage.toString(),
    limit: defaultLimit.toString(),
  });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [testIssues, setTestIssues] = useState<
    ProblemSetPartialFragment['test_issues']
  >([]);
  const [testName, setTestName] = useState('');
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editingLink, setEditingLink] = useState<
    AssessmentLinksPartialFragment | undefined
  >(undefined);

  const page = isValidPage(Number(searchParams.get('page')))
    ? Number(searchParams.get('page'))
    : defaultPage;

  const limit = isValidLimit(Number(searchParams.get('limit')))
    ? Number(searchParams.get('limit'))
    : defaultLimit;

  const LinkStatus = searchParams.get(
    'filterByLinkStatus'
  ) as EAssessmentLinkStatus;

  const filterByTest = searchParams.get('filterByTest') || '';
  const { data: { tests } = { tests: [] } } = useGetPrivateTestsQuery({
    variables: { company_id: companyId },
  });

  const selectedTestFilterId =
    tests.find((i) => i.name === filterByTest)?.brokee_id.toString() || 'All';

  const filterWrapper = {
    filter: {
      company_id: { _eq: getUserMetaData(user)?.company_id },
      is_active: {
        _eq: LinkStatus === EAssessmentLinkStatus.ACTIVE ? true : false,
      },
      ...(selectedTestFilterId === 'All'
        ? {}
        : {
            test_id: {
              _eq: Number(selectedTestFilterId),
            },
          }),
    },
  };

  const { data: linksCount } =
    useSubscribeAssessmentLinksCountByFilterSubscription();
  const { data: linksDataAggregate } =
    useSubscribeAssessmentLinksCountByFilterSubscription({
      variables: filterWrapper,
    });
  const { data: linksData, loading } =
    useSubscribeAssessmentLinksByFilterSubscription({
      variables: {
        filter: filterWrapper.filter,
        limit: limit,
        offset: limit * page,
      },
    });

  const totalLinks =
    linksDataAggregate?.assessment_links_aggregate?.aggregate?.count ?? 0;
  const [updateAssessmentLinksById] = useUpdateAssessmentLinksByIdMutation();

  const hasLinks = useMemo(
    () =>
      linksCount?.assessment_links_aggregate?.aggregate?.count &&
      linksCount?.assessment_links_aggregate?.aggregate?.count > 0,
    [linksCount]
  );

  const handleCopyToClipboardURL = async (url: string | undefined | null) => {
    await navigator.clipboard.writeText(url ?? '');
    setShowTooltip(true);
  };

  const handleStatusChange = async (
    link: AssessmentLinksPartialFragment,
    checked: boolean
  ) => {
    await updateAssessmentLinksById({
      variables: {
        id: link.id,
        is_active: checked,
      },
    });
  };

  const handleCreateDialogOpen = () => {
    setShowCreateDialog(true);
  };

  const handleEditDialogOpen = (link: AssessmentLinksPartialFragment) => {
    setEditingLink(link);
    setShowEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setShowEditDialog(false);
    setEditingLink(undefined);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleCreateDialogClose = () => {
    setShowCreateDialog(false);
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const handleClick = (
    testIssues: ProblemSetPartialFragment['test_issues'],
    testName = '',
    issueList: number[]
  ) => {
    const filteredTestIssues = testIssues.filter((issue) =>
      issueList.find((id) => id === issue.issue_id)
    );
    setTestIssues(filteredTestIssues);
    setTestName(testName);
    setOpenModal(true);
  };

  const handleIssueClose = () => {
    setOpenModal(false);
  };

  const handleSuccess = ({ message }: IAssessmentLinkResponse) => {
    handleCreateDialogClose();
    setShowSnackbar(true);
    setSeverity('success');
    setMessage(message);
  };

  const handleEditSuccess = () => {
    handleEditDialogClose();
    setShowSnackbar(true);
    setSeverity('success');
    setMessage('Invitation link updated successfully!');
  };

  const handleError = (error: string) => {
    handleCreateDialogClose();
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(error);
  };
  const handlePageChange = (event: unknown, newPage: number) => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set('page', newPage.toString());
      return prevSearchParams;
    });
  };

  const handleLimitChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newLimit = parseInt(event.target.value, 10);
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set('limit', newLimit.toString());
      return prevSearchParams;
    });
  };

  const handleFilterByTestChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => {
      prev.set('filterByTest', event.target.value);
      prev.set('limit', defaultLimit.toString());
      prev.set('page', defaultPage.toString());
      return prev;
    });
  };
  const handleFilterByLinkStatusChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSearchParams((prev) => {
      prev.set('filterByLinkStatus', event.target.value);
      prev.set('page', defaultPage.toString());
      prev.set('limit', defaultLimit.toString());
      return prev;
    });
  };

  useEffect(() => {
    if (
      !isValidPage(Number(searchParams.get('page'))) ||
      !isValidLimit(Number(searchParams.get('limit')))
    ) {
      setSearchParams((prev) => {
        !isValidPage(Number(searchParams.get('page'))) &&
          prev.set('page', defaultPage.toString());
        !isValidLimit(Number(searchParams.get('limit'))) &&
          prev.set('limit', defaultLimit.toString());
        return prev;
      });
    }
  }, [searchParams, setSearchParams]);

  return (
    <>
      {!canAccessProFeatures() && (
        <Grid item xs={12}>
          <SubscriptionAlert
            features={ESubscriptionFeaturesList.INVITATION_LINK_PAGE}
          />
        </Grid>
      )}
      <Grid
        container
        sx={{
          mt: '1rem',
          mb: '2rem',
        }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap-reverse',
          }}
        >
          <Typography variant="h3" display="block">
            Invitation Links
          </Typography>
          <Button
            id="invitation-link-create"
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleCreateDialogOpen}
            disabled={!canAccessProFeatures()}
          >
            Create Link
          </Button>
        </Grid>
      </Grid>
      {!hasLinks && !loading && (
        <Alert sx={{ my: '1rem' }} severity="info">
          <AlertTitle>Speed up your hiring with Invitation Links!</AlertTitle>
          Easily generate a single link for your test, inviting multiple
          candidates at once. Say goodbye to individual invites and hello to
          efficient hiring. Create your first link now for seamless candidate
          invitations.
        </Alert>
      )}
      {hasLinks ? (
        <Paper elevation={3}>
          <PerfectScrollbar>
            <Grid container sx={{ p: '2rem' }} spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Test Name"
                  value={filterByTest}
                  onChange={handleFilterByTestChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value={'All'}>All</option>
                  {tests
                    ?.filter(({ requires_payment }) =>
                      hasActiveFreeTrial() ? !requires_payment : true
                    )
                    .map(({ brokee_id, name }) => (
                      <option key={`challenge${brokee_id}`} value={name}>
                        {name}
                      </option>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Status"
                  value={LinkStatus}
                  onChange={handleFilterByLinkStatusChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {filterOptionByLinkStatus?.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Table sx={{ minWidth: 600 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Job Title</TableCell>
                  <TableCell>Test</TableCell>
                  <TableCell align="center">Invites Accepted</TableCell>
                  <TableCell align="center">Tests Taken</TableCell>
                  <TableCell align="center">Max Candidates</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Expires At</TableCell>
                  <TableCell align="center">Active</TableCell>
                  <TableCell align="center">Test Problems</TableCell>
                  <TableCell align="center" colSpan={3}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {linksData?.assessment_links.map(
                  (link: AssessmentLinksPartialFragment) => {
                    const {
                      id,
                      job_title,
                      test,
                      link_id,
                      max_applicants,
                      created_at,
                      is_active,
                      expires_at,
                      test_trials_aggregate: { aggregate },
                      number_of_candidates_aggregate,
                      test_issues,
                      issues_list,
                    } = link;

                    const linkUrl = link_id
                      ? `${ASSESSMENT_LINK_PREFIX}${link_id}`
                      : 'N/A';
                    const candidatePageUrl = `/company/candidates/${link_id}`;
                    const testUrl = `/company/tests-catalogue/test-details/${test?.brokee_id}`;
                    return (
                      <TableRow hover key={`link${id}`}>
                        <TableCell>
                          <Link
                            component={RouterLink}
                            to={candidatePageUrl}
                            variant="subtitle2"
                          >
                            {job_title}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            component={RouterLink}
                            to={testUrl}
                            variant="subtitle2"
                          >
                            {test?.name}
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          {number_of_candidates_aggregate.aggregate?.count ??
                            ''}
                        </TableCell>
                        <TableCell align="center">
                          {aggregate?.count ?? ''}
                        </TableCell>
                        <TableCell align="center">
                          {max_applicants ?? '∞'}
                        </TableCell>
                        <TableCell>
                          {created_at && format(parseISO(created_at), 'PP')}
                        </TableCell>
                        <TableCell>
                          {(expires_at && format(parseISO(expires_at), 'PP')) ??
                            '∞'}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: is_active ? 'success.main' : 'error.main',
                          }}
                          align="center"
                        >
                          <Tooltip
                            title={
                              is_active
                                ? 'Deactivating the link archives it, making it unavailable, while still allowing history review via archived links'
                                : 'This link is archived'
                            }
                          >
                            <Switch
                              disabled={!is_active}
                              checked={
                                LinkStatus === EAssessmentLinkStatus.ACTIVE &&
                                !!is_active
                              }
                              onChange={(event, checked: boolean) => {
                                handleStatusChange(link, checked);
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {test?.dynamic_issues &&
                            issues_list &&
                            issues_list.length > 0 && (
                              <Button
                                id="invitation-link-show-problem-list"
                                disabled={!is_active}
                                variant="outlined"
                                size="small"
                                onClick={() =>
                                  handleClick(
                                    test_issues,
                                    test?.name,
                                    issues_list
                                  )
                                }
                                sx={{ whiteSpace: 'nowrap', p: '0 0.5rem' }}
                              >
                                Show Problems
                              </Button>
                            )}
                          {test?.dynamic_issues &&
                            (!issues_list || issues_list.length === 0) && (
                              <Button
                                variant="outlined"
                                size="small"
                                disabled
                                sx={{ whiteSpace: 'nowrap', p: '0 0.5rem' }}
                              >
                                Random
                              </Button>
                            )}
                          {!test?.dynamic_issues && (
                            <Button
                              variant="outlined"
                              size="small"
                              disabled
                              sx={{ whiteSpace: 'nowrap', p: '0 0.5rem' }}
                            >
                              Static
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip
                            open={showTooltip}
                            onClose={handleTooltipClose}
                            followCursor
                            placement="top"
                            title="Copied!"
                          >
                            <IconButton
                              id="invitation-link-copy-url"
                              disabled={!is_active}
                              size="small"
                              onClick={() => handleCopyToClipboardURL(linkUrl)}
                            >
                              <ContentCopyIcon fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            id="invitation-link-edit-action"
                            disabled={!is_active}
                            size="small"
                            onClick={() => handleEditDialogOpen(link)}
                          >
                            <Edit fontSize="medium" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          {loading && <LinearProgress sx={{ width: '100%' }} />}
          {!totalLinks && !loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 2,
                color: 'text.secondary',
                bgcolor: 'background.neutral',
              }}
            >
              <Typography variant="body1">No link found!</Typography>
            </Box>
          )}
          {!loading && (
            <TablePagination
              component="div"
              count={totalLinks}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          )}
        </Paper>
      ) : null}
      {showCreateDialog && (
        <CreateInvitationLinkDialog
          handleClose={handleCreateDialogClose}
          onSuccess={handleSuccess}
          onEditSuccess={handleEditSuccess}
          onError={handleError}
        />
      )}
      {showEditDialog && (
        <CreateInvitationLinkDialog
          linkData={editingLink}
          handleClose={handleEditDialogClose}
          onSuccess={handleSuccess}
          onEditSuccess={handleEditSuccess}
          onError={handleError}
        />
      )}
      {showSnackbar && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            variant="filled"
            onClose={handleSnackbarClose}
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openModal}
        onClose={handleIssueClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
        PaperProps={{
          style: {
            padding: '16px',
            maxHeight: '80vh',
            overflowY: 'auto',
          },
        }}
      >
        <ProblemList testName={testName} testIssues={testIssues} />
      </Dialog>
    </>
  );
}
