export enum EAccountTypeIds {
  company = 1,
  personal = 2,
  invited = 3,
}

export enum EAccountTypes {
  company = 'company',
  personal = 'personal',
  invited = 'invited',
}
export const AccountTypeToIdMap: Record<EAccountTypes, EAccountTypeIds> = {
  [EAccountTypes.company]: EAccountTypeIds.company,
  [EAccountTypes.personal]: EAccountTypeIds.personal,
  [EAccountTypes.invited]: EAccountTypeIds.invited,
};
export const getAccountTypeId = (
  accountType: EAccountTypes
): EAccountTypeIds => {
  if (accountType && accountType in AccountTypeToIdMap) {
    return AccountTypeToIdMap[accountType];
  }
  return EAccountTypeIds.company;
};
