export interface Breakdown {
  tier: string;
  unit_amount: number;
  quantity: number;
  total_price: number;
}

export interface PriceItem {
  currency: string;
  breakdown: Breakdown[];
  order: number;
  total_price: number;
}
export interface EstimatedExpenseCardProps {
  planName: string;
  priceItem: PriceItem;
}
export interface EstimatedExpenseResult {
  [key: string]: PriceItem[];
}
interface SliderMarks {
  value: number;
  label: string;
}

export interface CostEstimatorProps {
  minTests?: number;
  maxTests?: number;
  testStep?: number;
}

export const generateCostSliderMarks = (
  min: number,
  max: number,
  step: number
): SliderMarks[] => {
  const marks: SliderMarks[] = [];
  let current = min;

  while (current <= max) {
    marks.push({
      value: current,
      label: `${current} credit${current === 1 ? '' : 's'}`,
    });
    current = current < step ? step : current + step;
  }

  return marks;
};
