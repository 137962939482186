import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IModalProps } from '../../interfaces';

const UpgradePlanDialog: FC<IModalProps> = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/company/settings/subscription');
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '1rem',
          width: '100%',
          maxWidth: '500px',
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
        Upgrade Your Plan
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Upgrade your account to unlock additional features.
        </DialogContentText>
      </DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
        <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            id="test-catalog-upgrade-plan-close"
            sx={{ color: 'red' }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
        <DialogActions>
          <Button id="test-catalog-upgrade-plan" onClick={handleNavigate}>
            Upgrade Now
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UpgradePlanDialog;
