import { useAuth0 } from '@auth0/auth0-react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useState } from 'react';
import { useGetTransactionsQuery } from '../../../@generated/facadeClient';
import { getUserMetaData } from '../../../utils/auth';
import { defaultPage } from '../data';
import TransactionsTable from './TransactionsTable';

export default function Transactions() {
  const { user } = useAuth0();
  const [page, setPage] = useState(defaultPage);
  const [limit, setLimit] = useState(5);
  const { data, loading } = useGetTransactionsQuery({
    variables: {
      company_id: getUserMetaData(user)?.company_id,
      limit: limit,
      offset: limit * page,
    },
  });

  const transactions = data?.transactions ?? [];
  const total = data?.transactions_aggregate?.aggregate?.count ?? 0;

  const handleLimitChange = (limitBy: number) => {
    setLimit(limitBy);
  };

  const handlePageChange = (pageBy: number) => {
    setPage(pageBy);
  };

  return (
    <Card elevation={3}>
      <CardHeader title="Invoice History" />
      <CardContent>
        <TransactionsTable
          transactions={transactions}
          loading={loading}
          onChangeLimit={handleLimitChange}
          onChangePage={handlePageChange}
          page={page}
          limit={limit}
          total={total}
        />
      </CardContent>
    </Card>
  );
}
