import trophy from '@iconify/icons-twemoji/trophy';
import { Icon } from '@iconify/react';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { ClaimBadgeProps } from './data';

export default function ClaimBadge({
  available = false,
  badgeName,
  onAction,
}: ClaimBadgeProps) {
  const buttonName = available ? 'Copy Public Link' : 'Claim My Badge';
  return (
    <Box
      sx={{
        borderRadius: '20px',
        p: '1.5rem',
        overflow: 'hidden',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <SvgIcon
        sx={{ width: '4.5rem', height: '4.5rem', marginBottom: '0.5rem' }}
      >
        <Icon icon={trophy} />
      </SvgIcon>
      <Typography variant="h6" gutterBottom>
        New Achievement Unlocked!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Congratulations! You've earned the {badgeName} badge for your
        performance.
      </Typography>
      <Button
        id="claim-badge"
        variant="text"
        onClick={onAction}
        sx={{
          border: 1,
          color: 'transparent',
          position: 'relative',
          borderColor: 'black',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            color: 'inherit',
          },
          '&::before': {
            content: `"${buttonName}"`,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'black',
            pointerEvents: 'none',
          },
        }}
      >
        {buttonName}
      </Button>
    </Box>
  );
}
