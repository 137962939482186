import { useAuth0 } from '@auth0/auth0-react';
import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import TrendingTestCard from '../TestCards/TrendingTestCard';
import { getTopRunningTests } from '../utils';

export default function TrendingTestsOverview() {
  const { getAccessTokenSilently } = useAuth0();
  const [trendingTests, setTrendingTests] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const effectRan = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const tests = await getTopRunningTests(accessToken, { limit: 3 });
        setTrendingTests(tests);
      } catch (err) {
        setError('Failed to fetch trending tests.');
      } finally {
        setLoading(false);
      }
    };

    if (!effectRan.current) {
      fetchData();
      effectRan.current = true;
    }
  }, [getAccessTokenSilently]);

  if (loading) return <LinearProgress />;
  if (error) return <Typography>Error: {error}</Typography>;

  return (
    <Card elevation={3}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" display="block">
              Check Out Trending Assessments
            </Typography>
            <Typography
              variant="subtitle1"
              display="block"
              sx={{ mb: '2rem', color: 'gray' }}
            >
              Discover highly recommended tests globally!
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {trendingTests.map((test) => (
              <Grid item xs={12} sm={6} md={4} key={test.BrokeeID}>
                <TrendingTestCard test={test} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
