import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import { Icon } from '@iconify/react';
import { Typography, Grid, Stack, Button } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButtonProps } from '../../interfaces';

export const BackNavigationButton: FC<BackButtonProps> = ({
  navigationName,
  navigationPath,
  state,
}) => {
  const navigate = useNavigate();
  return (
    <Grid item xs={12} sm={4}>
      <Stack sx={{ pt: '2rem', pb: '2rem' }}>
        <Button
          id="test-catalog-return-back"
          variant="text"
          sx={{
            display: 'flex',
            width: 'fit-content',
            alignItems: 'start',
            textDecoration: 'none',
            color: 'text.primary',
            '&:hover': {
              color: 'primary.main',
              boxShadow: 'none',
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
          onClick={() => navigate(`${navigationPath}`, { state: { ...state } })}
        >
          <Icon
            icon={arrowBackFill}
            width={24}
            height={24}
            style={{ marginRight: '.5rem' }}
          />
          <Typography variant={'subtitle2'} fontWeight={500}>
            {navigationName}
          </Typography>
        </Button>
      </Stack>
    </Grid>
  );
};
