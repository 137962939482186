import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { EBadgesStatuses } from '../../constants/common';
import { claimBadge, UseClaimBadgeProps, UseClaimBadgeReturn } from './data';

interface ErrorResponse {
  errorCode?: string;
}
export default function useClaimBadge({
  badgeId,
  recipientEmail,
  testId,
  userId,
}: UseClaimBadgeProps): UseClaimBadgeReturn {
  const { getAccessTokenSilently } = useAuth0();
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [alertOpen, setAlertOpen] = useState(false);

  const { isLoading, mutate } = useMutation(
    async () => {
      const token = await getAccessTokenSilently();
      await claimBadge(token, {
        badge_id: badgeId,
        recipient_email: recipientEmail,
        test_id: testId,
        user_id: userId,
      });
    },
    {
      onSuccess: () => {
        setAlertMessage(EBadgesStatuses.AWARDED);
        setAlertSeverity('success');
        setAlertOpen(true);
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errorCode === 'PREVIOUSLY_AWARDED'
        ) {
          setAlertMessage(EBadgesStatuses.PREVIOUSLY_AWARDED);
        } else {
          setAlertMessage(EBadgesStatuses.ERROR);
        }
        setAlertSeverity('error');
        setAlertOpen(true);
      },
    }
  );

  const handleClaimAlertClose = () => {
    setAlertOpen(false);
  };

  return {
    claimAlertMessage: alertMessage,
    claimAlertSeverity: alertSeverity,
    claimAlertOpen: alertOpen,
    claimBadge: mutate,
    claiming: isLoading,
    handleClaimAlertClose,
  };
}
